window.process = {} as any;

import 'bootstrap/dist/css/bootstrap.css';
import 'react-circular-progressbar/dist/styles.css';

import { createRoot } from 'react-dom/client';

import App from './App';
import * as serviceWorker from './serviceWorker';

const rootElement = document.getElementById('root');
const root = createRoot(rootElement!);

root.render(<App />);

//registerServiceWorker();
serviceWorker.unregister();
