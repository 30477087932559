import React, { createContext, PropsWithChildren, useCallback, useState } from 'react';
import Loader from '../components/ContentLoader';

export type LoaderContextType = {
    isLoading: string;
    show: () => void;
    hide: () => void;
};

export const LoaderContext = createContext<LoaderContextType>({} as LoaderContextType);

function LoaderProvider({ children }: PropsWithChildren<{}>) {
    const [isLoading, setIsLoading] = useState('false');

    const show = useCallback(() => {
        setIsLoading('true');
    }, []);

    const hide = useCallback(() => {
        setIsLoading('false');
    }, []);

    return (
        <LoaderContext.Provider value={{ hide, show, isLoading }}>
            <Loader loading={isLoading} />
            {children}
        </LoaderContext.Provider>
    );
}

export default LoaderProvider;
