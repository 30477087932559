import axios from "axios";
import { TransportRequestEntity, TransportRequestTag } from "data/TransportRequest";
import { CheckDllVersionParams, CheckDllVersionResponse } from "./listDll.schema";

export async function checkedDllVersion(params: CheckDllVersionParams) {
    return axios.get<CheckDllVersionResponse>('v1/listdll/checkedfile', {
        params
    });
}

export async function updatedDllVersion(body: TransportRequestTag) {
    return axios.patch<string>('v1/listdll/updatefile', body)
}