import { memo, useEffect } from 'react';

import LineChart from 'commons/components/LineChart';
import { MetricMonitorGraphValues } from 'models/Google';

type Props = {
    webMonitorGraphValues?: MetricMonitorGraphValues;
    dbMonitorGraphValues?: MetricMonitorGraphValues;
};

const data = [
    ['Year', 'Sales', 'Expenses'],
    ['2004', 1000, 400],
    ['2005', 1170, 460],
    ['2006', 660, 1120],
    ['2007', 1030, 540]
];

function MonitorInformation({ webMonitorGraphValues, dbMonitorGraphValues }) {
    // const { webMonitorGraphValues, dbMonitorGraphValues } = props;

    return (
        <div className="mt-4 space-y-6">
            {webMonitorGraphValues && (
                <div>
                    <h4>Web</h4>
                    <div className="grid grid-cols-1 gap-2 lg:grid-cols-1">
                        {Object.keys(webMonitorGraphValues).map(key => {
                            const sortedData = webMonitorGraphValues[key].slice().sort((a, b) => {
                                const dateA = new Date(a[0]);
                                const dateB = new Date(b[0]);
                                return dateA.getTime() - dateB.getTime();
                            });

                            let title = key;

                            if (key === 'sentBytes') {
                                title = 'sentMegabit (MB/s)';
                            }

                            if (key === 'receivedBytes') {
                                title = 'receivedMegabit (MB/s)';
                            }

                            return (
                                <LineChart
                                    key={key}
                                    data={sortedData}
                                    options={{
                                        legend: { position: 'bottom' },
                                        title: title,
                                        height: 400,
                                        titleTextStyle: { fontSize: 10 },
                                        vAxis: { title: 'Value', viewWindow: { min: 0 } }
                                    }}
                                />
                            );
                        })}
                    </div>
                </div>
            )}

            {dbMonitorGraphValues && (
                <div>
                    <h4>Database</h4>
                    <div className="grid grid-cols-1 gap-2 lg:grid-cols-1">
                        {Object.keys(dbMonitorGraphValues).map(key => {
                            const sortedData = dbMonitorGraphValues[key].slice().sort((a, b) => {
                                const dateA = new Date(a[0]);
                                const dateB = new Date(b[0]);
                                return dateA.getTime() - dateB.getTime();
                            });

                            let title = key;

                            if (key === 'sentBytes') {
                                title = 'sentMegabit (MB/s)';
                            }

                            if (key === 'receivedBytes') {
                                title = 'receivedMegabit (MB/s)';
                            }

                            return (
                                <LineChart
                                    key={key}
                                    data={sortedData}
                                    options={{
                                        legend: { position: 'bottom' },
                                        title: title,
                                        height:400,
                                        titleTextStyle: { fontSize: 10 },
                                        vAxis: { title: 'Value', viewWindow: { min: 0 } }
                                    }}
                                />
                            );
                        })}
                    </div>
                </div>
            )}
        </div>
    );
}

export default memo(MonitorInformation);
