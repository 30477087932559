import {
    Modal,
    ModalBody,
    ModalCloseButton,
    ModalContent,
    ModalHeader,
    ModalOverlay
} from '@chakra-ui/react';
import { F1ApiLogResponse } from 'data/schemas/apiLog';
import { formatDateTime } from 'helpers/formatDateTime';
import { useCallback } from 'react';

interface ModalProps {
    isOpenModal: boolean;
    onClose: () => void;
    f1ApiLogDetail: F1ApiLogResponse;
}

function ModalDetailApiLog(props: ModalProps) {
    const { isOpenModal, onClose, f1ApiLogDetail } = props;

    const handleClose = useCallback(() => {
        onClose();
    }, [onClose]);

    return (
        <Modal
            scrollBehavior="inside"
            isCentered
            size="6xl"
            isOpen={isOpenModal}
            onClose={handleClose}
        >
            <ModalOverlay />
            <ModalContent>
                <ModalHeader>F1 Api Log Detail</ModalHeader>
                <ModalCloseButton />
                <ModalBody>
                    {f1ApiLogDetail && (
                        <div className="flex flex-col gap-3">
                            <div className="flex flex-row items-center gap-2">
                                <span className="w-32 font-bold">ID:</span>
                                <span>{f1ApiLogDetail.id}</span>
                            </div>
                            <div className="flex flex-row items-center gap-2">
                                <span className="w-32 font-bold">Api Name:</span>
                                <span>{f1ApiLogDetail.apiName}</span>
                            </div>
                            <div className="flex flex-row items-center gap-2">
                                <span className="w-32 font-bold">Level:</span>
                                <span>{f1ApiLogDetail.level}</span>
                            </div>
                            <div className="flex flex-row items-center gap-2">
                                <span className="w-2/6 font-bold">Message:</span>
                                <span className="overflow-x-auto whitespace-pre-wrap">
                                    {f1ApiLogDetail.message}
                                </span>
                            </div>
                            <div className="flex flex-row items-center gap-2">
                                <span className="w-2/6 font-bold">Output Message:</span>
                                <span className="overflow-x-auto whitespace-pre-wrap">
                                    {f1ApiLogDetail.outputMessage}
                                </span>
                            </div>
                            <div className="flex flex-row items-center gap-2">
                                <span className="w-32 font-bold">Exception:</span>
                                <span className="overflow-x-auto whitespace-pre-wrap">
                                    {f1ApiLogDetail.exception}
                                </span>
                            </div>
                            <div className="flex flex-row items-center gap-2">
                                <span className="w-32 font-bold">Duration:</span>
                                <span className="overflow-x-auto whitespace-pre-wrap">
                                    {f1ApiLogDetail.duration}
                                </span>
                            </div>
                            <div className="flex flex-row items-center gap-2">
                                <span className="w-32 font-bold">Created By:</span>
                                <span>{f1ApiLogDetail.createdBy}</span>
                            </div>
                            <div className="flex flex-row items-center gap-2">
                                <span className="w-32 font-bold">Time Stamp:</span>
                                <span>{formatDateTime(f1ApiLogDetail.timeStamp)}</span>
                            </div>
                        </div>
                    )}
                </ModalBody>
            </ModalContent>
        </Modal>
    );
}

export default ModalDetailApiLog;
