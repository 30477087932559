import React from 'react';
import { Button, Input, Modal, ModalBody, ModalFooter, ModalHeader } from 'reactstrap';

import { ServerConfig } from '../../../../models/AdminServer';

interface ServerConfigModalProps {
    isOpenModal: boolean;
    toggleModal: () => void;
    serverConfigInfo: ServerConfig;
    onChangeServerConfig: (value: ServerConfig) => void;
    onClickSaveServerConfig: (mode: 'edit' | 'delete') => Promise<void>;
}

const ServerConfigModalComponent = (props: ServerConfigModalProps) => {
    return (
        <Modal
            isOpen={props.isOpenModal}
            toggle={props.toggleModal}
            fullscreen="sm"
            centered
            size="xl"
        >
            <ModalHeader toggle={props.toggleModal}>Manage AdminServer </ModalHeader>
            <ModalBody>
                <div className="row">
                    <div className="col-2">
                        Group:
                        <Input
                            value={props.serverConfigInfo.group}
                            name="group"
                            onChange={e =>
                                props.onChangeServerConfig({
                                    ...props.serverConfigInfo,
                                    group: e.target.value
                                })
                            }
                        />
                    </div>
                    <div className="col-2">
                        Type:
                        <Input
                            value={props.serverConfigInfo.type}
                            name="type"
                            onChange={e =>
                                props.onChangeServerConfig({
                                    ...props.serverConfigInfo,
                                    type: e.target.value
                                })
                            }
                        />
                    </div>
                    <div className="col-2">
                        Name:
                        <Input
                            value={props.serverConfigInfo.name}
                            name="serverName"
                            onChange={e =>
                                props.onChangeServerConfig({
                                    ...props.serverConfigInfo,
                                    name: e.target.value
                                })
                            }
                        />
                    </div>
                    <div className="col-5">
                        Ip address:
                        <Input
                            value={props.serverConfigInfo.ipAddress}
                            name="ipAddress"
                            onChange={e =>
                                props.onChangeServerConfig({
                                    ...props.serverConfigInfo,
                                    ipAddress: e.target.value
                                })
                            }
                        />
                    </div>
                </div>
            </ModalBody>
            <ModalFooter style={{ justifyContent: 'flex-start' }}>
                {/* <Button
                    className="btn btn-danger"
                    onClick={() => props.onClickSaveServerConfig('delete')}
                >
                    DELETE
                </Button> */}
                <div style={{ marginLeft: 'auto' }}>
                    <Button
                        className="btn btn-primary"
                        style={{ marginRight: '5px' }}
                        onClick={() => props.onClickSaveServerConfig('edit')}
                    >
                        Save
                    </Button>
                    <Button className="btn btn-primary" onClick={props.toggleModal}>
                        Close
                    </Button>
                </div>
            </ModalFooter>
        </Modal>
    );
};

export default ServerConfigModalComponent;
