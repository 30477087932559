import React from 'react';
import { Button } from '@chakra-ui/react';
import MonacoEditor from '@monaco-editor/react';
import AutoSizer from 'react-virtualized-auto-sizer';
import { Tree } from 'react-arborist';
import { twMerge } from 'tailwind-merge';
import { FaFolder } from 'react-icons/fa6';
import { TbFileTypeSql } from 'react-icons/tb';

import { ConnectionForm, SkippableModal } from './components';
import useMigrationViewModel from './Migration.viewmodel';

const STATEMENT_STATUS_CLASSES = {
    success: 'bg-green-600 text-white',
    error: 'bg-red-600 text-white',
    exec: 'bg-yellow-400 text-white'
};

function Migration() {
    const {
        folders,
        outputLogs,
        showSkippableModal,
        error,
        fileTreeRef,
        skipResolverRef,
        virtualTerminalRef,
        selectedFileContent,
        handleClickConnect,
        handleSelectFiles,
        handleClickExportRunningLog,
        handleClickRunSelectedFiles,
        handleClickRunAllFiles,
        setShowSkippableModal
    } = useMigrationViewModel();

    return (
        <React.Fragment>
            <div className="flex h-full flex-col">
                <ConnectionForm onClickConnect={handleClickConnect} />
                <div className="mt-[16px] flex gap-2">
                    <Button
                        size="sm"
                        onClick={handleClickRunSelectedFiles}
                    >
                        Run
                    </Button>
                    <Button
                        size="sm"
                        onClick={handleClickRunAllFiles}
                    >
                        Run All
                    </Button>
                </div>
                <div className="flex flex-1 px-2 pt-2">
                    <div className="w-[253px] border">
                        <AutoSizer>
                            {({ height }) => (
                                <Tree
                                    ref={fileTreeRef}
                                    data={folders}
                                    idAccessor="id"
                                    childrenAccessor={d => d.files as any}
                                    openByDefault={true}
                                    width={250}
                                    height={height}
                                    padding={4}
                                    onSelect={handleSelectFiles}
                                    disableDrag={false}
                                >
                                    {({ node, style, dragHandle }) => (
                                        <div
                                            ref={dragHandle}
                                            style={{
                                                ...style,
                                                paddingLeft:
                                                    ((style.paddingLeft ?? 0) as number) + 8,
                                                paddingRight:
                                                    ((style.paddingRight ?? 0) as number) + 8
                                            }}
                                            className={twMerge(
                                                'cursor-pointer hover:bg-neutral-200',
                                                node.isSelected &&
                                                    'bg-blue-300 hover:bg-blue-400 focus-visible:border-none'
                                            )}
                                        >
                                            <div className="flex items-center gap-2">
                                                {!node.isLeaf ? <FaFolder /> : <TbFileTypeSql />}
                                                <span className="flex-1">{node.data.id}</span>
                                            </div>
                                        </div>
                                    )}
                                </Tree>
                            )}
                        </AutoSizer>
                    </div>
                    <div className="flex flex-1 flex-col">
                        <div className="flex-1 border">
                            <MonacoEditor
                                language="sql"
                                theme="vs-light"
                                value={selectedFileContent}
                                options={{ selectOnLineNumbers: true }}
                            />
                        </div>
                        <div
                            ref={virtualTerminalRef}
                            className="h-[200px] overflow-y-auto border p-2"
                        >
                            <Button
                                size="sm"
                                type="button"
                                onClick={handleClickExportRunningLog}
                                className="absolute right-10 h-8"
                            >
                                Export
                            </Button>
                            <div id="output-log-container">
                                {outputLogs.map(log => (
                                    <p>
                                        <span
                                            className={twMerge(
                                                STATEMENT_STATUS_CLASSES[log.detail.status],
                                                'rounded-md px-2'
                                            )}
                                        >
                                            [{log.detail.status}]
                                        </span>
                                        <span>
                                            [{log.folder}/{log.id}]
                                        </span>
                                        : <span>{log.detail.message}</span>
                                        {log.detail.status && (
                                            <span
                                                className='px-2'
                                            >
                                                [Elapsed Time - {log.detail.elapsed_time}]
                                            </span>
                                        )}
                                    </p>
                                ))}
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <SkippableModal
                noCloseButton={true}
                isOpen={showSkippableModal}
                onClose={setShowSkippableModal.off}
                error={error}
                skipResolverRef={skipResolverRef}
            />
        </React.Fragment>
    );
}

export default Migration;
