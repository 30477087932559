import { Action, Reducer } from 'redux';
import { AppThunkAction } from '.';

import * as Models from '../models/AdminApplicationServer'

interface LoadAdminApplicationServer {
    type: 'LOAD_ADMIN_APPLICATION_SERVER'
}

interface LoadedAdminApplicationServer {
    type: 'LOADED_ADMIN_APPLICATION_SERVER';
    response: Models.DataAdminApplicationServer;
    status: number,
    statusMessage: string
}

interface FailLoadedAdminApplicationServer {
    type: 'LOAD_ADMIN_APPLICATION_SERVER_FAIL'
    status: number,
    statusMessage: any
}

interface ClearAdminApplicationServer {
    type: 'CLEAR_ADMIN_APPLICATION_SERVER'
}

type KnownAction = LoadedAdminApplicationServer | FailLoadedAdminApplicationServer | LoadAdminApplicationServer | ClearAdminApplicationServer;

export const actionCreators = {
    requestGetAdminApplicationServer: (startDateIndex: number): AppThunkAction<KnownAction> => (dispatch, getState) => {

        const appState = getState();

        //const fetchFappspg = async () => {
        //    try {
        //        const response = await fetch('v1/monitor/fappspg');

        //        if (!response.ok) {
        //            throw new Error(response.json() as any)
        //        }

        //        const data = await response.json() as Promise<Models.DataAdminApplicationServer>

        //        dispatch({ type: 'LOADED_ADMIN_APPLICATION_SERVER', response: data, status: 200, statusMessage: '' });
        //    } catch (error) {
        //        dispatch({ type: 'LOAD_ADMIN_APPLICATION_SERVER_FAIL', status: 204, statusMessage: 'errorfetch' });
        //    }
        //}

        //if (startDateIndex === 100) {
        //    fetchFappspg();
        //}

        if (startDateIndex == 100) {
            fetch(`v1/monitor/fappspg`)
                .then((response) => {
                    if (!response.ok) {
                        throw new Error(response.json() as any)
                    } else {
                        return response.json() as Promise<Models.DataAdminApplicationServer>



                    }
                })
                .then((data) => {
                    dispatch({ type: 'LOADED_ADMIN_APPLICATION_SERVER', response: data, status: 200, statusMessage: ''});
                })
                .catch(error => {         
                    dispatch({ type: 'LOAD_ADMIN_APPLICATION_SERVER_FAIL', status: 204, statusMessage: 'errorfetch' });
                })
            dispatch({ type: 'LOAD_ADMIN_APPLICATION_SERVER' })
        }
    }
};


const unloadedState: Models.AdminApplicationServerState = { isLoadAdminApplicationServer: false, ResponseAdminApplicationServer: [] };



export const reducer: Reducer<Models.AdminApplicationServerState> = (state: Models.AdminApplicationServerState | undefined, incomingAction: Action): Models.AdminApplicationServerState => {
    if (state === undefined) {
        return unloadedState;
    }
    const action = incomingAction as KnownAction;
    switch (action.type) {
        case 'LOAD_ADMIN_APPLICATION_SERVER':
            return {
                isLoadAdminApplicationServer: false,
                ResponseAdminApplicationServer: []
            }
        case 'LOADED_ADMIN_APPLICATION_SERVER':
            return {
                ResponseAdminApplicationServer: action.response as any,
                isLoadAdminApplicationServer: true,
                statusAdminAppicationServer: action.status,
                messageAdminAppicationServer: action.statusMessage
            }
        case 'LOAD_ADMIN_APPLICATION_SERVER_FAIL':
            return {
                isLoadAdminApplicationServer: false,
                ResponseAdminApplicationServer: [],
                messageAdminAppicationServer: action.statusMessage,
                statusAdminAppicationServer: action.status
            }
        default: return state;
    }

};
