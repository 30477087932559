import axios from 'axios';

import { MonitorServerResponse } from '../../../schemas/monitor';

export async function getByIdV2(server_id: string, params: { tool_update: string }) {
    return axios.get<MonitorServerResponse>(`v2/monitor/servers/${server_id}`, {
        params,
        headers: {
            Authorization: 'Bearer ' + localStorage.getItem('MM_APPLICATION')
        }
    });
}
