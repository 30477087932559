import React, { useEffect, useState } from 'react';
import { SiChakraui } from "react-icons/si";
import ReactDatatable from '@ashvin27/react-datatable';
import { Route, useHistory } from "react-router-dom";
import { Button, Progress } from "reactstrap";
import { AiOutlineReload } from "react-icons/ai";
import BootstrapSwitchButton from 'bootstrap-switch-button-react';

import "../assets/style/style.css"

import Loader from './loader/ContentLoader';
import { configdatatableOverView } from './configdatatable/Config';

import { connect } from 'react-redux';
import { ApplicationState } from '../store';

import * as OverViewStore from '../store/OverView';
import * as OverViewModels from '../models/OverView';

type OverViewProps = OverViewModels.AdminOverViewState & typeof OverViewStore.actionCreators


const QuickBox = ({ title, children }) => {
    return (
        <div className="QuickBox">
            <p>{title} : </p>
            <p>{children}</p>
        </div>
    );
}

const AdminDashboardOverView = (adminProps: OverViewProps) => {
    let history = useHistory();

    const [autorefresh, setautorefresh] = useState(false);

    const [refresh, setrefresh] = useState(false);
    const togglerefresh = () => setrefresh(!refresh);
    const [data, setdata] = useState(Array);
    const [isloader, setisloader] = useState(false)

    useEffect(() => {
        console.log('เริ่ม')
        adminProps.requestGetOverview(100);
        autorefreshtime(null);
    }, [refresh]);

    useEffect(() => {
        if (adminProps.isLoadAdminOverView === true) {
            managedata(adminProps.ResponseAdminOverView)
        }
    }, [adminProps.isLoadAdminOverView]);


    const onchangeSwitch = (checked: boolean) => {
        if (checked === true) {
            setautorefresh(true);
            autorefreshtime(true);
        } else {
            setautorefresh(false);
        }
    }
    const autorefreshtime = (Switch) => {
        if (autorefresh === true || Switch === true) {
            setTimeout(() => {
                togglerefresh();
            }, 60000);
        } else {
            console.log("");
        }
    }

    const managedata = (dataProps) => {

        const chackdata = [...dataProps];

        let values = "" as any

        for (let j = 0; j < chackdata.length; j++) {

            if (j == 0) {
                values = chackdata[j]
            }
            else if (values.fappID === chackdata[j].fappID) {

                chackdata[j].type = null
                chackdata[j].name = null
                chackdata[j].group = null
                chackdata[j].domain = null
                chackdata[j].serverCpu = null
                chackdata[j].totalStorage = null
                chackdata[j].serverTotalRam = null
                chackdata[j].iiscpu = null
                chackdata[j].totalIisRam = null
            }
            else {
                values = chackdata[j]
            }
        }
        setdata(chackdata)
        setisloader(true)
    }

    const columns = [
        {
            key: "statusSever", text: "Status", className: "Status", sortable: true,
            cell: (record, index) => {
                return (<SiChakraui style={{ color: (record.status === "false") ? 'red' : 'green' }} />);
            }
        },
        {
            key: "group", text: "Group", className: "Group"
        },
        {
            key: "type", text: "Type", className: "Type"
        },
        {
            key: "name", text: "Name", className: "Name"
        },
        {
            key: "domain", text: "Domain", className: "Domain",
        },
        {
            key: "servercpu", text: "Server CPU", className: "widthProgressbar",
            cell: (record, index) => {
                return (
                    (record.serverCpu === null || record.serverCpu === undefined) ? null
                        : (record.serverCpu > 80) ? <Progress color="danger" className="Progressbar" animated value={record.serverCpu}><span className="fontProgressbar">{parseFloat(record.serverCpu).toFixed(2)}%</span></Progress>
                            : <Progress className="Progressbar" animated value={record.serverCpu}><span className="fontProgressbar">{parseFloat(record.serverCpu).toFixed(2)}%</span></Progress>)
            }
        },
        {
            key: "serverram", text: "Server Ram", className: "widthProgressbar",
            cell: (record, index) => {
                return (
                    (record.serverTotalRam === null || record.serverTotalRam === undefined) ? null
                        : (record.serverTotalRam > 80) ? <Progress color="danger" className="Progressbar" animated value={record.serverTotalRam}><span className="fontProgressbar">{parseFloat(record.serverTotalRam).toFixed(2)}%</span></Progress>
                            : <Progress className="Progressbar" animated value={record.serverTotalRam}><span className="fontProgressbar">{parseFloat(record.serverTotalRam).toFixed(2)}%</span></Progress>

                )
            }
        },
        {
            key: "serverhdd", text: "Server HDD", className: "widthProgressbar",
            cell: (record, index) => {
                return (
                    (record.totalStorage === null || record.totalStorage === undefined) ? null
                        : (record.totalStorage > 80) ? <Progress color="danger" className="Progressbar" animated value={record.totalStorage}><span className="fontProgressbar">{parseFloat(record.totalStorage).toFixed(2)}%</span></Progress>
                            : <Progress className="Progressbar" animated value={record.totalStorage}><span className="fontProgressbar">{parseFloat(record.totalStorage).toFixed(2)}%</span></Progress>
                )
            }
        },
        {
            key: "iiscpu", text: "IIS CPU", className: "widthProgressbar",
            cell: (record, index) => {
                return (
                    (record.totalStorage === null || record.totalStorage === undefined) ? null
                        : (record.iiscpu > 80) ? <Progress color="danger" className="Progressbar" animated value={record.iiscpu}><span className="fontProgressbar">{parseFloat(record.iiscpu).toFixed(2)}%</span></Progress>
                            : <Progress className="Progressbar" animated value={record.iiscpu}><span className="fontProgressbar">{parseFloat(record.iiscpu).toFixed(2)}%</span></Progress>
                )
            }
        },
        {
            key: "iisram", text: "IIS RAM", className: "widthProgressbar",
            cell: (record, index) => {
                return (
                    (record.totalStorage === null || record.totalStorage === undefined) ? null
                        : (record.totalIisRam > 80) ? <Progress color="danger" className="Progressbar" animated value={record.totalIisRam}><span className="fontProgressbar">{parseFloat(record.totalIisRam).toFixed(2)}%</span></Progress>
                            : <Progress className="Progressbar" animated value={record.totalIisRam}><span className="fontProgressbar">{parseFloat(record.totalIisRam).toFixed(2)}%</span></Progress>
                )
            }
        },
        {
            key: "port", text: "Port", className: "widthProgressbar"
        },
        {
            key: "statusFapp", text: "Fapp Status", className: "Status",
            cell: (record, index) => {
                return (<SiChakraui style={{ color: (record.status === "false") ? 'red' : 'green' }} />);
            }
        },
        {
            key: "fappCpu", text: "Fapp CPU", className: "widthProgressbar",
            cell: (record, index) => {
                return (
                    (record.fappCpu > 80) ? <Progress color="danger" className="Progressbar" animated value={record.fappCpu}><span className="fontProgressbar">{parseFloat(record.fappCpu).toFixed(2)}%</span></Progress>
                        : <Progress className="Progressbar" animated value={record.fappCpu}><span className="fontProgressbar">{parseFloat(record.fappCpu).toFixed(2)}%</span></Progress>);
            }
        },
        {
            key: "fappram", text: "Fapp RAM", className: "widthProgressbar",
            cell: (record, index) => {
                return (
                    (record.fappTotalRam > 80) ? <Progress color="danger" className="Progressbar" animated value={record.fappTotalRam}><span className="fontProgressbar">{parseFloat(record.fappTotalRam).toFixed(2)}%</span></Progress>
                        : <Progress className="Progressbar" animated value={record.fappTotalRam}><span className="fontProgressbar">{parseFloat(record.fappTotalRam).toFixed(2)}%</span></Progress>);
            }
        },
    ];

    const handleClickBack = () => {
        history.push(`/`)
    }

    return (
        <div>
            {(adminProps.isLoadAdminOverView === true) ?
                < div className="totalserver" style={{ fontSize: '14px' }}>
                    <div className="d-flex align-items-center justify-content-between">
                        <div>
                            <Button style={{ padding: '5px' }} color="primary" onClick={() => handleClickBack()} >
                                Back
                            </Button>
                        </div>
                        <div className="row d-flex align-items-center" style={{ marginRight: '9px' }}>
                                <div>
                                <h5 className="m-2">AutoRefresh</h5>
                                </div>
                                <div>
                                    <BootstrapSwitchButton
                                        onlabel='ON'
                                        offlabel='OFF'
                                        checked={autorefresh}
                                        onChange={(checked: boolean) => {
                                            onchangeSwitch(checked);
                                        }}
                                        onstyle="primary" />
                            </div>
                            <div>
                                <h3 className="m-2">|</h3>
                            </div>
                                <div>
                                    <Button style={{ padding: '0.9px'}} color="primary" onClick={togglerefresh}>
                                        Refresh<AiOutlineReload />
                                    </Button>
                                </div>
                        </div>
                        {/*<QuickBox title="Total Server ">*/}
                        {/*    &nbsp;&nbsp;*/}
                        {/*</QuickBox>*/}
                        {/*<QuickBox title="ON">*/}
                        {/*    <span style={{ color: 'green' }}> &nbsp;{data.filter(item => item.statusSever === "true").length}&nbsp;</span>*/}
                        {/*</QuickBox>*/}
                        {/*<QuickBox title="OFF">*/}
                        {/*    <span style={{ color: 'red' }}> &nbsp;{data.filter(item => item.statusSever === "false").length}&nbsp;</span>*/}
                        {/*</QuickBox>*/}
                    </div>
                    <ReactDatatable
                        className="table table-striped table-hover"
                        tHeadClassName="custom-header-style"
                        config={configdatatableOverView}
                        records={data}
                        columns={columns} />
                </div>
                : <div className="Loader">
                    <Loader />
                </div>
            }

        </div>

    );

}
export default connect(
    (state: ApplicationState) => state.overview, OverViewStore.actionCreators
)(AdminDashboardOverView as any)

