import React from 'react';
import { connect } from 'react-redux';
import { Card, CardHeader, CardBody, ListGroup, ListGroupItem, Collapse, CardText, Row, Col } from 'reactstrap';
import { ResultCompareTable } from '../../models/DBComparison';
import { ApplicationState } from '../../store';
import { BsFillSquareFill } from 'react-icons/bs';

import TableItem from './TableItem';

export enum ResultTypes {
    NotMatch,
    MisMatch,
    Match
}

interface Props {
    children?: React.ReactNode;
    databaseName: string;
    resultCompareTable: ResultCompareTable;
}

function DBDetail({ databaseName, resultCompareTable }: Props) {
    const { tableNotMatches, tableMisMatches, tableMatches } = resultCompareTable;

    return (
        <Card className="h-100 overflow-hidden text-nowrap">
            <CardHeader>
                <Row>
                    <Col>{databaseName}</Col>
                    <Col className="hint d-flex justify-content-end">
                        <div className="d-flex align-items-center text-danger">
                            <BsFillSquareFill className="mr-1" />
                            <span>ไม่มี</span>
                        </div>
                        <div className="d-flex align-items-center text-warning">
                            <BsFillSquareFill className="mr-1" />
                            <span>ไม่ตรงกัน</span>
                        </div>
                        <div className="d-flex align-items-center text-black">
                            <BsFillSquareFill className="mr-1" />
                            <span>ตรงกัน</span>
                        </div>
                    </Col>
                </Row>
            </CardHeader>
            <CardBody>
                <ListGroup>
                    {/* Not Match Tables */}
                    {tableNotMatches.map((table, index) => (
                        <TableItem key={index} table={table} type={ResultTypes.NotMatch} />
                    ))}

                    {/* MisMatch Tables */}
                    {tableMisMatches.map((misMatchTable, index) => (
                        <TableItem key={index} misMatchTable={misMatchTable} type={ResultTypes.MisMatch} />
                    ))}

                    {/* Match Tables */}
                    {tableMatches.map((table, index) => (
                        <TableItem key={index} table={table} type={ResultTypes.Match} />
                    ))}
                </ListGroup>
            </CardBody>
        </Card>
    );
}

export default connect(
    (state: ApplicationState) => state.dbComparison
)(DBDetail);