import React, { Fragment, useCallback } from 'react';
import { observer } from 'mobx-react';
import {
    Button,
    Modal,
    ModalBody,
    ModalCloseButton,
    ModalContent,
    ModalFooter,
    ModalHeader,
    ModalOverlay
} from '@chakra-ui/react';

interface ScriptDbErrorProps {
    noCloseButton?: boolean;
    isOpen: boolean;
    error: { fileName: string; message: string; indexOfTransaction: number };
    onClose: () => void;
    skipResolverRef: React.MutableRefObject<
        ((value: 'skip' | 'skip_all' | 'cancel') => void) | undefined
    >;
}

function ScriptDBErrorModal(props: ScriptDbErrorProps) {
    const { noCloseButton, isOpen, error, skipResolverRef, onClose } = props;

    const PARSE_ERR_INDEX_OF_TRANSACTION = -1;

    const handleClickInteraction = useCallback(
        (type: 'skip' | 'skip_all' | 'cancel') => {
            skipResolverRef.current && skipResolverRef.current(type);
            onClose();
        },
        [skipResolverRef]
    );

    return (
        <Modal
            closeOnEsc={false}
            closeOnOverlayClick={false}
            isOpen={isOpen}
            onClose={onClose}
        >
            <ModalOverlay />
            <ModalContent>
                <ModalHeader>Error in [error.fileName]</ModalHeader>
                {!noCloseButton && <ModalCloseButton />}
                <ModalBody>
                    <span>{error.message}</span>
                </ModalBody>

                <ModalFooter className='gap-2'>
                    <Button onClick={() => handleClickInteraction('cancel')}>Cancel</Button>
                    {error.indexOfTransaction > PARSE_ERR_INDEX_OF_TRANSACTION && (
                        <Fragment>
                            <Button onClick={() => handleClickInteraction('skip')}>Skip</Button>
                            <Button
                                colorScheme="red"
                                onClick={() => handleClickInteraction('skip_all')}
                            >
                                Skip All
                            </Button>
                        </Fragment>
                    )}
                </ModalFooter>
            </ModalContent>
        </Modal>
    )
}

export default observer(ScriptDBErrorModal);
