import axios from 'axios';
import {
    F1ApiLogDeleteParams,
    F1ApiLogDeleteResponse,
    F1ApiLogParams,
    F1ApiLogResponse,
    F1ApiLogReturn,
    F1ApiLogSizeParams,
    F1ApiLogSizeResponse
} from 'data/schemas/apiLog';

export async function retrieveF1ApiLog(params: F1ApiLogParams) {
    return axios.get<F1ApiLogReturn>('v1/getf1apilog', {
        params
    });
}

export async function deleteF1ApiLog(params: F1ApiLogDeleteParams) {
    return axios.delete<F1ApiLogDeleteResponse>('v1/deletef1apilog', { params });
}

export async function retrieveF1ApiLogSize(params: F1ApiLogSizeParams) {
    return axios.get<F1ApiLogSizeResponse>('v1/getf1apilogsize', {
        params
    });
}
