import axios from "axios";
import { useStore } from "commons/hooks";
import { getTransportRequestPrd, TransportRequestDetailMapping, TransportRequestEntity, TransportRequestPrdParams } from "data/TransportRequest";
import useLoader from "hooks/useLoader";
import JSZip from "jszip";
import { useEffect, useState } from "react";
import Swal from "sweetalert2";

function TransportRequestPRDViewModel() {
    const screenLoader = useLoader();
    const { transportRequest } = useStore();

    const [totalPages, setTotalPage] = useState(1);
    const [searchHelp, setSearchHelp] = useState<TransportRequestPrdParams>({
        prdTag: '',
        page: 1,
        pageSize: 15,
        all: false
    });
    const [transportRequestPrd, setTransportRequestPrd] = useState<TransportRequestEntity[]>()

    useEffect(() => {
        fetchTransportRequest();
    }, [searchHelp.pageSize, searchHelp.page]);

    const handlePageChange = ({ selected }) => {
        handleSearchChange('page', selected + 1);
    };

    const handleSearchChange = <K extends keyof TransportRequestPrdParams>(
        key: K,
        value: TransportRequestPrdParams[K]
    ) => {
        setSearchHelp(prevState => ({
            ...prevState,
            [key]: value
        }));
    };

    const fetchTransportRequest = async () => {
        try {
            screenLoader.show();

            await transportRequest.fetchTransportRequestPrd(searchHelp);

            if (transportRequest.transportRequestPrdResponse !== null) {
                const resultList = transportRequest.transportRequestPrdResponse;
                const totalCount = transportRequest.transportRequestPrdResponse.length;
                //handle error slice when no data ja
                if (resultList && totalCount !== undefined) {
                    setTransportRequestPrd(resultList);
                    setTotalPage(Math.ceil(totalCount / searchHelp.pageSize));
                }
            }

        } catch (error) {
            if (error instanceof Error) {
                Swal.fire('Cannot get transport request!', error.message, 'error');
            }
        } finally {
            screenLoader.hide();
        }
    };

    const handleFetchGetPathFileByPrdTag = async (prdTag: string) => {
        try {
            screenLoader.show();
            searchHelp.all = true;
            searchHelp.prdTag = prdTag;

            const response = await getTransportRequestPrd(searchHelp);

            if (!response || response.length === 0) {
                return;
            }

            const zipBlob = await createZipFromFiles(response);

            if (zipBlob.size === 0) {
                return;
            } else {
                downloadBlob(zipBlob, prdTag);
            }
        } catch (error) {
            Swal.fire({
                icon: 'error',
                title: 'Error',
                text: (error as any).message
            })
        } finally {
            screenLoader.hide();
        }
    }

    const createZipFromFiles = async (fileEntities: TransportRequestEntity[]): Promise<Blob> => {
        const zip = new JSZip();

        for (const entity of fileEntities) {
            if (!entity.s3FilePath) {
                continue;
            }

            const folder = zip.folder(entity.module);

            try {
                const fileData = await downloadFile(entity.s3FilePath);

                if (fileData) {
                    folder?.file(entity.deploymentObject, fileData);
                }
            } catch (error) {
                throw error;
            }
        }

        const zipBlob = await zip.generateAsync({ type: 'blob' });

        return zipBlob;
    }

    const downloadFile = async (url: string): Promise<Blob | undefined> => {
        try {
            const response = await axios.get(url, { responseType: 'blob' });

            return response.data;
        } catch {
            return undefined;
        }
    }

    const downloadBlob = (blob: Blob, filename: string) => {
        if (!blob || blob.size === 0) {
            return;
        }

        const link = document.createElement('a');
        link.href = URL.createObjectURL(blob);
        link.download = filename;
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
    }

    return {
        totalPages,
        transportRequestPrd,

        handlePageChange,
        handleSearchChange,
        fetchTransportRequest,
        handleFetchGetPathFileByPrdTag
    }
}

export default TransportRequestPRDViewModel;

