import React, { useCallback, useEffect, useState } from 'react';
import {
    PDFViewer,
    Document,
    Page,
    View,
    Text,
    Image,
    StyleSheet,
    PDFDownloadLink
} from '@react-pdf/renderer';
import { Button } from '@chakra-ui/react';
import { useLocation } from 'react-router';
import { MetricMonitorGraphValues } from 'models/Google';

const monitorKeys = [
    'CPU Usage',
    'Ram Usage',
    'Disk Space Usage',
    'Network Traffic Sent (MB/s)',
    'Network Traffic Received (MB/s)'
] as const;

const formatDateInTitle = (first: Date, last: Date) => {
    const months = [
        'January',
        'February',
        'March',
        'April',
        'May',
        'June',
        'July',
        'August',
        'September',
        'October',
        'November',
        'December'
    ];
    const firstDate = new Date(first);
    const lastDate = new Date(last);
    const firstDay = firstDate.getDate().toString().padStart(2, '0');
    const lastDay = lastDate.getDate().toString().padStart(2, '0');
    const month = months[firstDate.getMonth()];
    const year = firstDate.getFullYear();

    return `${firstDay} - ${lastDay} ${month} ${year}`;
};

function MonitoringSummaryPDF() {
    const location = useLocation();
    const monitorName = location.state.monitorName as string;

    // Web
    const {
        webMonitorWeekOne,
        webMonitorWeekTwo,
        webMonitorWeekThree,
        webMonitorWeekFour,
        webMonitorWeekFive,
        webMonitorMonth
    } = location.state as Record<string, MetricMonitorGraphValues>;

    // Database
    const {
        dbMonitorWeekOne,
        dbMonitorWeekTwo,
        dbMonitorWeekThree,
        dbMonitorWeekFour,
        dbMonitorWeekFive,
        dbMonitorMonth
    } = location.state as Record<string, MetricMonitorGraphValues>;

    const [formatInTitle, setFormatInTitle] = useState('');
    const [imageUriWebWeekOne, setImageUriWebWeekOne] = useState<string[]>([]);
    const [imageUriWebWeekTwo, setImageUriWebWeekTwo] = useState<string[]>([]);
    const [imageUriWebWeekThree, setImageUriWebWeekThree] = useState<string[]>([]);
    const [imageUriWebWeekFour, setImageUriWebWeekFour] = useState<string[]>([]);
    const [imageUriWebWeekFive, setImageUriWebWeekFive] = useState<string[]>([]);
    const [imageUriWebMonth, setImageUriWebMonth] = useState<string[]>([]);
    const [imageUriDatabaseWeekOne, setImageUriDatabaseWeekOne] = useState<string[]>([]);
    const [imageUriDatabaseWeekTwo, setImageUriDatabaseWeekTwo] = useState<string[]>([]);
    const [imageUriDatabaseWeekThree, setImageUriDatabaseWeekThree] = useState<string[]>([]);
    const [imageUriDatabaseWeekFour, setImageUriDatabaseWeekFour] = useState<string[]>([]);
    const [imageUriDatabaseWeekFive, setImageUriDatabaseWeekFive] = useState<string[]>([]);
    const [imageUriDatabaseMonth, setImageUriDatabaseMonth] = useState<string[]>([]);

    useEffect(() => {
        google.charts.load('current', { packages: ['corechart', 'line'] });
        google.charts.setOnLoadCallback(draw);

        const today = new Date();
        const firstMonth = new Date(today.getFullYear(), today.getMonth() - 1, 1);
        const lastMonth = new Date(today.getFullYear(), today.getMonth(), 0);
        setFormatInTitle(formatDateInTitle(firstMonth, lastMonth));
    }, []);

    const draw = useCallback(async () => {
        let uniqueDates: { [key: string]: boolean } = {};
        let filteredData: string[] = [];

        const createImage = (monitorData: MetricMonitorGraphValues) => {
            Object.keys(monitorData).map(key => {
                monitorData[key].forEach(item => {
                    const date = item[0];
                    if (!uniqueDates[date]) {
                        uniqueDates[date] = true;
                        filteredData.push(item);
                    }
                });

                const dataSort = filteredData.slice().sort((a, b) => {
                    const dateA = new Date(a[0]);
                    const dateB = new Date(b[0]);
                    return dateA.getTime() - dateB.getTime();
                });
                const dataTable = google.visualization.arrayToDataTable(dataSort);

                uniqueDates = {};
                filteredData = [];

                const chartOptions: google.visualization.LineChartOptions = {
                    legend: { position: 'bottom' },
                    titleTextStyle: { fontSize: 10 },
                    vAxis: { viewWindow: { min: 0 } },
                    // width: 1248,
                    height: 502
                };

                const container = document.createElement('div');
                document.body.appendChild(container);

                const chart = new google.visualization.LineChart(container);

                google.visualization.events.addListener(chart, 'ready', () => {
                    const imageUri = chart.getImageURI();
                    container.remove();

                    if (monitorData === webMonitorWeekOne) {
                        setImageUriWebWeekOne(prevImageUri => [...prevImageUri, imageUri]);
                    } else if (monitorData === webMonitorWeekTwo) {
                        setImageUriWebWeekTwo(prevImageUri => [...prevImageUri, imageUri]);
                    } else if (monitorData === webMonitorWeekThree) {
                        setImageUriWebWeekThree(prevImageUri => [...prevImageUri, imageUri]);
                    } else if (monitorData === webMonitorWeekFour) {
                        setImageUriWebWeekFour(prevImageUri => [...prevImageUri, imageUri]);
                    } else if (monitorData === webMonitorWeekFive) {
                        setImageUriWebWeekFive(prevImageUri => [...prevImageUri, imageUri]);
                    } else if (monitorData === webMonitorMonth) {
                        setImageUriWebMonth(prevImageUri => [...prevImageUri, imageUri]);
                    } else if (monitorData === dbMonitorWeekOne) {
                        setImageUriDatabaseWeekOne(prevImageUri => [...prevImageUri, imageUri]);
                    } else if (monitorData === dbMonitorWeekTwo) {
                        setImageUriDatabaseWeekTwo(prevImageUri => [...prevImageUri, imageUri]);
                    } else if (monitorData === dbMonitorWeekThree) {
                        setImageUriDatabaseWeekThree(prevImageUri => [...prevImageUri, imageUri]);
                    } else if (monitorData === dbMonitorWeekFour) {
                        setImageUriDatabaseWeekFour(prevImageUri => [...prevImageUri, imageUri]);
                    } else if (monitorData === dbMonitorWeekFive) {
                        setImageUriDatabaseWeekFive(prevImageUri => [...prevImageUri, imageUri]);
                    } else if (monitorData === dbMonitorMonth) {
                        setImageUriDatabaseMonth(prevImageUri => [...prevImageUri, imageUri]);
                    }
                });

                chart.draw(dataTable, chartOptions);
            });
        };

        //web
        createImage(webMonitorWeekOne);
        createImage(webMonitorWeekTwo);
        createImage(webMonitorWeekThree);
        createImage(webMonitorWeekFour);
        createImage(webMonitorWeekFive);
        createImage(webMonitorMonth);

        //database
        createImage(dbMonitorWeekOne);
        createImage(dbMonitorWeekTwo);
        createImage(dbMonitorWeekThree);
        createImage(dbMonitorWeekFour);
        createImage(dbMonitorWeekFive);
        createImage(dbMonitorMonth);
    }, [location]);

    const fileName = `${monitorName} - Performance Report.pdf`;

    const summaryPDF = (
        <Document title={fileName}>
            <Page
                size="A4"
                style={styles.pageContent}
            >
                <View style={styles.headerContainer}>
                    <Text style={styles.header}>{monitorName} System Performance Report</Text>
                    <Text style={styles.subHeader}>{formatInTitle}</Text>
                </View>

                <View style={styles.titleContainer}>
                    <Text style={styles.title}>FocusOne Application VM</Text>
                    <Text style={styles.textWeek}>Weekly</Text>
                    <Text style={styles.textWeek}>1st</Text>
                </View>
                {imageUriWebWeekOne.length > 0 && (
                    <View>
                        {imageUriWebWeekOne?.map((url, index) => (
                            <View break={monitorKeys[index] === 'Disk Space Usage'}>
                                <View style={styles.titleContainer}>
                                    <Text style={styles.textMonitor}>{monitorKeys[index]}</Text>
                                </View>
                                <View style={styles.image}>
                                    <Image
                                        key={index}
                                        src={url}
                                        style={{
                                            // width: '547px',
                                            // height: '220px',
                                            alignItems: 'center'
                                        }}
                                    />
                                </View>
                            </View>
                        ))}
                    </View>
                )}

                {imageUriWebWeekTwo.length > 0 && (
                    <React.Fragment>
                        <View
                            break
                            style={styles.titleContainer}
                        >
                            <Text style={styles.textWeek}>2st</Text>
                        </View>
                        {imageUriWebWeekTwo?.map((url, index) => (
                            <View break={monitorKeys[index] === 'Disk Space Usage'}>
                                <View style={styles.titleContainer}>
                                    <Text style={styles.textMonitor}>{monitorKeys[index]}</Text>
                                </View>
                                <View style={styles.image}>
                                    <Image
                                        key={index}
                                        src={url}
                                        style={{
                                            // width: '547px',
                                            // height: '220px',
                                            alignItems: 'center'
                                        }}
                                    />
                                </View>
                            </View>
                        ))}
                    </React.Fragment>
                )}

                {imageUriWebWeekThree.length > 0 && (
                    <React.Fragment>
                        <View
                            break
                            style={styles.titleContainer}
                        >
                            <Text style={styles.textWeek}>3st</Text>
                        </View>
                        {imageUriWebWeekThree?.map((url, index) => (
                            <View break={monitorKeys[index] === 'Disk Space Usage'}>
                                <View style={styles.titleContainer}>
                                    <Text style={styles.textMonitor}>{monitorKeys[index]}</Text>
                                </View>
                                <View style={styles.image}>
                                    <Image
                                        key={index}
                                        src={url}
                                        style={{
                                            // width: '547px',
                                            // height: '220px',
                                            alignItems: 'center'
                                        }}
                                    />
                                </View>
                            </View>
                        ))}
                    </React.Fragment>
                )}

                {imageUriWebWeekFour.length > 0 && (
                    <React.Fragment>
                        <View
                            break
                            style={styles.titleContainer}
                        >
                            <Text style={styles.textWeek}>4st</Text>
                        </View>
                        {imageUriWebWeekFour?.map((url, index) => (
                            <View break={monitorKeys[index] === 'Disk Space Usage'}>
                                <View style={styles.titleContainer}>
                                    <Text style={styles.textMonitor}>{monitorKeys[index]}</Text>
                                </View>
                                <View style={styles.image}>
                                    <Image
                                        key={index}
                                        src={url}
                                        style={{
                                            // width: '547px',
                                            // height: '220px',
                                            alignItems: 'center'
                                        }}
                                    />
                                </View>
                            </View>
                        ))}
                    </React.Fragment>
                )}

                {imageUriWebWeekFive.length > 0 && (
                    <React.Fragment>
                        <View
                            break
                            style={styles.titleContainer}
                        >
                            <Text style={styles.textWeek}>5st</Text>
                        </View>
                        {imageUriWebWeekFive?.map((url, index) => (
                            <View break={monitorKeys[index] === 'Disk Space Usage'}>
                                <View style={styles.titleContainer}>
                                    <Text style={styles.textMonitor}>{monitorKeys[index]}</Text>
                                </View>
                                <View style={styles.image}>
                                    <Image
                                        key={index}
                                        src={url}
                                        style={{
                                            // width: '547px',
                                            // height: '220px',
                                            alignItems: 'center'
                                        }}
                                    />
                                </View>
                            </View>
                        ))}
                    </React.Fragment>
                )}

                {imageUriWebMonth.length > 0 && (
                    <React.Fragment>
                        <View
                            break
                            style={styles.titleContainer}
                        >
                            <Text style={styles.textWeek}>Month</Text>
                        </View>
                        {imageUriWebMonth?.map((url, index) => (
                            <View break={monitorKeys[index] === 'Disk Space Usage'}>
                                <View style={styles.titleContainer}>
                                    <Text style={styles.textMonitor}>{monitorKeys[index]}</Text>
                                </View>
                                <View style={styles.image}>
                                    <Image
                                        key={index}
                                        src={url}
                                        style={{
                                            // width: '547px',
                                            // height: '220px',
                                            alignItems: 'center'
                                        }}
                                    />
                                </View>
                            </View>
                        ))}
                    </React.Fragment>
                )}

                <View
                    break
                    style={styles.titleContainer}
                >
                    <Text style={styles.title}>Database VM</Text>
                    <Text style={styles.textWeek}>Weekly</Text>
                    <Text style={styles.textWeek}>1st</Text>
                </View>

                {imageUriDatabaseWeekOne.length > 0 && (
                    <View>
                        {imageUriDatabaseWeekOne?.map((url, index) => (
                            <View break={monitorKeys[index] === 'Disk Space Usage'}>
                                <View style={styles.titleContainer}>
                                    <Text style={styles.textMonitor}>{monitorKeys[index]}</Text>
                                </View>
                                <View style={styles.image}>
                                    <Image
                                        key={index}
                                        src={url}
                                        style={{
                                            // width: '547px',
                                            // height: '220px',
                                            alignItems: 'center'
                                        }}
                                    />
                                </View>
                            </View>
                        ))}
                    </View>
                )}

                {imageUriDatabaseWeekTwo.length > 0 && (
                    <React.Fragment>
                        <View
                            break
                            style={styles.titleContainer}
                        >
                            <Text style={styles.textWeek}>2st</Text>
                        </View>
                        {imageUriDatabaseWeekTwo?.map((url, index) => (
                            <View break={monitorKeys[index] === 'Disk Space Usage'}>
                                <View style={styles.titleContainer}>
                                    <Text style={styles.textMonitor}>{monitorKeys[index]}</Text>
                                </View>
                                <View style={styles.image}>
                                    <Image
                                        key={index}
                                        src={url}
                                        style={{
                                            // width: '547px',
                                            // height: '220px',
                                            alignItems: 'center'
                                        }}
                                    />
                                </View>
                            </View>
                        ))}
                    </React.Fragment>
                )}

                {imageUriDatabaseWeekThree.length > 0 && (
                    <React.Fragment>
                        <View
                            break
                            style={styles.titleContainer}
                        >
                            <Text style={styles.textWeek}>3st</Text>
                        </View>
                        {imageUriDatabaseWeekThree?.map((url, index) => (
                            <View break={monitorKeys[index] === 'Disk Space Usage'}>
                                <View style={styles.titleContainer}>
                                    <Text style={styles.textMonitor}>{monitorKeys[index]}</Text>
                                </View>
                                <View style={styles.image}>
                                    <Image
                                        key={index}
                                        src={url}
                                        style={{
                                            // width: '547px',
                                            // height: '220px',
                                            alignItems: 'center'
                                        }}
                                    />
                                </View>
                            </View>
                        ))}
                    </React.Fragment>
                )}

                {imageUriDatabaseWeekFour.length > 0 && (
                    <React.Fragment>
                        <View
                            break
                            style={styles.titleContainer}
                        >
                            <Text style={styles.textWeek}>4st</Text>
                        </View>
                        {imageUriDatabaseWeekFour?.map((url, index) => (
                            <View break={monitorKeys[index] === 'Disk Space Usage'}>
                                <View style={styles.titleContainer}>
                                    <Text style={styles.textMonitor}>{monitorKeys[index]}</Text>
                                </View>
                                <View style={styles.image}>
                                    <Image
                                        key={index}
                                        src={url}
                                        style={{
                                            // width: '547px',
                                            // height: '220px',
                                            alignItems: 'center'
                                        }}
                                    />
                                </View>
                            </View>
                        ))}
                    </React.Fragment>
                )}

                {imageUriDatabaseWeekFive.length > 0 && (
                    <React.Fragment>
                        <View
                            break
                            style={styles.titleContainer}
                        >
                            <Text style={styles.textWeek}>5st</Text>
                        </View>
                        {imageUriDatabaseWeekFive?.map((url, index) => (
                            <View break={monitorKeys[index] === 'Disk Space Usage'}>
                                <View style={styles.titleContainer}>
                                    <Text style={styles.textMonitor}>{monitorKeys[index]}</Text>
                                </View>
                                <View style={styles.image}>
                                    <Image
                                        key={index}
                                        src={url}
                                        style={{
                                            // width: '547px',
                                            // height: '220px',
                                            alignItems: 'center'
                                        }}
                                    />
                                </View>
                            </View>
                        ))}
                    </React.Fragment>
                )}

                {imageUriDatabaseMonth.length > 0 && (
                    <React.Fragment>
                        <View
                            break
                            style={styles.titleContainer}
                        >
                            <Text style={styles.textWeek}>Month</Text>
                        </View>
                        {imageUriDatabaseMonth?.map((url, index) => (
                            <View break={monitorKeys[index] === 'Disk Space Usage'}>
                                <View style={styles.titleContainer}>
                                    <Text style={styles.textMonitor}>{monitorKeys[index]}</Text>
                                </View>
                                <View style={styles.image}>
                                    <Image
                                        key={index}
                                        src={url}
                                        style={{
                                            // width: '547px',
                                            // height: '220px',
                                            alignItems: 'center'
                                        }}
                                    />
                                </View>
                            </View>
                        ))}
                    </React.Fragment>
                )}
            </Page>
        </Document>
    );

    return (
        <div className="h-full w-full">
            <div className="mb-4 flex justify-end">
                <Button>
                    <PDFDownloadLink
                        document={summaryPDF}
                        fileName={fileName}
                    >
                        {({ blob, url, loading, error }) =>
                            loading ? 'กำลังโหลด...' : 'ดาวน์โหลด PDF'
                        }
                    </PDFDownloadLink>
                </Button>
            </div>
            <PDFViewer
                className="h-full w-full"
                showToolbar={false}
            >
                {summaryPDF}
            </PDFViewer>
        </div>
    );
}

const styles = StyleSheet.create({
    pageContent: {
        paddingTop: 40,
        paddingBottom: 40
    },
    headerContainer: {
        textAlign: 'center',
        lineHeight: 2,
        marginTop: 30,
        marginBottom: 20
    },
    header: {
        fontSize: 16,
        fontWeight: 'extrabold'
    },
    subHeader: {
        fontSize: 14,
        fontWeight: 'bold'
    },
    titleContainer: {
        textAlign: 'left',
        lineHeight: 2,
        marginLeft: 50
    },
    title: {
        fontSize: 12,
        fontWeight: 'bold'
    },
    textWeek: {
        fontSize: 12,
        fontWeight: 'ultrabold'
    },
    textMonitor: {
        fontSize: 10,
        fontWeight: 'ultrabold'
    },
    image: {
        justifyContent: 'center'
    }
});

export default MonitoringSummaryPDF;
