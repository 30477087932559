import * as Counter from './Counter';

import * as AdminWebStore from './AdminWeb';
import * as AdminWebModels from '../models/AdminWeb';

import * as AdminApplicationServerStore from './AdminApplicationServer';
import * as AdminApplicationServerModels from '../models/AdminApplicationServer';

import * as AdminServerStore from './AdminServer';
import * as AdminServerModels from '../models/AdminServer';

import * as OverViewStore from './OverView';
import * as OverViewModels from '../models/OverView';

import * as DBWorker from './DBWorker';
import * as DBWorkerModels from '../models/DBWorker';

import * as DBComparisonStore from './DBComparison';
import * as DBComparisonModels from '../models/DBComparison';

import RootStore from './Root.store';

// The top-level state object

export interface ApplicationState {
    counter: Counter.CounterState | undefined;
    adminweb: AdminWebModels.AdminWebState | undefined;
    adminapplicationserver: AdminApplicationServerModels.AdminApplicationServerState | undefined;
    adminserver: AdminServerModels.AdminServerState | undefined;
    overview: OverViewModels.AdminOverViewState | undefined;
    DBWorker: DBWorkerModels.GetDBWorkerState | undefined;
    dbComparison: DBComparisonModels.DBComparisonStoreState | undefined;
}

// Whenever an action is dispatched, Redux will update each top-level application state property using
// the reducer with the matching name. It's important that the names match exactly, and that the reducer
// acts on the corresponding ApplicationState property type.
export const reducers = {
    counter: Counter.reducer,
    adminweb: AdminWebStore.reducer,
    adminapplicationserver: AdminApplicationServerStore.reducer,
    adminserver: AdminServerStore.reducer,
    overview: OverViewStore.reducer,
    DBWorker: DBWorker.rootReducer,
    dbComparison: DBComparisonStore.reducer
};

// This type can be used as a hint on action creators so that its 'dispatch' and 'getState' params are
// correctly typed to match your store.
export interface AppThunkAction<TAction> {
    (dispatch: (action: TAction) => void, getState: () => ApplicationState): void;
}

export const store = new RootStore();
