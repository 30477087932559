import { memo, useCallback } from 'react';
import { twMerge } from 'tailwind-merge';
import Swal from 'sweetalert2';

const monitors = [
    {
        available: false,
        name: 'DMS',
        monitorDatabaseId: 'dms-db-prd',
        monitorId: 'dms-prd'
    },
    {
        available: true,
        name: 'EPS',
        monitorDatabaseId: 'eps-db-v2',
        monitorId: 'eps-prd'
    },
    {
        available: false,
        name: 'Legacy',
        monitorDatabaseId: 'legacy-db-prd',
        monitorId: 'legacy-prd'
    },
    {
        available: true,
        name: 'QMIX',
        monitorDatabaseId: 'qmix-db',
        monitorId: 'qmix-prd'
    },
    {
        available: true,
        name: 'SCG-CEMEMT',
        monitorDatabaseId: 'scg-cement-db-v2',
        monitorId: 'scg-cement-prd'
    },
    {
        available: true,
        name: 'SCIECO',
        monitorDatabaseId: 'scieco-db-v2',
        monitorId: 'scieco-prd'
    },
    {
        available: true,
        name: 'SLSN',
        monitorDatabaseId: 'slsn-db',
        monitorId: 'slsn-prd'
    },
    {
        available: true,
        name: 'SLTS',
        monitorDatabaseId: 'slts-db',
        monitorId: 'slts-prd'
    },
    {
        available: true,
        name: 'SRIC',
        monitorDatabaseId: 'sric-db-v2',
        monitorId: 'sric-prd-v2'
    },
    {
        available: false,
        name: 'Workflow',
        monitorDatabaseId: 'workflow-db-prd',
        monitorId: 'workflow'
    }
];

export type Monitor = (typeof monitors)[number];

type Props = {
    selectedMonitor: Monitor;
    onChange: (monitor: Monitor) => void;
};

function MonitorSelectorSideNav(props: Props) {
    const { selectedMonitor, onChange } = props;

    const handleClickMonitor = useCallback(
        (monitor: Monitor) => {
            if (!monitor.available) {
                Swal.fire(`${monitor.name} is not supported`);
                return;
            }

            onChange(monitor);
        },
        [onChange]
    );

    return (
        <div className="w-[275px] bg-slate-50 py-2">
            <ul className="m-0">
                {monitors.map(monitor => (
                    <li key={monitor.monitorId}>
                        <button
                            type="button"
                            className={twMerge(
                                'w-full transform px-4 py-2 text-left transition duration-100 hover:scale-105 hover:bg-gray-300',
                                selectedMonitor.name === monitor.name && 'scale-105 bg-gray-300'
                            )}
                            onClick={() => handleClickMonitor(monitor)}
                        >
                            {monitor.name}
                        </button>
                    </li>
                ))}
            </ul>
        </div>
    );
}

export default memo(MonitorSelectorSideNav);
