import React, { useState, useEffect } from 'react';
import {
    Collapse,
    Container,
    Navbar,
    NavbarBrand,
    NavbarToggler,
    NavItem,
    NavLink
} from 'reactstrap';
import { Link, useLocation } from 'react-router-dom';
import { parseJwt } from 'helpers/decodeLocalStoreage';
import Swal from 'sweetalert2';
import { getMasterEmployee } from 'data/repositories/auth/authentication.repository';
import { Extra } from 'models';
import { useStore } from 'commons/hooks';
import { observer } from 'mobx-react';

const TopNavigation: React.FC = () => {
    const [isOpen, setIsOpen] = useState(false);
    const location = useLocation();

    const token = localStorage.getItem('MM_APPLICATION');
    const { authentication } = useStore();

    useEffect(() => {
        if (token !== null) {
            var jwt = parseJwt(token!);
            var extra: Extra = JSON.parse(jwt.extra);
            var data = {
                access_token: token
            };

            (async () => {
                await authentication.fetchMasterEmployee(extra.citizen_id);
                const masterEmployee = authentication.masterEmployeeResponse;
                const statusUser = masterEmployee?.status;
                if (!masterEmployee?.ctizenID || statusUser === 'inactive') {
                    Swal.fire({
                        icon: 'error',
                        title: 'User not Permission',
                        text: `user: ${extra.citizen_id ?? 'ของคุณ'} ไม่มีสิทธ์เข้าใช้งาน`,
                        allowOutsideClick: false,
                        preConfirm() {
                            window.close();
                        }
                    });
                }
            })();
        }
    }, []);

    const toggle = () => {
        setIsOpen(!isOpen);
    };

    return (
        <header>
            <Navbar
                className="navbar-expand-sm navbar-toggleable-sm border-bottom box-shadow mb-3"
                light
            >
                <div className="container-fluid">
                    <NavbarBrand
                        tag={Link}
                        to="/"
                    >
                        Web
                    </NavbarBrand>
                    <NavbarToggler
                        onClick={toggle}
                        className="mr-2"
                    />
                    <Collapse
                        className="d-sm-inline-flex flex-sm-row-reverse"
                        isOpen={isOpen}
                        navbar
                    >
                        <ul className="navbar-nav flex-grow justify-end">
                            <NavItem>
                                <NavLink
                                    tag={Link}
                                    className="text-dark"
                                    to="/admindashboard"
                                >
                                    admindashboard
                                </NavLink>
                            </NavItem>
                        </ul>
                    </Collapse>
                </div>
            </Navbar>
        </header>
    );
};

export default TopNavigation;
