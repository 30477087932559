import { DataAdminServer } from '../../../../models/AdminServer';
import { dbDateTimeToDisplayString } from '../../../../commons/common';
import { SiChakraui } from 'react-icons/si';
import { Progress } from 'reactstrap';

type Column = {
    key: string;
    text: string;
    className: string;
    sortable: boolean;
    cell: (record: DataAdminServer, index: number) => React.JSX.Element;
};

export const serverMonitorColumns: Column[] = [
    //{
    //    key: '',
    //    text: '',
    //    className: 'Manage',
    //    cell: (record, index) => {
    //        return (
    //            <div style={{ display: 'flex' }}>
    //                {/*<div*/}
    //                {/*    className="mx-2"*/}
    //                {/*    style={{ textAlign: 'center', cursor: 'pointer' }}*/}
    //                {/*>*/}
    //                {/*    <BsFillGearFill*/}
    //                {/*        onClick={() =>*/}
    //                {/*            props.handleClickEvent(*/}
    //                {/*                'NAVIGATE_TO_ADMIN_SERVER_CONTROL',*/}
    //                {/*                record.id*/}
    //                {/*            )*/}
    //                {/*        }*/}
    //                {/*    />*/}
    //                {/*</div>*/}
    //                <div
    //                    className="mx-1"
    //                    style={{ textAlign: 'center', cursor: 'pointer' }}
    //                >
    //                    <HiOutlineSearch
    //                        onClick={() =>
    //                            props.handleClickEvent(
    //                                'NAVIGATE_TO_SERVER_DASHBOARD_DETAIL',
    //                                record.id
    //                            )
    //                        }
    //                    />
    //                </div>
    //            </div>
    //        );
    //    }
    //},
    {
        key: 'status',
        text: 'Status',
        className: 'Status flex justify-center',
        sortable: true,
        cell: (record, index) => {
            return (
                <SiChakraui
                    style={{
                        textAlign: 'center',
                        color: record.status === 'false' ? 'red' : 'green'
                    }}
                />
            );
        }
    },
    {
        key: 'updateOn',
        text: 'Last Status',
        className: 'LastStatus',
        sortable: true,
        cell: (record, index) => {
            return (
                <div
                    style={{
                        textAlign: 'center'
                    }}
                >
                    {dbDateTimeToDisplayString(record.updateOn)}
                </div>
            );
        }
    },
    //{
    //    key: 'group',
    //    text: 'Group',
    //    className: 'Group',
    //    sortable: true,
    //    cell: (record, index) => {
    //        return (
    //            <div
    //                style={{
    //                    textAlign: 'left'
    //                }}
    //            >
    //                {record.group}
    //            </div>
    //        );
    //    }
    //},
    //{
    //    key: 'type',
    //    text: 'Type',
    //    className: 'Type',
    //    sortable: true,
    //    cell: (record, index) => {
    //        return (
    //            <div
    //                style={{
    //                    textAlign: 'left'
    //                }}
    //            >
    //                {record.type}
    //            </div>
    //        );
    //    }
    //},
    {
        key: 'name',
        text: 'Name',
        className: 'text-left',
        sortable: true,
        cell: (record, index) => <div>{record.name}</div>
    },
    //{
    //    key: 'domain',
    //    text: 'Domain',
    //    className: 'Ipaddress',
    //    cell: (record, index) => {
    //        return (
    //            <div
    //                style={{
    //                    textAlign: 'left'
    //                }}
    //            >
    //                {record.domain}
    //            </div>
    //        );
    //    }
    //},
    {
        key: 'cpu',
        text: 'CPU',
        className: 'widthProgressbar flex justify-center',
        sortable: true,
        cell: (record, index) => {
            // @ts-ignore
            return record.cpu > 80 ? (
                <Progress
                    color="danger"
                    className="Progressbar"
                    animated
                    value={record.cpu}
                >
                    <span className="fontProgressbar">{parseFloat(record.cpu).toFixed(2)}%</span>
                </Progress>
            ) : (
                <Progress
                    className="Progressbar"
                    animated
                    value={record.cpu}
                >
                    <span className="fontProgressbar">{parseFloat(record.cpu).toFixed(2)}%</span>
                </Progress>
            );
        }
    },
    {
        key: 'totalRam',
        text: 'RAM',
        className: 'widthProgressbar',
        sortable: true,
        cell: (record, index) => {
            // @ts-ignore
            return record.totalRam > 80 ? (
                <div className="flex justify-center">
                    <Progress
                        color="danger"
                        className="Progressbar"
                        animated
                        value={record.totalRam}
                    >
                        <span className="fontProgressbar">
                            {parseFloat(record.totalRam).toFixed(2)}%
                        </span>
                    </Progress>
                </div>
            ) : (
                <div className="flex justify-center">
                    <Progress
                        className="Progressbar"
                        animated
                        value={record.totalRam}
                    >
                        <span className="fontProgressbar">
                            {parseFloat(record.totalRam).toFixed(2)}%
                        </span>
                    </Progress>
                </div>
            );
        }
    },
    {
        key: 'totalStorage',
        text: 'HDD',
        className: 'widthProgressbar',
        sortable: true,
        cell: (record, index) => {
            // @ts-ignore
            return record.totalStorage > 80 ? (
                <div className="flex justify-center">
                    <Progress
                        color="danger"
                        className="Progressbar"
                        animated
                        value={record.totalStorage}
                    >
                        <span className="fontProgressbar">
                            {parseFloat(record.totalStorage).toFixed(2)}%
                        </span>
                    </Progress>
                </div>
            ) : (
                <div className="flex justify-center">
                    <Progress
                        className="Progressbar"
                        animated
                        value={record.totalStorage}
                    >
                        <span className="fontProgressbar">
                            {parseFloat(record.totalStorage).toFixed(2)}%
                        </span>
                    </Progress>
                </div>
            );
        }
    }
    //{
    //    key: 'iiscpu',
    //    text: 'IIS CPU',
    //    sortable: true,
    //    className: 'widthProgressbar',
    //    cell: (record, index) => {
    //        return record.iiscpu > 80 ? (
    //            <Progress
    //                color="danger"
    //                className="Progressbar"
    //                animated
    //                value={record.iiscpu}
    //            >
    //                <span className="fontProgressbar">
    //                    {parseFloat(record.iiscpu).toFixed(2)}%
    //                </span>
    //            </Progress>
    //        ) : (
    //            <Progress
    //                className="Progressbar"
    //                animated
    //                value={record.iiscpu}
    //            >
    //                <span className="fontProgressbar">
    //                    {parseFloat(record.iiscpu).toFixed(2)}%
    //                </span>
    //            </Progress>
    //        );
    //    }
    //},
    //{
    //    key: 'iisRam',
    //    text: 'IIS RAM',
    //    sortable: true,
    //    className: 'widthProgressbar',
    //    cell: (record, index) => {
    //        return record.totalIisRam > 80 ? (
    //            <Progress
    //                color="danger"
    //                className="Progressbar"
    //                animated
    //                value={record.iisRam}
    //            >
    //                <span className="fontProgressbar">
    //                    {parseFloat(record.iisRam).toFixed(2)}%
    //                </span>
    //            </Progress>
    //        ) : (
    //            <Progress
    //                className="Progressbar"
    //                animated
    //                value={record.totalIisRam}
    //            >
    //                <span className="fontProgressbar">
    //                    {parseFloat(record.totalIisRam).toFixed(2)}%
    //                </span>
    //            </Progress>
    //        );
    //    }
    //}
];
