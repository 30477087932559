import { useCallback, useEffect, useMemo, useState } from 'react';
import { useHistory, useParams } from 'react-router';
import Swal from 'sweetalert2';

import { useLoader, useStore } from '../../../commons/hooks';
import { getMonitorServersIndayById } from '../../../data/repositories/monitor/monitorServerInday.repository';
import { getMonitorServerDetailsByServerId } from '../../../data/repositories/monitor/monitorServer.repository';
import { getFappMonitorByDomain } from '../../../data/repositories/monitor/monitorFapp.repository';
import { FappMonitor, FappMonitorState } from '../../../models/FappMonitor';

interface ParamTypes {
    page: string;
    id: string;
}

interface DatasetMonitorServerType {
    date: string[];
    rawDate: string[];
    cpu: string[];
    totalRam: string[];
    totalStorage: string[];
    iisCpu: string[];
    totalIisRam: string[];
}

function DashboardServerDetailViewModel() {
    const history = useHistory();
    const screenLoader = useLoader();
    const { serverConfigStore } = useStore();

    const monitorServers = serverConfigStore.monitorServers;
    const selectedMonitorServer = serverConfigStore.selectedServer;

    const { id } = useParams<ParamTypes>();

    const initialMonitorServerInday: DatasetMonitorServerType = {
        date: [],
        rawDate: [],
        cpu: [],
        totalRam: [],
        totalStorage: [],
        iisCpu: [],
        totalIisRam: []
    };

    const initialMonitorServerEveryDay: DatasetMonitorServerType = {
        date: [],
        rawDate: [],
        cpu: [],
        totalRam: [],
        totalStorage: [],
        iisCpu: [],
        totalIisRam: []
    };

    const [fappMonitors, setFappMonitors] = useState<FappMonitorState>({
        isLoaded: false,
        payload: []
    });

    const [dateInday, setDateInday] = useState<string[]>(initialMonitorServerInday.date);
    const [cpuInday, setCpuInday] = useState<string[]>(initialMonitorServerInday.cpu);
    const [totalRamInday, setTotalRamInday] = useState<string[]>(
        initialMonitorServerInday.totalRam
    );
    const [totalStorageInday, setTotalStorageInday] = useState<string[]>(
        initialMonitorServerInday.totalStorage
    );
    const [iisCpuInday, setIisCpuInday] = useState<string[]>(initialMonitorServerInday.iisCpu);
    const [totalIisRamInday, setIisRamInday] = useState<string[]>(
        initialMonitorServerInday.totalIisRam
    );

    const monitorServerIndayMemo = useMemo(() => initialMonitorServerInday, []);
    const monitorServerEveryDayMemo = useMemo(() => initialMonitorServerEveryDay, []);

    const [chartOpen, setchartOpen] = useState(false);
    const [startDate, setStartDate] = useState('');
    const [endDate, setEndDate] = useState('');

    // After refresh page
    // then call API get all server monitor.
    // Hasn't selected server and ResponseAdminServer is empty array.
    useEffect(() => {
        if (monitorServers.ResponseAdminServer.length > 0 && selectedMonitorServer) {
            return;
        }

        _fetchMonitorServers();
    }, []);

    // Has selected server from admin dashboard.
    useEffect(() => {
        if (!selectedMonitorServer) {
            return;
        }

        _initialData();
    }, [selectedMonitorServer]);

    const chartDataServerMonitorInday = {
        labels: dateInday,
        datasets: [
            {
                label: 'CPU',
                fill: false,
                //lineTension: 1,
                backgroundColor: '#19e6a0',
                borderColor: '#19e6a0',
                //borderDash: [],
                // borderDashOffset: 0.0,
                pointBorderColor: 'rgba(75,192,192,1)',
                pointBackgroundColor: '#fff',
                pointBorderWidth: 1,
                pointHoverRadius: 5,
                pointHoverBackgroundColor: 'rgba(75,192,192,1)',
                pointHoverBorderColor: 'rgba(220,220,220,1)',
                pointHoverBorderWidth: 2,
                pointRadius: 1,
                pointHitRadius: 10,
                data: cpuInday
            },
            {
                label: 'RAM',
                fill: false,
                // lineTension: 1,
                backgroundColor: '#1798f9',
                borderColor: '#1798f9',
                // borderDash: [],
                //borderDashOffset: 0.0,
                pointBorderColor: '#1798f9',
                pointBackgroundColor: '#fff',
                pointBorderWidth: 1,
                pointHoverRadius: 5,
                pointHoverBackgroundColor: '#1798f9',
                pointHoverBorderColor: '#1798f9',
                pointHoverBorderWidth: 2,
                pointRadius: 1,
                pointHitRadius: 10,
                data: totalRamInday
            },
            {
                label: 'HDD',
                fill: false,
                // lineTension: 1,
                backgroundColor: '#df513d',
                borderColor: '#df513d',
                //borderDash: [],
                // borderDashOffset: 0.0,
                pointBorderColor: '#df513d',
                pointBackgroundColor: '#fff',
                pointBorderWidth: 1,
                pointHoverRadius: 5,
                pointHoverBackgroundColor: '#df513d',
                pointHoverBorderColor: '#df513d',
                pointHoverBorderWidth: 2,
                pointRadius: 1,
                pointHitRadius: 10,
                data: totalStorageInday
            },
            {
                label: 'IIS CPU',
                fill: false,
                // lineTension: 1,
                backgroundColor: '#dbb957',
                borderColor: '#dbb957',
                //borderDash: [],
                // borderDashOffset: 0.0,
                pointBorderColor: '#dbb957',
                pointBackgroundColor: '#fff',
                pointBorderWidth: 1,
                pointHoverRadius: 5,
                pointHoverBackgroundColor: '#dbb957',
                pointHoverBorderColor: '#dbb957',
                pointHoverBorderWidth: 2,
                pointRadius: 1,
                pointHitRadius: 10,
                data: iisCpuInday
            },
            {
                label: 'IIS RAM',
                fill: false,
                // lineTension: 1,
                backgroundColor: '#242038',
                borderColor: '#242038',
                //borderDash: [],
                // borderDashOffset: 0.0,
                pointBorderColor: '#242038',
                pointBackgroundColor: '#fff',
                pointBorderWidth: 1,
                pointHoverRadius: 5,
                pointHoverBackgroundColor: '#242038',
                pointHoverBorderColor: '#242038',
                pointHoverBorderWidth: 2,
                pointRadius: 1,
                pointHitRadius: 10,
                data: totalIisRamInday
            }
        ]
    };

    const handleClick = (serverId: string) => {
        history.push(`/ViewDashboardServer/adminServer/${serverId}`);
    };

    const onClickReset = () => {
        setDateInday(monitorServerIndayMemo.date);
        setCpuInday(monitorServerIndayMemo.cpu);
        setTotalRamInday(monitorServerIndayMemo.totalRam);
        setTotalStorageInday(monitorServerIndayMemo.totalStorage);
        setIisCpuInday(monitorServerIndayMemo.iisCpu);
        setIisRamInday(monitorServerIndayMemo.totalIisRam);

        setStartDate('');
        setEndDate('');
    };

    const onSelectDate = (selectedDate: string, isStartDate: boolean) => {
        if (isStartDate) {
            setStartDate(selectedDate);

            return;
        }

        setEndDate(selectedDate);
    };

    const onClickFilter = () => {
        // const rawDate = [...monitorServerEveryDayMemo.rawDate];
        const date = [...monitorServerEveryDayMemo.date];
        const dataPoints2cpu = [...monitorServerEveryDayMemo.cpu];
        const dataPoints2ram = [...monitorServerEveryDayMemo.totalRam];
        const dataPoints2hdd = [...monitorServerEveryDayMemo.totalStorage];
        const dataPoints2iiscpu = [...monitorServerEveryDayMemo.iisCpu];
        const dataPoints2iisram = [...monitorServerEveryDayMemo.totalIisRam];

        // debugger;
        const indexStartDate = date.indexOf(startDate);
        const indexEndDate = date.lastIndexOf(endDate);

        const filterDate = date.slice(indexStartDate, indexEndDate + 1);
        const filterDataPointscpu = dataPoints2cpu.slice(indexStartDate, indexEndDate + 1);
        const filterDataPointsram = dataPoints2ram.slice(indexStartDate, indexEndDate + 1);
        const filterDataPointshdd = dataPoints2hdd.slice(indexStartDate, indexEndDate + 1);
        const filterDataPointsiiscpu = dataPoints2iiscpu.slice(indexStartDate, indexEndDate + 1);
        const filterDataPointsiisram = dataPoints2iisram.slice(indexStartDate, indexEndDate + 1);

        setDateInday(filterDate);
        setCpuInday(filterDataPointscpu);
        setTotalRamInday(filterDataPointsram);
        setTotalStorageInday(filterDataPointshdd);
        setIisCpuInday(filterDataPointsiiscpu);
        setIisRamInday(filterDataPointsiisram);
    };

    const _initialData = async () => {
        try {
            screenLoader.show();

            // get Server monitor inday
            // get Server monitor by id
            // await until all finished
            await Promise.all([_fetchMonitorServersInday(id), _fetchMonitorServerDetailsById(id)]);

            if (selectedMonitorServer === null) {
                throw new Error('Please select server.');
            }

            // get Fapp monitor
            _fetchFappMonitorByDomain(selectedMonitorServer.domain);
        } catch (error) {
            if (error instanceof Error) {
                _redirectToHomeWhenError(error.message);
            }
        } finally {
            screenLoader.hide();
        }
    };

    const _fetchMonitorServersInday = useCallback(async (serverId: string) => {
        try {
            const monitorServerIndayResponse = await getMonitorServersIndayById(serverId);

            if (monitorServerIndayResponse.ResponseAdminServer.length === 0) {
                return;
            }

            monitorServerIndayResponse.ResponseAdminServer.forEach(response => {
                initialMonitorServerInday.date.push(
                    response.updateOn.substring(19, 0).replace('T', ' ')
                );
                initialMonitorServerInday.rawDate.push(response.updateOn.substring(10, 9));
                initialMonitorServerInday.cpu.push(response.cpu);
                initialMonitorServerInday.totalRam.push(response.totalRam);
                initialMonitorServerInday.totalStorage.push(response.totalStorage);
                initialMonitorServerInday.iisCpu.push(response.iisCpu);
                initialMonitorServerInday.totalIisRam.push(response.totolIisRam);
            });

            setchartOpen(true);
        } catch (error) {
            throw error;
        }
    }, []);

    const _fetchMonitorServerDetailsById = useCallback(async (serverId: string) => {
        try {
            const monitorServerDetails = await getMonitorServerDetailsByServerId(serverId);

            if (monitorServerDetails.ResponseAdminServer.length === 0) {
                return;
            }

            monitorServerDetails.ResponseAdminServer.forEach(response => {
                initialMonitorServerEveryDay.date.push(
                    // response.updateOn.substring(19, 0).replace('T', ' ')
                    response.updateOn.substring(0, 10)
                );
                initialMonitorServerEveryDay.rawDate.push(response.updateOn.substring(19, 0));
                initialMonitorServerEveryDay.cpu.push(response.cpu);
                initialMonitorServerEveryDay.totalRam.push(response.totalRam);
                initialMonitorServerEveryDay.totalStorage.push(response.totalStorage);
                initialMonitorServerEveryDay.iisCpu.push(response.iisCpu);
                initialMonitorServerEveryDay.totalIisRam.push(response.totolIisRam);
            });
        } catch (error) {
            throw error;
        }
    }, []);

    const _fetchFappMonitorByDomain = useCallback(async (domain: string) => {
        try {
            const fappMonitors = await getFappMonitorByDomain({ domain });
            // const fappMonitors: FappMonitorState = { isLoaded: true, payload: [] };

            setFappMonitors(fappMonitors);
        } catch (error) {
            throw error;
        }
    }, []);

    const _fetchMonitorServers = useCallback(async () => {
        try {
            screenLoader.show();

            await serverConfigStore.fetchMonitorServers(monitorServers => {
                const foundMonitorServer = monitorServers.ResponseAdminServer.find(
                    response => response.id === id
                );
                if (!foundMonitorServer) {
                    throw new Error('This server id not found.');
                }

                serverConfigStore.setSelectedServer(foundMonitorServer);
            });
        } catch (error) {
            if (error instanceof Error) {
                _redirectToHomeWhenError(error.message);
            }
        } finally {
            screenLoader.hide();
        }
    }, [serverConfigStore]);

    const _redirectToHomeWhenError = (message: string) => {
        Swal.fire('เกิดข้อผิดพลาด!', message, 'error').then(result => {
            if (result.isConfirmed) {
                history.push('/');
            }
        });
    };

    return {
        fappMonitors,
        selectedMonitorServer,
        handleClick,
        chartDataServerMonitorInday,
        chartOpen,
        onClickReset,
        onClickFilter,
        onSelectDate,
        startDate,
        endDate
    };
}

export default DashboardServerDetailViewModel;
