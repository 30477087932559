import { useEffect, useState } from 'react';
import Swal from 'sweetalert2';
import useLoader from 'hooks/useLoader';
import {
    F1ApiLogDeleteParams,
    F1ApiLogParams,
    F1ApiLogResponse,
    F1ApiLogSizeParams,
    F1ApiLogSizeResponse
} from 'data/schemas/apiLog';
import {
    deleteLogF1Api,
    getF1ApiLog,
    getSizeLogF1Api
} from 'data/repositories/apiLog/f1ApiLog.repository';
import { convertToYYYYMMDD, formatDateTime } from 'helpers/formatDateTime';
import { useBoolean } from '@chakra-ui/react';

function F1ApiLogViewModel() {
    const loading = useLoader();

    const headers: string[] = ['ID', 'Api Name', 'Level', 'Time Stamp', 'Created By', 'See More'];
    const companyList: { value: string; name: string }[] = [
        { name: 'Eps Prd', value: 'eps_db_prd' },
        { name: 'Qmix Prd', value: 'qmix_db_prd' },
        { name: 'Scg Cement Prd', value: 'scg_cement_db_prd' },
        { name: 'Scieco Prd', value: 'scieco_db_prd' },
        { name: 'Slsn Prd', value: 'slsn_prd' },
        { name: 'Slts Prd', value: 'slts_prd' },
        { name: 'Sric Prd', value: 'sric_db_prd' }
        // { name: 'Eps Uat', value: 'eps_db_uat' }
    ];

    const [dateForm, setDateForm] = useState(new Date());
    const [dateTo, setDateTo] = useState(new Date());
    const [totalPages, setTotalPage] = useState<number>(1);
    const [f1ApiLogResponse, setF1ApiLogResponse] = useState<F1ApiLogResponse[]>();
    const [f1ApiLogDetail, setF1ApiLogDetail] = useState<F1ApiLogResponse>();
    const [showF1ApiLogDetail, setShowF1ApiLogDetail] = useBoolean();
    const [showModalDeleteLogF1Api, setShowModalDeleteLogF1Api] = useBoolean();
    const [f1ApiLogSizeResponse, setF1ApiLogSizeResponse] = useState<F1ApiLogSizeResponse>();
    const [company, setCompany] = useState<string>();

    const [searchHelp, setSearchHelp] = useState<F1ApiLogParams>({
        company: '',
        apiName: '',
        createdFrom: convertToYYYYMMDD(dateForm.toISOString()),
        createdTo: '',
        page: 1,
        pageSize: 25
    });

    const [deleteF1ApiLog, setDeleteF1ApiLog] = useState<F1ApiLogDeleteParams>({
        company: '',
        createdFrom: '',
        createdTo: ''
    });

    useEffect(() => {
        const fetchData = async () => {
            await handleSearchApiLog();
        };

        if (f1ApiLogResponse !== undefined) {
            fetchData();
        }
    }, [searchHelp.pageSize, searchHelp.page]);

    const handlePageChange = ({ selected }) => {
        handleSearchChange('page', selected + 1);
    };

    const handleSearchChange = <K extends keyof F1ApiLogParams>(
        key: K,
        value: F1ApiLogParams[K]
    ) => {
        setSearchHelp(prevState => ({
            ...prevState,
            [key]: value
        }));
    };

    const handleSearchApiLog = async () => {
        if (searchHelp.company === '') {
            Swal.fire({
                icon: 'warning',
                title: 'Warning',
                text: `กรุณาเลือก Company`,
                allowOutsideClick: false,
                customClass: {
                    container: 'z-[9999]'
                }
            });

            return;
        }
        try {
            loading.show();
            const response = await getF1ApiLog(searchHelp);

            if (response) {
                const resultList = response.resultList;
                const totalCount = response.totalCount;
                console.log(response);

                if (resultList && totalCount !== undefined) {
                    setF1ApiLogResponse(response.resultList);
                    setTotalPage(Math.ceil(response.totalCount / searchHelp.pageSize));
                }
            }
        } catch {
            Swal.fire({
                icon: 'error',
                title: 'Error',
                text: `ไม่พบข้อมูล`,
                allowOutsideClick: false,
                customClass: {
                    container: 'z-[9999]'
                }
            });
        } finally {
            loading.hide();
        }
    };

    const handleOpenModalShowDetailF1ApiLog = (detailApiLog: F1ApiLogResponse) => {
        setF1ApiLogDetail(detailApiLog);
        setShowF1ApiLogDetail.on();
    };

    const handleOpenModalShowDeleteF1ApiLog = async () => {
        if (searchHelp.company === '') {
            Swal.fire({
                icon: 'warning',
                title: 'Warning',
                text: `กรุณาเลือก Company`,
                allowOutsideClick: false,
                customClass: {
                    container: 'z-[9999]'
                }
            });

            return;
        }

        try {
            loading.show();

            const logSizeResponse = await getSizeLogF1Api({
                company: searchHelp.company
            });

            setCompany(searchHelp.company);

            if (logSizeResponse) {
                setF1ApiLogSizeResponse(logSizeResponse);
                setShowModalDeleteLogF1Api.on();
            }
        } finally {
            loading.hide();
        }
    };

    const handleClearLog = async () => {
        if (deleteF1ApiLog.company === '') {
            Swal.fire({
                icon: 'warning',
                title: 'Warning',
                text: `กรุณาเลือก Company`,
                allowOutsideClick: false,
                customClass: {
                    container: 'z-[9999]'
                }
            });

            return;
        }

        if (deleteF1ApiLog.createdFrom === '' || deleteF1ApiLog.createdTo === '') {
            Swal.fire({
                icon: 'warning',
                title: 'Warning',
                text: `กรุณาเลือก วันที่`,
                allowOutsideClick: false,
                customClass: {
                    container: 'z-[9999]'
                }
            });

            return;
        }

        try {
            loading.show();
            const deleteLogResponse = await deleteLogF1Api(deleteF1ApiLog);
            const logSizeResponse = await getSizeLogF1Api({
                company: searchHelp.company
            });

            if (deleteLogResponse) {
                Swal.fire({
                    icon: 'success',
                    title: 'Success',
                    text: `ลบ Log ช่วงเวลา ${formatDateTime(deleteF1ApiLog.createdFrom)} ถึง ${formatDateTime(deleteF1ApiLog.createdTo)} เสร็จสิ้น`,
                    allowOutsideClick: false,
                    customClass: {
                        container: 'z-[9999]'
                    }
                });
            }

            if (logSizeResponse) {
                setF1ApiLogSizeResponse(logSizeResponse);
            }
        } finally {
            loading.hide();
        }
    };

    const handleChangeDeleteLogParams = <K extends keyof F1ApiLogDeleteParams>(
        key: K,
        value: F1ApiLogDeleteParams[K]
    ) => {
        setDeleteF1ApiLog(prevState => ({
            ...prevState,
            [key]: value
        }));
    };

    return {
        headers,
        company,
        companyList,
        dateForm,
        dateTo,
        searchHelp,
        totalPages,
        f1ApiLogResponse,
        f1ApiLogDetail,
        showF1ApiLogDetail,
        showModalDeleteLogF1Api,
        f1ApiLogSizeResponse,

        setDateTo,
        setDateForm,
        handlePageChange,
        handleSearchChange,
        handleSearchApiLog,
        handleClearLog,
        setShowF1ApiLogDetail,
        setShowModalDeleteLogF1Api,
        handleChangeDeleteLogParams,
        handleOpenModalShowDetailF1ApiLog,
        handleOpenModalShowDeleteF1ApiLog
    };
}

export default F1ApiLogViewModel;
