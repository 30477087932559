import React from 'react';
import { Button, Input } from 'reactstrap';
import CreatableSelect from 'react-select/creatable';

import '../../../../assets/style/style.css';
import { CommandType, FappPortOptions, RunCommandResponse } from '../../../../models/AdminServer';
import { CommandConfigMaster } from '../../../../models/CommandConfig';

interface SvnUpdateProps {
    ports: FappPortOptions[];
    onSelectPort: (port: string) => void;
    onClickRunCommand: (command: CommandType, path: string) => void;
    output: RunCommandResponse[];
    onChangeRevision: (revision: string) => void;
    revision: string;
    commandConfigsMaster: CommandConfigMaster[];
}

const SvnUpdateComponent = (props: SvnUpdateProps) => {
    const validateCommandBtn = (commandConfig: CommandConfigMaster): CommandType => {
        switch (commandConfig.command_name) {
            case 'SVN UPDATE': {
                return { name: commandConfig.command_name, script: `-r ${props.revision}` };
            }
            default: {
                return { name: commandConfig.command_name };
            }
        }
    };

    return (
        <>
            {/* Select Port */}
            <div className="mb-2">
                <label className="headTBox mb-1">Select Port (Update target)</label>
                <CreatableSelect
                    className="w-100"
                    isClearable={false}
                    options={props.ports}
                    onChange={port => props.onSelectPort(port?.value ?? '')}
                    isValidNewOption={() => false}
                />
            </div>

            {/* Enter revision number */}
            <div className="mb-2">
                <label className="headTBox mb-1">Revision Target (Update item to revision)</label>
                <Input
                    value={props.revision}
                    onChange={e => props.onChangeRevision(e.target.value)}
                />
            </div>

            {/* Command Button */}
            <div className="my-1">
                {/* <Button className="btn-danger mr-2">Web Config Editor</Button> */}
                {props.commandConfigsMaster &&
                    props.commandConfigsMaster.length > 0 &&
                    props.commandConfigsMaster.map((command, index) => {
                        return (
                            <Button
                                key={index}
                                onClick={() =>
                                    props.onClickRunCommand(validateCommandBtn(command), '')
                                }
                                className="mr-2"
                                style={{
                                    background: command.btn_color,
                                    borderColor: command.btn_color
                                }}
                            >
                                {command.btn_name}
                            </Button>
                        );
                    })}
            </div>

            {/* Output Console */}
            <div className="my-1">
                <label className="headTBox">Output</label>
                <div className="mat-box svn-result text-light">
                    {props.output.map(result => result.message + '\n')}
                </div>
            </div>
        </>
    );
};

export default SvnUpdateComponent;
