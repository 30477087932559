import { Badge, FormControl, Select } from "@chakra-ui/react";
import { TransportRequestDetail } from "data/TransportRequest";
import React, { useState } from "react";
import { twMerge } from "tailwind-merge";

type Props = {
    transportRequestDetail: TransportRequestDetail;
    handleSelectFileStandbyPRD: (details: TransportRequestDetail, checked: boolean) => void;
    checkedState: { [key: string]: boolean }
}

function CardTransportRequestPRD(props: Props) {
    const { transportRequestDetail, handleSelectFileStandbyPRD, checkedState } = props;

    return (
        <div
            className={
                twMerge(
                    'mb-2 flex flex-row gap-2 rounded-md border-b border-r border-t border-neutral-400 p-2',
                    transportRequestDetail.module === 'FAPP'
                        ? 'border-l-8 border-l-green-500'
                        : transportRequestDetail.module === 'FGUI'
                            ? 'border-l-8 border-l-teal-400'
                            : 'border-l-8 border-l-violet-600'
                )
            }
        >
            <div className="p-2.5">
                <div
                    id="row-0"
                    className="flex flex-col gap-1"
                >
                    <div className='flex flex-row justify-between'>
                        <Badge
                            variant="outline"
                            colorScheme={
                                transportRequestDetail.module === 'FAPP'
                                    ? 'green'
                                    : transportRequestDetail.module === 'FGUI'
                                        ? 'teal'
                                        : 'purple'
                            }
                            className="w-fit"
                        >
                            {transportRequestDetail.module}
                        </Badge>
                    </div>
                    <span className="mt-2 text-sm font-bold">
                        File Name : {transportRequestDetail.deploymentObject}
                    </span>
                    <span className="mt-2 text-sm font-bold">
                        Version : {transportRequestDetail.version}
                    </span>
                </div>
                <div className="mt-2">
                    <div
                        id="row-1"
                        className="grid grid-cols-2 gap-2"
                    >
                        <div className="flex flex-col gap-2">
                            <div className="flex flex-col">
                                <span className="text-sm font-medium">
                                    Is Core
                                    <span className="relative bottom-1 font-bold text-red-600">
                                        *
                                    </span>
                                </span>
                                <FormControl>
                                    <Select
                                        name="core_or_noncore"
                                        size="sm"
                                        className="rounded-sm border-neutral-400 text-black"
                                        placeholder="Please choose one"
                                        value={transportRequestDetail.coreOrNonCore}
                                        disabled
                                    >
                                        <option value="CORE">Core</option>
                                        <option value="NON CORE">Non-Core</option>
                                    </Select>
                                </FormControl>
                            </div>
                        </div>
                        <div className="flex flex-col">
                            <span className="text-sm font-medium">
                                Add / Edit
                                <span className="relative bottom-1 font-bold text-red-600">
                                    *
                                </span>
                            </span>
                            <FormControl>
                                <Select
                                    name="add_or_edit"
                                    size="sm"
                                    className="rounded-sm border-neutral-400 text-black"
                                    placeholder="Please choose one"
                                    value={transportRequestDetail.addOrEdit}
                                    disabled
                                >
                                    <option value="ADD">ADD</option>
                                    <option value="EDIT">EDIT</option>
                                </Select>
                            </FormControl>
                        </div>
                    </div>
                </div>
            </div>
            <div className="flex flex-1 justify-center items-center">
                <input
                    type="checkbox"
                    className="w-5 h-5"
                    checked={checkedState[transportRequestDetail.deploymentObject] || false}
                    defaultChecked={transportRequestDetail.readyForPrd}
                    disabled={transportRequestDetail.readyForPrd}
                    onChange={(e) => { handleSelectFileStandbyPRD(transportRequestDetail, e.target.checked) }}
                />
            </div>
        </div>
    )
}

export default CardTransportRequestPRD;