import { AxiosError } from 'axios';

import { validateDataResponse } from '../../../commons/helpers';
import {
    deleteF1ApiLog,
    retrieveF1ApiLog,
    retrieveF1ApiLogSize
} from 'data/datasources/v1/apiLog/f1ApiLog.datasource';
import {
    F1ApiLogDeleteParams,
    F1ApiLogDeleteResponse,
    F1ApiLogParams,
    F1ApiLogResponse,
    F1ApiLogReturn,
    F1ApiLogSizeParams,
    F1ApiLogSizeResponse
} from 'data/schemas/apiLog';

export async function getF1ApiLog(params: F1ApiLogParams): Promise<F1ApiLogReturn> {
    try {
        const response = await retrieveF1ApiLog(params);
        const result: F1ApiLogReturn = validateDataResponse(response);

        return result;
    } catch (error) {
        if (error instanceof AxiosError && error.response) {
            throw new Error(validateDataResponse(error.response).message);
        }

        throw error;
    }
}

export async function deleteLogF1Api(
    params: F1ApiLogDeleteParams
): Promise<F1ApiLogDeleteResponse> {
    try {
        const response = await deleteF1ApiLog(params);
        const result: F1ApiLogDeleteResponse = validateDataResponse(response);

        return result;
    } catch (error) {
        if (error instanceof AxiosError && error.response) {
            throw new Error(validateDataResponse(error.response).message);
        }

        throw error;
    }
}

export async function getSizeLogF1Api(params: F1ApiLogSizeParams): Promise<F1ApiLogSizeResponse> {
    try {
        const response = await retrieveF1ApiLogSize(params);
        const result: F1ApiLogSizeResponse = validateDataResponse(response);

        return result;
    } catch (error) {
        if (error instanceof AxiosError && error.response) {
            throw new Error(validateDataResponse(error.response).message);
        }

        throw error;
    }
}
