import React, { useMemo } from 'react';
import { IoIosSearch } from 'react-icons/io';
import { TbPlayerTrackNextFilled } from 'react-icons/tb';
import {
    Table,
    TableContainer,
    Tbody,
    Td,
    Th,
    Thead,
    Tr,
    useBoolean,
    useToast
} from '@chakra-ui/react';

import { useStore } from 'commons/hooks';
import { TransportRequestTag } from 'data/schemas/TransportRequest';
import { FaEdit } from 'react-icons/fa';
import { FaDownload } from 'react-icons/fa6';

type Props = {
    transportRequestTag: TransportRequestTag[];
    onClickDeploy: (tag: TransportRequestTag) => void;
    onClickShowModalEdit: (transportRequest: TransportRequestTag) => void;
    onClickDowloadFile: (uatTag: string) => void;
    onClickShowModalReadyForPrd: (uatTag: TransportRequestTag) => void;
};

function TransportRequestTable(props: Props) {
    const { transportRequestTag, onClickDeploy, onClickShowModalEdit, onClickDowloadFile, onClickShowModalReadyForPrd } = props;
    const { authentication } = useStore();
    const toast = useToast();

    const headers = useMemo(
        () => [
            '',
            '',
            'Ready For Prd',
            'UAT TAG',
            'Module',
            'Deployment Object',
            'Version',
            'Status',
            'Ticket',
            'Detail',
            'Feature',
            'Core / Non Core',
            'Add / Edit',
            'Impact',
            'Risk Level',
            'Remark',
            'Developer',
            'Deploy',
            'Log'
        ],
        []
    );

    const employeeData = authentication.masterEmployeeResponse;

    const CopyToClipboard = (tagDetail: TransportRequestTag) => {
        const { uatTag, developer, details } = tagDetail;
        const detailsWithTextWrap = '"' + details.trim() + '"';
        const nullStringRegEx = new RegExp('null', 'g');

        const outputLines = tagDetail.transportRequestDetail.map((detail, index) => {
            const {
                module,
                deploymentObject,
                version,
                ticket,
                feature,
                coreOrNonCore,
                addOrEdit,
                impact,
                riskLevel
            } = detail;

            if (index === 0) {
                const fistRowClipboardMessage =
                    uatTag +
                    '\t' +
                    developer +
                    '\t' +
                    module.toUpperCase() +
                    '\t' +
                    deploymentObject +
                    '\t' +
                    version +
                    '\t' +
                    ticket +
                    '\t' +
                    detailsWithTextWrap +
                    '\t' +
                    feature +
                    '\t' +
                    coreOrNonCore +
                    '\t' +
                    addOrEdit +
                    '\t' +
                    impact +
                    '\t' +
                    riskLevel;

                return fistRowClipboardMessage.replace(nullStringRegEx, '');
            }

            const clipboardMessage =
                '\t' +
                '\t' +
                module.toUpperCase() +
                '\t' +
                deploymentObject +
                '\t' +
                version +
                '\t' +
                //ticket +
                '\t' +
                //details +
                '\t' +
                //feature +
                '\t' +
                coreOrNonCore +
                '\t' +
                addOrEdit +
                '\t' +
                //impact +
                '\t';
            //riskLevel;

            return clipboardMessage.replace(nullStringRegEx, '');
        });

        toast({ title: 'Copied', status: 'success', duration: 2000 });
        navigator.clipboard.writeText(outputLines.join('\n'));
    };

    return (
        <TableContainer
            whiteSpace="wrap"
            className="mt-2"
        >
            <Table
                size="sm"
                variant="unstyled"
            >
                <Thead>
                    <Tr>
                        {headers.map(header => (
                            <Th
                                key={header}
                                className="border text-center"
                                style={{
                                    borderColor:
                                        'rgb(212 212 212 / var(--tw-border-opacity)) !important'
                                }}
                            >
                                {header}
                            </Th>
                        ))}
                    </Tr>
                </Thead>
                <Tbody className="overflow-x-scroll">
                    {transportRequestTag ? (
                        transportRequestTag.map((tag, rowIndex) => {
                            const rowSpan = tag.transportRequestDetail.length;

                            return tag.transportRequestDetail.map((detail, detailIndex) => (
                                <Tr
                                    key={tag.uatTag + detailIndex}
                                    className="hover:bg-gray-50 [&>td]:border [&>td]:border-neutral-300"
                                    onDoubleClick={() => {
                                        CopyToClipboard(tag);
                                    }}
                                >
                                    {detailIndex === 0 && (
                                        <React.Fragment>
                                            <Td
                                                rowSpan={rowSpan}
                                                className="cursor-pointer text-center"
                                                onClick={() => onClickDowloadFile(tag.uatTag)}
                                            >
                                                <FaDownload className="text-green-500" />
                                            </Td>
                                            <Td
                                                rowSpan={rowSpan}
                                                className="cursor-pointer text-center"
                                                onClick={() => onClickShowModalEdit(tag)}
                                            >
                                                <FaEdit className="text-yellow-500" />
                                            </Td>
                                        </React.Fragment>
                                    )}
                                    <Td className="text-center">
                                        <input
                                            type="checkbox"
                                            defaultChecked={detail.readyForPrd}
                                            disabled={detail.readyForPrd}
                                            onClick={() => onClickShowModalReadyForPrd(tag)}
                                        />
                                    </Td>
                                    {detailIndex === 0 && (
                                        <Td
                                            rowSpan={rowSpan}
                                            className="whitespace-nowrap text-nowrap"
                                        >
                                            {tag.uatTag}
                                        </Td>
                                    )}
                                    <Td>
                                        <span>{detail.module.toUpperCase()}</span>
                                    </Td>
                                    <Td className="max-w-[460px] truncate">
                                        {detail.deploymentObject}
                                    </Td>
                                    <Td>
                                        <span>{detail.version}</span>
                                    </Td>
                                    <Td
                                        className={
                                            detail.deployStatus === 'Online'
                                                ? 'font-bold text-green-600'
                                                : 'font-bold text-yellow-500'
                                        }
                                    >
                                        {detail.deployStatus}
                                    </Td>
                                    {detailIndex === 0 && (
                                        <React.Fragment>
                                            <Td rowSpan={rowSpan}>{detail.ticket}</Td>
                                            <Td rowSpan={rowSpan}>{tag.details}</Td>
                                            <Td rowSpan={rowSpan}>{detail.feature}</Td>
                                        </React.Fragment>
                                    )}
                                    <Td>{detail.coreOrNonCore}</Td>
                                    <Td>{detail.addOrEdit}</Td>
                                    {detailIndex === 0 && (
                                        <React.Fragment>
                                            <Td
                                                rowSpan={rowSpan}
                                                className="min-w-[360px] text-wrap"
                                            >
                                                {detail.impact}
                                            </Td>
                                            <Td rowSpan={rowSpan}>{detail.riskLevel}</Td>
                                            <Td rowSpan={rowSpan}>{detail.remark}</Td>
                                            <Td rowSpan={rowSpan}>{tag.developer}</Td>
                                            <Td
                                                className="text-center"
                                                rowSpan={rowSpan}
                                            >
                                                <button
                                                    onClick={() => onClickDeploy(tag)}
                                                    disabled={
                                                        detail.deployStatus === 'Online' ||
                                                        (employeeData?.type !== 'super_admin' &&
                                                            employeeData?.ctizenID !==
                                                            tag.developer)
                                                    }
                                                >
                                                    <TbPlayerTrackNextFilled
                                                        size={24}
                                                        className={
                                                            detail.deployStatus === 'Online' ||
                                                                (employeeData?.type !== 'super_admin' &&
                                                                    employeeData?.ctizenID !==
                                                                    tag.developer)
                                                                ? 'pointer-events-none text-gray-600'
                                                                : 'text-green-600'
                                                        }
                                                    />
                                                </button>
                                            </Td>
                                        </React.Fragment>
                                    )}
                                    {detailIndex === 0 && (
                                        <Td
                                            rowSpan={rowSpan}
                                            className="text-center"
                                        >
                                            <button>
                                                <IoIosSearch size={24} />
                                            </button>
                                        </Td>
                                    )}
                                </Tr>
                            ));
                        })
                    ) : (
                        <tr className="text-center">
                            <td colSpan={headers.length}>
                                <span>No data..</span>
                            </td>
                        </tr>
                    )}
                </Tbody>
            </Table>
        </TableContainer>
    );
}

export default TransportRequestTable;
