import { SiChakraui } from 'react-icons/si';

import { WebMonitor } from '../../../../models/AdminWeb';
import { dbDateTimeToDisplayString } from '../../../../commons/common';

type Column = {
    key: string;
    text: string;
    className: string;
    sortable: boolean;
    cell: (record: WebMonitor, index: number) => React.JSX.Element;
};

export const webMonitorColumns: Column[] = [
    {
        key: 'status',
        text: 'Status',
        className: 'Status flex justify-center',
        sortable: true,
        cell: (record, index) => {
            return (
                <SiChakraui
                    style={{
                        textAlign: 'center',
                        color: record.status === false ? 'red' : 'green'
                    }}
                />
            );
        }
    },
    {
        key: 'updateOn',
        text: 'Last Status',
        className: 'LastStatus',
        sortable: true,
        cell: (record, index) => {
            return (
                <div
                    style={{
                        textAlign: 'center'
                    }}
                >
                    {dbDateTimeToDisplayString(record.updatedOn)}
                </div>
            );
        }
    },
    {
        key: 'name',
        text: 'Name',
        className: 'text-left',
        sortable: true,
        cell: (record, index) => <div>{record.name}</div>
    },
    {
        key: 'domain',
        text: 'Domain',
        className: 'text-left',
        sortable: true,
        cell: (record, index) => <div>{record.domain}</div>
    }
];
