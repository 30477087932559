import axios from 'axios';

import { FappUpdateRequest, RunCommandResponse } from '../../../schemas/fapp';

export async function runCommand(params: FappUpdateRequest) {
    return axios.post<RunCommandResponse[]>('v1/fapp/update', params, {
        headers: {
            Authorization: 'Bearer ' + localStorage.getItem('MM_APPLICATION')
        }
    });
}
