import { useState } from 'react';

function useDisclosure(initial: boolean = false) {
    const [isOpen, setIsOpen] = useState(initial);

    const onToggle = () => setIsOpen(!isOpen);
    const onOpen = () => setIsOpen(true);
    const onClose = () => setIsOpen(false);

    const provide = {
        isOpen,
        onToggle,
        onOpen,
        onClose
    }

    return provide;
}

export default useDisclosure;