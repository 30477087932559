import { AxiosError } from 'axios';
import { validateDataResponse } from '../../../commons/helpers';
import { CommandConfig, CommandConfigMaster } from '../../../models/CommandConfig';
import { getAllMaster, getByDomain } from '../../datasources/v1/command';
import {
    CommandConfigMasterResponse,
    GetCommandConfigByDomainRequest,
    GetCommandConfigMasterRequest
} from '../../schemas/command';

export async function getCommandConfigsByDomainAndFilterWithPortName(
    params: GetCommandConfigByDomainRequest
): Promise<CommandConfig[]> {
    try {
        const response = await getByDomain(params);
        const result: CommandConfig[] = validateDataResponse(response);

        return result;
    } catch (error) {
        if (error instanceof AxiosError && error.response) {
            throw new Error(validateDataResponse(error.response).message);
        }

        throw error;
    }
}

export async function getCommandConfigsMaster(
    params: GetCommandConfigMasterRequest
): Promise<CommandConfigMaster[]> {
    try {
        const response = await getAllMaster(params);
        const result: CommandConfigMaster[] = validateDataResponse(response);

        return result;
    } catch (error) {
        if (error instanceof AxiosError && error.response) {
            throw new Error(validateDataResponse(error.response).message);
        }

        throw error;
    }
}
