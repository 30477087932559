import { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import axios, { AxiosError } from 'axios';
import { useBoolean, useToast } from '@chakra-ui/react';
import type { NodeApi, TreeApi } from 'react-arborist';
import Swal from 'sweetalert2';

import type {
    MigrationScriptGroup,
    MigrationScriptGroupContent,
    MigrationScriptGroupFolder,
    MigrationSystemAllScriptGoupAndDatabase,
    WebSocketRequest,
    WebSocketResponse
} from 'models/Migration';

import { isJson, isPing, isWebSocketResponse } from 'commons/helpers';
import { useLoader } from 'commons/hooks';
import { getDbDateTime } from 'commons/common';

const DESTRUCTION_TIMEOUT = 120000;

function MigrationViewModel() {
    const screenLoader = useLoader();
    const toast = useToast({ duration: 5000, isClosable: true });

    const [files, setFiles] = useState<MigrationScriptGroup[]>([]);
    const folders = useMemo(() => {
        const folders: MigrationScriptGroupFolder[] = [];
        files.forEach(script => {
            const foundFolder = folders.find(f => f.id === script.folder);
            if (!foundFolder) {
                folders.push({
                    id: script.folder,
                    files: [script]
                });
            } else {
                foundFolder.files.push(script);
            }
        });

        return folders;
    }, [files]);
    const [selectedFiles, setSelectedFiles] = useState<MigrationScriptGroup[]>([]);
    const fileTreeRef = useRef<TreeApi<MigrationScriptGroupFolder>>();

    const [outputLogs, setOutputLogs] = useState<WebSocketResponse[]>([]);
    const virtualTerminalRef = useRef<HTMLDivElement>(null);

    const [showSkippableModal, setShowSkippableModal] = useBoolean();
    const [error, setError] = useState({ fileName: '', message: '', indexOfTransaction: 0 });
    const skipResolverRef = useRef<(value: 'skip' | 'skip_all' | 'cancel') => void>();

    const wsRef = useRef<WebSocket>();
    const connectedRef = useRef<WebSocketRequest>();

    const [selectedFileContent, setSelectedFileContent] = useState('');
    const cacheFileContents = useRef({});

    const [isSelectedAll, setIsSelectedAll] = useState(false);

    const [scriptGroupDatabase, setScriptGroupDatabase] =
        useState<MigrationSystemAllScriptGoupAndDatabase[]>();

    // Auto scroll to bottom when output log changes
    useEffect(() => {
        virtualTerminalRef.current?.scrollTo(0, virtualTerminalRef.current.scrollHeight);
    }, [outputLogs]);

    // This will trigger when run all files
    useEffect(() => {
        if (isSelectedAll && selectedFiles.length === files.length) {
            runScripts(selectedFiles);
        }
    }, [isSelectedAll, selectedFiles, files]);

    useEffect(() => {
        fetchScriptGroptOptions();
    }, []);

    const fetchScriptGroptOptions = async () => {
        try {
            const allScriptGoupAndDatabase = (
                await axios.get<MigrationSystemAllScriptGoupAndDatabase[]>(
                    '/v1/migration-scriptGroup-database'
                )
            ).data;
            setScriptGroupDatabase(allScriptGoupAndDatabase!);
        } catch (error) {
            console.error('Error:', error);
        }
    };

    const fetchFileContent = async (folder: string, id: string) => {
        try {
            const connection = connectedRef.current;
            if (!connection) {
                return;
            }

            const fileContent = cacheFileContents.current[id];
            if (fileContent) {
                setSelectedFileContent(fileContent.content);
                clearTimeout(fileContent.destructionId);
                fileContent.destructionId = setTimeout(
                    () => delete cacheFileContents.current[id],
                    DESTRUCTION_TIMEOUT
                );
                return;
            }

            const data = (
                await axios.get<MigrationScriptGroupContent>(
                    `v1/migration-script/${connection.script_group}/${folder}/${id}`
                )
            ).data;
            cacheFileContents.current[id] = {
                content: data.content,
                destructionId: setTimeout(
                    () => delete cacheFileContents.current[id],
                    DESTRUCTION_TIMEOUT
                )
            };
            setSelectedFileContent(data.content);
        } catch (error) {
            toast({
                title: 'Open file error',
                description: `Failed to retreive file content from ${folder}/${id}`,
                status: 'error'
            });
        }
    };

    const handleClickConnect = useCallback(async (connection: WebSocketRequest) => {
        try {
            screenLoader.show();

            await connectToWebSocketAndDatabase(connection);

            const scripts = (
                await axios.get<MigrationScriptGroup[]>('/v1/migration-script', {
                    params: {
                        script_group: connection.script_group
                    }
                })
            ).data;

            connectedRef.current = connection;
            setFiles(scripts);
        } catch (error) {
            if (error instanceof AxiosError) {
                Swal.fire(
                    'Error!',
                    'Cannot connect to this connection ' + (error as AxiosError).response?.data,
                    'error'
                );
            } else if (error instanceof Error) {
                Swal.fire('Error!', error.message, 'error');
            }
        } finally {
            screenLoader.hide();
        }
    }, []);

    const connectToWebSocketAndDatabase = useCallback((connection: WebSocketRequest) => {
        return new Promise<void>((resolve, reject) => {
            const url = window.location.href.includes('local')
                ? 'ws://localhost:8082/ws'
                : 'wss://ag-management-api.sonarinno.com/ws';

            if (connectedRef.current?.connection_id) {
                reject(new Error('Database already connected.'));

                return;
            }

            wsRef.current = new WebSocket(url);
            const ws = wsRef.current;

            // Listening open connection
            ws.addEventListener('open', _event => {
                // Send connect to database after connected to websocket
                ws.send(
                    JSON.stringify({
                        username: 'focusone',
                        client_action: 'connect',
                        value: '',
                        host: connection.host,
                        database: connection.database,
                        connection_id: '',
                        script_group: connection.script_group,
                        folder: '',
                        id: '',
                        index_of_transaction: 0
                    })
                );
            });

            // Listening the database is connected or not
            let onMessage: (this: WebSocket, event: MessageEvent<string | any>) => void;
            ws.addEventListener(
                'message',
                (onMessage = event => {
                    if (!isJson(event.data)) {
                        return;
                    }

                    const data = JSON.parse(event.data);
                    if (!isWebSocketResponse(data)) {
                        return;
                    }

                    if (
                        !data.connection_id ||
                        !data.detail.status ||
                        data.detail.status !== 'success'
                    ) {
                        ws.close(200, 'Database is not connected.');
                        delete wsRef.current;

                        // Return to caller but doesn't process the rest of the script below
                        reject();

                        return;
                    }

                    // Save connection id and connection string
                    connection.connection_id = data.connection_id;
                    connectedRef.current = connection;

                    // Show success toast
                    toast({ title: 'Connected to server success!', duration: 6000 });

                    // Return to caller but still processing the rest of the script below
                    resolve();

                    // Remove this listener
                    ws.removeEventListener('message', onMessage);
                })
            );

            // Listening ping from server
            ws.addEventListener('message', event => {
                console.info('Received ping?', event.data);

                if (!isJson(event.data)) {
                    return;
                }

                if (!isPing(JSON.parse(event.data))) {
                    return;
                }

                const currentConnection = connectedRef.current ?? connection;
                ws.send(
                    JSON.stringify({
                        username: 'focusone',
                        client_action: 'pong',
                        script_group: currentConnection.script_group,
                        host: currentConnection.host,
                        database: currentConnection.database,
                        connection_id: currentConnection.connection_id
                    } as WebSocketRequest)
                );

                // Debugging pong
                console.info('pong', event.data);
            });
        });
    }, []);

    const handleClickExportRunningLog = useCallback(() => {
        const virtualTerminal = virtualTerminalRef.current;
        if (!virtualTerminal) {
            Swal.fire('Error!', 'Virtual terminal is not ready', 'error');

            return;
        }

        const outputLogsContainer = virtualTerminal.querySelector('#output-log-container');
        if (!outputLogsContainer) {
            Swal.fire('Error!', 'Output log is not ready', 'error');

            return;
        }

        const logs = outputLogsContainer.innerHTML
            .replace(/<style([\s\S]*?)<\/style>/gi, '')
            .replace(/<script([\s\S]*?)<\/script>/gi, '')
            .replace(/<\/div>/gi, '\n')
            .replace(/<\/li>/gi, '\n')
            .replace(/<li>/gi, '  *  ')
            .replace(/<\/ul>/gi, '\n')
            .replace(/<\/p>/gi, '\n')
            .replace(/<br\s*[\/]?>/gi, '\n')
            .replace(/<[^>]+>/gi, '');

        const blob = new Blob([logs], { type: 'octet/stream' });
        const url = URL.createObjectURL(blob);

        const a = document.createElement('a');
        a.href = url;
        a.download = `execute-logs-${getDbDateTime()}.txt`;
        a.click();

        URL.revokeObjectURL(url);
    }, []);

    const handleSelectFiles = useCallback(
        (nodes: NodeApi<MigrationScriptGroupFolder>[]) => {
            if (isSelectedAll) {
                return;
            }

            const selectedFiles = nodes
                .filter(node => node.id.includes('.txt'))
                .map(node => node.data as unknown as MigrationScriptGroup);

            setSelectedFiles(selectedFiles);

            if (selectedFiles.length > 0) {
                fetchFileContent(selectedFiles[0].folder, selectedFiles[0].id);
            }
        },
        [isSelectedAll]
    );

    const handleClickRunSelectedFiles = useCallback(() => {
        runScripts(selectedFiles);
    }, [selectedFiles]);

    const handleClickRunAllFiles = useCallback((event: React.MouseEvent<HTMLButtonElement>) => {
        const fileTree = fileTreeRef.current;
        if (!fileTree) {
            return;
        }

        fileTree.selectAll();
        event.stopPropagation();
        setIsSelectedAll(true);

        // After that will navigate to `useEffect` above
    }, []);

    const runScripts = async (selectedFiles: MigrationScriptGroup[]) => {
        const ws = wsRef.current;
        if (!ws) {
            Swal.fire('Error', 'ไม่สามารถเชื่อมต่อ Server ได้ กรุณาเชื่อมต่อใหม่อีกครั้ง', 'error');

            return;
        }

        const connection = connectedRef.current;
        if (!connection) {
            Swal.fire(
                'Error',
                'ไม่สามารถเชื่อมต่อกับฐานข้อมูล กรุณาเชื่อมต่อใหม่อีกครั้ง',
                'error'
            );

            return;
        }

        const result = await Swal.fire({
            title: 'Warning',
            text: 'ยืนยันที่จะรันสคริปต์ที่เลือก',
            icon: 'warning',
            showCancelButton: true
        });
        if (!result.isConfirmed) {
            setIsSelectedAll(false);
            return;
        }

        let workingRequest: WebSocketRequest | undefined;
        let resolver: () => void;

        // Listen script message sequentially
        let onMessage: (this: WebSocket, event: MessageEvent<string>) => void;
        ws.addEventListener(
            'message',
            (onMessage = async (event: MessageEvent<string>) => {
                if (!isJson(event.data)) {
                    // If it's not JSON string then append this string to virtual terminal
                    setOutputLogs(prevState => [
                        ...prevState,
                        {
                            script_group: workingRequest?.script_group ?? 'UNKNOWN-SCRIPT_GROUP',
                            connection_id: workingRequest?.connection_id ?? '',
                            folder: workingRequest?.folder ?? '',
                            id: workingRequest?.id ?? '',
                            detail: {
                                status: '',
                                message: event.data,
                                index_of_transaction: 0,
                                elapsed_time: ''
                            }
                        }
                    ]);

                    return;
                }

                // If it's JSON string, go here
                const response = JSON.parse(event.data);
                if (!isWebSocketResponse(response)) {
                    return;
                }

                setOutputLogs(prevState => [...prevState, response]);

                if (response.detail.status !== 'success') {
                    const skippingType = await new Promise<'skip' | 'skip_all' | 'cancel'>(
                        resolve => {
                            skipResolverRef.current = resolve;

                            setShowSkippableModal.on();
                            setError({
                                fileName: workingRequest?.id ?? '',
                                message: response.detail.message,
                                indexOfTransaction: response.detail.index_of_transaction
                            });
                        }
                    );

                    ws.send(
                        JSON.stringify({
                            username: 'focusone',
                            connection_id: connection.connection_id,
                            client_action: skippingType,
                            value: '',
                            host: connection.host,
                            database: connection.database,
                            script_group: connection.script_group,
                            folder: response.folder,
                            id: response.id,
                            index_of_transaction: response.detail.index_of_transaction
                        } as WebSocketRequest)
                    );
                } else {
                    resolver();
                }
            })
        );

        // Feed script to server sequentially
        for (const file of selectedFiles) {
            // Jump to `onMessage` function
            await new Promise<void>(resolve => {
                resolver = resolve;

                ws.send(
                    JSON.stringify(
                        (workingRequest = {
                            username: 'focusone',
                            connection_id: connection.connection_id,
                            client_action: 'exec',
                            value: '',
                            host: connection.host,
                            database: connection.database,
                            script_group: connection.script_group,
                            folder: file.folder,
                            id: file.id,
                            index_of_transaction: 0
                        })
                    )
                );

                // Statement will be here until `resolve` is called
            });
        }

        ws.removeEventListener('message', onMessage);
    };

    return {
        folders,
        outputLogs,
        showSkippableModal,
        error,
        fileTreeRef,
        skipResolverRef,
        virtualTerminalRef,
        selectedFileContent,
        handleClickConnect,
        handleSelectFiles,
        handleClickExportRunningLog,
        handleClickRunSelectedFiles,
        handleClickRunAllFiles,
        setShowSkippableModal,
        scriptGroupDatabase
    };
}

export default MigrationViewModel;
