import { SiChakraui } from 'react-icons/si';

import { dbDateTimeToDisplayString } from '../../../../commons/common';
import { FTPMonitor } from '../../../../models/FTPMonitor';

type Column = {
    key: string;
    text: string;
    className: string;
    sortable: boolean;
    cell: (record: FTPMonitor, index: number) => React.JSX.Element;
};

export const ftpMonitorColumns: Column[] = [
    {
        key: 'status',
        text: 'Status',
        className: 'Status flex justify-center',
        sortable: true,
        cell: (record, index) => {
            return (
                <SiChakraui
                    style={{
                        textAlign: 'center',
                        color: record.status === false ? 'red' : 'green'
                    }}
                />
            );
        }
    },
    {
        key: 'updateOn',
        text: 'Last Status',
        className: 'LastStatus',
        sortable: true,
        cell: (record, index) => {
            return (
                <div
                    style={{
                        textAlign: 'center'
                    }}
                >
                    {dbDateTimeToDisplayString(record.updatedOn)}
                </div>
            );
        }
    },
    {
        key: 'name',
        text: 'Name',
        className: 'text-left',
        sortable: true,
        cell: (record, index) => <div>{record.name}</div>
    },
    {
        key: 'ip',
        text: 'IP',
        className: 'text-left',
        sortable: true,
        cell: (record, index) => <div>{record.sftpIp}</div>
    },
    {
        key: 'port',
        text: 'Port',
        className: 'text-center',
        sortable: true,
        cell: (record, index) => <div>{record.sftpPort}</div>
    },
    {
        key: 'username',
        text: 'Username',
        className: 'text-left',
        sortable: true,
        cell: (record, index) => <div>{record.sftpUsername}</div>
    }
];
