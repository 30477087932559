import { makeAutoObservable, runInAction } from 'mobx';

import RootStore from '../Root.store';
import axios, { AxiosError } from 'axios';
import { FAPPMonitor } from '../../models/AdminApplicationServer';

class FAPPMonitorStore {
    public fetching = false;
    public fappMonitors?: FAPPMonitor[] = undefined;

    constructor(private rootStore: RootStore) {
        makeAutoObservable(this);
    }

    public async fetchFAPPMonitors() {
        try {
            this.fetching = true;

            const response = await axios.get<FAPPMonitor[]>('/v1/monitor/fappspg', {
                headers: {
                    Authorization: 'Bearer ' + localStorage.getItem('MM_APPLICATION')
                }
            });
            const fappMonitors = response.data.sort((a, b) =>
                a.name < b.name ? -1 : a.name > b.name ? 1 : 0
            );

            runInAction(() => (this.fappMonitors = fappMonitors));
        } catch (error) {
            console.error('fetchFAPPMonitors', (error as AxiosError).response?.data);

            throw error;
        } finally {
            this.fetching = false;
        }
    }
}

export default FAPPMonitorStore;
