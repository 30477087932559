import React from 'react';
import { BsFillGearFill } from 'react-icons/bs';
import { SiChakraui } from 'react-icons/si';
import { HiOutlineSearch } from 'react-icons/hi';
import ReactDatatable from '@ashvin27/react-datatable';
import { Button, Progress } from 'reactstrap';
import BootstrapSwitchButton from 'bootstrap-switch-button-react';
import { AiOutlineReload } from 'react-icons/ai';

import '../../../../assets/style/style.css';
import { config } from '../../../../constants';
import { ServerMonitorOnClickType } from '../ServerMonitor.viewmodel';
import { AdminServerState } from '../../../../models/AdminServer';
import { dbDateTimeToDisplayString } from '../../../../commons/common';

interface AdminDashboardTableProps {
    handleClickEvent: (key: ServerMonitorOnClickType, value: string) => void;
    monitorServers: AdminServerState;
    onToggleSwitch: (checked: boolean) => void;
    isAutoRefresh: boolean;
    toggleRefresh: () => void;
}

const AdminDashboardTableComponent = (props: AdminDashboardTableProps) => {
    const QuickBox = ({ title, children }) => {
        return (
            <span className="text-base">
                {title} : {children}{' '}
            </span>
        );
    };

    const columns = [
        {
            key: 'status',
            text: 'Status',
            className: 'Status flex justify-center',
            sortable: true,
            cell: (record, index) => {
                return (
                    <SiChakraui
                        style={{
                            textAlign: 'center',
                            color: record.status === 'false' ? 'red' : 'green'
                        }}
                    />
                );
            }
        },
        {
            key: 'updateOn',
            text: 'Last Status',
            className: 'LastStatus',
            sortable: true,
            cell: (record, index) => {
                return (
                    <div className="text-center">
                        {dbDateTimeToDisplayString(record.updateOn)}
                    </div>
                );
            }
        },
        {
            key: 'name',
            text: 'Name',
            className: 'Name',
            sortable: true,
            cell: (record, index) => {
                return (
                    <div className="text-left">
                        {record.name}
                    </div>
                );
            }
        },
        {
            key: 'cpu',
            text: 'CPU',
            className: 'widthProgressbar flex justify-center',
            sortable: true,
            cell: (record, index) => {
                return record.cpu > 80 ? (
                    <Progress
                        color="danger"
                        className="Progressbar"
                        animated
                        value={record.cpu}
                    >
                        <span className="fontProgressbar">
                            {parseFloat(record.cpu).toFixed(2)}%
                        </span>
                    </Progress>
                ) : (
                    <Progress
                        className="Progressbar"
                        animated
                        value={record.cpu}
                    >
                        <span className="fontProgressbar">
                            {parseFloat(record.cpu).toFixed(2)}%
                        </span>
                    </Progress>
                );
            }
        },
        {
            key: 'totalRam',
            text: 'RAM',
            className: 'widthProgressbar',
            sortable: true,
            cell: (record, index) => {
                return record.totalRam > 80 ? (
                    <div className="flex justify-center">
                        <Progress
                            color="danger"
                            className="Progressbar"
                            animated
                            value={record.totalRam}
                        >
                            <span className="fontProgressbar">
                                {parseFloat(record.totalRam).toFixed(2)}%
                            </span>
                        </Progress>
                    </div>
                ) : (
                        <div className="flex justify-center">
                            <Progress
                                className="Progressbar"
                                animated
                                value={record.totalRam}
                            >
                                <span className="fontProgressbar">
                                    {parseFloat(record.totalRam).toFixed(2)}%
                                </span>
                            </Progress>
                        </div>
                );
            }
        },
        {
            key: 'totalStorage',
            text: 'HDD',
            className: 'widthProgressbar',
            sortable: true,
            cell: (record, index) => {
                return record.totalStorage > 80 ? (
                    <div className="flex justify-center">
                        <Progress
                            color="danger"
                            className="Progressbar"
                            animated
                            value={record.totalStorage}
                        >
                            <span className="fontProgressbar">
                                {parseFloat(record.totalStorage).toFixed(2)}%
                            </span>
                        </Progress>
                    </div>
                ) : (
                    <div className="flex justify-center">
                        <Progress
                            className="Progressbar"
                            animated
                            value={record.totalStorage}
                        >
                            <span className="fontProgressbar">
                                {parseFloat(record.totalStorage).toFixed(2)}%
                            </span>
                        </Progress>
                    </div>
                );
            }
        },
    ];

    return (
        <React.Fragment>
            {!props.monitorServers.isLoadAdminServer && (
                <div className="text-center">
                    <span>No content.</span>
                </div>
            )}

            {props.monitorServers && props.monitorServers.isLoadAdminServer && (
                <div
                    className="totalserver"
                    style={{ fontSize: '14px' }}
                >
                    {/* Header */}
                    <div className="d-flex align-items-center justify-content-end">
                        <div>
                            <span className="m-2 text-base">Auto Refresh</span>
                        </div>
                        <div>
                            <BootstrapSwitchButton
                                size="sm"
                                onlabel="ON"
                                offlabel="OFF"
                                checked={props.isAutoRefresh}
                                onChange={(checked: boolean) => {
                                    props.onToggleSwitch(checked);
                                }}
                                onstyle="primary"
                            />
                        </div>
                        <div>
                            <h3 className="m-2">|</h3>
                        </div>
                        <div>
                            <Button
                                color="primary"
                                onClick={props.toggleRefresh}
                                className="inline-flex items-center gap-2 px-2 py-1.5 text-base"
                            >
                                Refresh
                                <AiOutlineReload size={18} />
                            </Button>
                        </div>
                        <div className="d-flex m-2">
                            <QuickBox title="Total Server ">
                                &nbsp;{props.monitorServers.ResponseAdminServer.length}
                                &nbsp;
                            </QuickBox>
                            <QuickBox title="ON">
                                <span style={{ color: 'green' }}>
                                    {' '}
                                    &nbsp;
                                    {
                                        props.monitorServers.ResponseAdminServer.filter(
                                            item => item.status === 'true'
                                        ).length
                                    }
                                    &nbsp;
                                </span>
                            </QuickBox>
                            <QuickBox title="OFF">
                                <span style={{ color: 'red' }}>
                                    {' '}
                                    &nbsp;
                                    {
                                        props.monitorServers.ResponseAdminServer.filter(
                                            item => item.status === 'false'
                                        ).length
                                    }
                                    &nbsp;
                                </span>
                            </QuickBox>
                        </div>
                    </div>

                    {/* Table - Server Config Detail */}
                    <div className="[&_.asrt-page-length]:items-center">
                        <ReactDatatable
                            className="table-striped table-hover table"
                            tHeadClassName="custom-header-style"
                            config={config.configdatatable}
                            records={props.monitorServers.ResponseAdminServer}
                            columns={columns}
                        />
                    </div>
                </div>
            )}
        </React.Fragment>
    );
};

export default AdminDashboardTableComponent;
