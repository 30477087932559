import { Action, Reducer } from 'redux';
import { AppThunkAction } from '.';
import {
    ConnectionForm,
    DBComparisonStoreState,
    ResultCompareTable
} from '../models/DBComparison';
import { RequestOptions, ResponseList } from '../models/Common';
import { fetchWithTimeout } from '../commons/helpers';

interface LoadDbComparisonRequest {
    type: 'LOAD_DB_COMPARISON_REQUEST';
}

interface LoadDbComparisonReceived {
    type: 'LOAD_DB_COMPARISON_RECEIVED';
}

interface LoadDbComparisonFailure {
    type: 'LOAD_DB_COMPARISON_FAILURED';
}

interface ClearDbComparisoState {
    type: 'CLEAR_DB_COMPARISON';
}

type KnownAction = (
    | LoadDbComparisonRequest
    | LoadDbComparisonReceived
    | LoadDbComparisonFailure
    | ClearDbComparisoState
) &
    DBComparisonStoreState;

interface ActionCreators {
    requestDbInformation: (
        options: RequestOptions<ConnectionForm[]>
    ) => AppThunkAction<KnownAction>;
}

export const actionCreators: ActionCreators = {
    requestDbInformation:
        (options): AppThunkAction<KnownAction> =>
        (dispatch, getState) => {
            const requestPost = async () => {
                try {
                    console.log('request compare database');
                    const response = await fetchWithTimeout(
                        '/v1/dbinformation',
                        {
                            timeout: 1800000,
                            headers: {
                                'Content-Type': 'application/json'
                            },
                            method: 'POST',
                            body: JSON.stringify(options.body)
                        }
                    );
                    const data: ResponseList<ResultCompareTable[]> =
                        await response.json();

                    dispatch({
                        type: 'LOAD_DB_COMPARISON_RECEIVED',
                        responseDBInformation: data
                    });
                } catch (error: any) {
                    console.log(error);
                    dispatch({
                        type: 'LOAD_DB_COMPARISON_FAILURED',
                        messageDBInformation: error.message
                    });
                }
            };

            switch (options.method) {
                case 'POST': {
                    requestPost();
                    break;
                }
            }

            dispatch({ type: 'LOAD_DB_COMPARISON_REQUEST' });
        }
};

const initialState: DBComparisonStoreState = {
    isLoadingDBInformation: false,
    messageDBInformation: '',
    responseDBInformation: {
        totalCount: 0,
        resultList: []
    },
    statusDBInformation: 0
};

export const reducer: Reducer<DBComparisonStoreState, KnownAction> = (
    state = initialState,
    action
): DBComparisonStoreState => {
    switch (action.type) {
        case 'LOAD_DB_COMPARISON_REQUEST': {
            return {
                ...state,
                isLoadingDBInformation: true
            };
        }
        case 'LOAD_DB_COMPARISON_RECEIVED': {
            return {
                ...state,
                isLoadingDBInformation: false,
                responseDBInformation: action.responseDBInformation,
                statusDBInformation: 200
            };
        }
        case 'LOAD_DB_COMPARISON_FAILURED': {
            return {
                ...state,
                isLoadingDBInformation: false,
                messageDBInformation: action.messageDBInformation,
                statusDBInformation: 400
            };
        }
        case 'CLEAR_DB_COMPARISON': {
            return initialState;
        }
        default:
            return state;
    }
};
