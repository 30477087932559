import React from 'react';
import { observer } from 'mobx-react';
import { SiChakraui } from 'react-icons/si';
import { Line } from 'react-chartjs-2';
import { HiOutlineSearch } from 'react-icons/hi';
import { Progress } from 'reactstrap';

import ReactDatatable from '@ashvin27/react-datatable';

import DashboardServerDetailViewModel from './DashboardServerDetail.viewmodel';
import { configdatatable } from '../../../constants/configDatatable';
import { FappMonitor } from '../../../models/FappMonitor';
import Loader from '../../../commons/components/ContentLoader';

const DashboardServerDetail = () => {
    const {
        fappMonitors,
        selectedMonitorServer,
        handleClick,
        chartDataServerMonitorInday,
        chartOpen,
        onClickReset,
        onClickFilter,
        onSelectDate,
        startDate,
        endDate
    } = DashboardServerDetailViewModel();

    const columns = [
        {
            key: '',
            text: '',
            className: 'Manage',
            cell: (record: FappMonitor, index: number) => {
                return (
                    <div style={{ display: 'flex' }}>
                        <div
                            className="mx-2"
                            style={{ textAlign: 'center', cursor: 'pointer' }}
                        ></div>
                        <div className="mx-1" style={{ textAlign: 'center', cursor: 'pointer' }}>
                            <HiOutlineSearch onClick={() => handleClick(record.id)} />
                        </div>
                    </div>
                );
            }
        },
        {
            key: 'status',
            text: 'Status',
            className: 'Status',
            sortable: true,
            cell: (record: FappMonitor, index: number) => {
                return (
                    <SiChakraui style={{ color: record.status === 'false' ? 'red' : 'green' }} />
                );
            }
        },
        {
            key: 'name',
            text: 'Name',
            className: 'Name',
            sortable: true
        },
        {
            key: 'port',
            text: 'port'
        },
        {
            key: 'cpu',
            text: 'CPU',
            className: 'widthProgressbar',
            sortable: true,
            cell: (record: FappMonitor, index: number) => {
                return Number(record.cpu) > 80 ? (
                    <Progress color="danger" className="Progressbar" animated value={record.cpu}>
                        <span className="fontProgressbar">
                            {parseFloat(record.cpu).toFixed(2)}%
                        </span>
                    </Progress>
                ) : (
                    <Progress className="Progressbar" animated value={record.cpu}>
                        <span className="fontProgressbar">
                            {parseFloat(record.cpu).toFixed(2)}%
                        </span>
                    </Progress>
                );
            }
        },
        {
            key: 'totalRam',
            text: 'RAM',
            className: 'widthProgressbar',
            sortable: true,
            cell: (record: FappMonitor, index: number) => {
                return Number(record.totalRam) > 80 ? (
                    <Progress
                        color="danger"
                        className="Progressbar"
                        animated
                        value={record.totalRam}
                    >
                        <span className="fontProgressbar">
                            {parseFloat(record.totalRam).toFixed(2)}%
                        </span>
                    </Progress>
                ) : (
                    <Progress className="Progressbar" animated value={record.totalRam}>
                        <span className="fontProgressbar">
                            {parseFloat(record.totalRam).toFixed(2)}%
                        </span>
                    </Progress>
                );
            }
        }
    ];

    return (
        <>
            {selectedMonitorServer !== null && (
                <div className="container-fluid">
                    <div className="row">
                        <div className="col shadow">
                            {/* Header */}
                            <div className="d-flex justify-content-md-between pt-2">
                                <div className="d-flex" style={{ gap: 10 }}>
                                    <span>Company : FocusOne |</span>
                                    <span>Name : {selectedMonitorServer.name}</span>
                                    <span>Group : {selectedMonitorServer.group}</span>
                                    <span>Type : {selectedMonitorServer.type}</span>
                                    <span>IP Address : {selectedMonitorServer.domain}</span>
                                </div>
                                <div>
                                    {selectedMonitorServer.status === 'false' ? (
                                        <h5>
                                            {' '}
                                            Status{' '}
                                            <SiChakraui
                                                className="mb-1"
                                                style={{ color: 'red' }}
                                            />{' '}
                                        </h5>
                                    ) : (
                                        <h5>
                                            {' '}
                                            Status :{' '}
                                            <SiChakraui
                                                className="mb-1"
                                                style={{ color: 'green' }}
                                            />
                                        </h5>
                                    )}
                                </div>
                            </div>
                        </div>
                    </div>

                    {/* Server port detail */}
                    <div className="row mt-3">
                        <div className="col shadow p-2">
                            {/* Show port status */}
                            {fappMonitors.isLoaded && fappMonitors.payload.length > 0 && (
                                <ReactDatatable
                                    className="table table-striped table-hover"
                                    tHeadClassName="custom-header-style"
                                    config={configdatatable}
                                    records={fappMonitors.payload}
                                    columns={columns}
                                />
                            )}

                            {/* Show Loading while load the fapp monitors */}
                            {!fappMonitors.isLoaded && fappMonitors.payload.length === 0 && (
                                <Loader loading="true" />
                            )}

                            {/* No fapp monitors */}
                            {fappMonitors.isLoaded && fappMonitors.payload.length === 0 && (
                                <h5>No data...</h5>
                            )}
                        </div>

                        {/* Chart */}
                        <div className="col shadow p-2">
                            <div className="mt-2">
                                <div className="container">
                                    <div
                                        id="filter-by-date"
                                        className="d-flex mb-4 align-items-center"
                                        style={{ gap: 14 }}
                                    >
                                        <span>Start Date : </span>
                                        <input
                                            id="start-date"
                                            type="date"
                                            value={startDate}
                                            onChange={e => onSelectDate(e.target.value, true)}
                                        />
                                        <span>End Date : </span>
                                        <input
                                            id="end-date"
                                            type="date"
                                            value={endDate}
                                            onChange={e => onSelectDate(e.target.value, false)}
                                        />
                                        <button
                                            className="btn btn-success btn-sm border-0"
                                            onClick={onClickFilter}
                                        >
                                            Filter
                                        </button>
                                        <button
                                            className="btn btn-primary btn-sm border-0"
                                            onClick={onClickReset}
                                        >
                                            Reset
                                        </button>
                                    </div>
                                    <div className="container">
                                        {chartOpen && (
                                            <Line
                                                height={240}
                                                data={chartDataServerMonitorInday}
                                                options={{
                                                    scales: {
                                                        x: {
                                                            ticks: {
                                                                font: {
                                                                    size: 10
                                                                }
                                                            }
                                                        }
                                                    }
                                                }}
                                            />
                                        )}
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            )}
        </>
    );
};

export default observer(DashboardServerDetail);
