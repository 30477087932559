import axios from 'axios';

import { ResponseList } from 'models/Common';
import {
    TransportRequestDetailInsert,
    TransportRequestEntity,
    TransportRequestInsert,
    TransportRequestSearch,
    TransportRequestTag,
    TransportRequestUpdateDeployStatus,
    UpdateTransportRequestResult
} from 'data/schemas/TransportRequest';

export async function retrieveTransportRequestUat(params?: TransportRequestSearch) {
    return axios.get<ResponseList<TransportRequestTag[]>>('v1/transport-request/uat/all', {
        params: params
    });
}

export async function callInsertTransportRequestUat(params: TransportRequestInsert) {
    const formData = new FormData();

    for (let index = 0; index < params.TransportRequestList.length; index++) {
        formData.append(
            `TransportRequestList[${index}][Module]`,
            params.TransportRequestList[index].Module
        );
        formData.append(
            `TransportRequestList[${index}][DeploymentObject]`,
            params.TransportRequestList[index].DeploymentObject
        );
        formData.append(
            `TransportRequestList[${index}][Version]`,
            params.TransportRequestList[index].Version ?? ''
        );
        formData.append(
            `TransportRequestList[${index}][Developer]`,
            params.TransportRequestList[index].Developer
        );
        formData.append(
            `TransportRequestList[${index}][Details]`,
            params.TransportRequestList[index].Details
        );
        formData.append(
            `TransportRequestList[${index}][ServerTarget]`,
            params.TransportRequestList[index].ServerTarget ?? ''
        );
        formData.append(
            `TransportRequestList[${index}][ReadyForPrd]`,
            String(params.TransportRequestList[index].ReadyForPrd ?? false)
        );
        formData.append(
            `TransportRequestList[${index}][Remark]`,
            String(params.TransportRequestList[index].Remark ?? '')
        );
        formData.append(
            `TransportRequestList[${index}][Ticket]`,
            String(params.TransportRequestList[index].Ticket)
        );
        formData.append(
            `TransportRequestList[${index}][Feature]`,
            String(params.TransportRequestList[index].Feature ?? '')
        );
        formData.append(
            `TransportRequestList[${index}][AddOrEdit]`,
            String(params.TransportRequestList[index].AddOrEdit ?? '')
        );
        formData.append(
            `TransportRequestList[${index}][CoreOrNonCore]`,
            String(params.TransportRequestList[index].CoreOrNonCore)
        );
        formData.append(
            `TransportRequestList[${index}][Impact]`,
            String(params.TransportRequestList[index].Impact)
        );
        formData.append(
            `TransportRequestList[${index}][RiskLevel]`,
            String(params.TransportRequestList[index].RiskLevel)
        );
    }

    for (const file of params.FilesList) {
        formData.append('FilesList', file);
    }

    return await axios.post('v1/transport-request/uat/create', formData, {
        // params: { params },
        headers: {
            'Content-Type': 'multipart/form-data'
        }
    });
}

export async function callUpdateTransportRequestUat(params: TransportRequestUpdateDeployStatus) {
    return axios.put<UpdateTransportRequestResult>('v1/transport-request/uat/deploy', params);
}

export async function callEditOrUpdateTransportRequest(body: TransportRequestTag) {
    return axios.put<string>('v1/transport-request/update', body, {
        headers: {
            Authorization: 'Bearer ' + localStorage.getItem('MM_APPLICATION')
        }
    });
}

export async function retrieveTransportRequestByUat(params: string) {
    return axios.get<TransportRequestEntity[]>('v1/transport-request/uat/by-tag', {
        params: { uatTag: params }
    });
}