function ClearTransactionViewModel() {
    const companyList: { value: string; name: string }[] = [
        { name: 'Eps Prd', value: 'eps_db_prd' },
        { name: 'Qmix Prd', value: 'qmix_db_prd' },
        { name: 'Scg Cement Prd', value: 'scg_cement_db_prd' },
        { name: 'Scieco Prd', value: 'scieco_db_prd' },
        { name: 'Slsn Prd', value: 'slsn_prd' },
        { name: 'Slts Prd', value: 'slts_prd' },
        { name: 'Sric Prd', value: 'sric_db_prd' }
    ];

    return { companyList };
}

export default ClearTransactionViewModel;
