import React, { useRef, useState } from 'react';
import { Collapse, ListGroupItem } from 'reactstrap';
import {
    ResultCompareColumn,
    ResultCompareTable,
    Table
} from '../../models/DBComparison';
import { HiPlusSm, HiMinusSm } from 'react-icons/hi';
import { ResultTypes } from './DBDetail';

import ColumnItem from './ColumnItem';
import useDisclosure from '../../commons/hooks/useDisclosure';

interface Props {
    table?: Table;
    misMatchTable?: ResultCompareColumn;
    type: ResultTypes;
}

function TableItem({ table, type, misMatchTable }: Props) {
    const { isOpen, onToggle } = useDisclosure();
    const [isShow, setIsShow] = useState<boolean>(false);
    const style =
        type === ResultTypes.NotMatch
            ? 'text-danger'
            : type === ResultTypes.MisMatch
            ? 'text-warning'
            : '';

    return (
        <React.Fragment>
            <ListGroupItem
                className={`table-item py-0 overflow-hidden ${style}`}
                onClick={onToggle}
            >
                {!isShow ? (
                    <HiPlusSm className="mr-2" />
                ) : (
                    <HiMinusSm className="mr-2" />
                )}
                <span>
                    {table !== undefined
                        ? table.tableName
                        : misMatchTable !== undefined
                        ? misMatchTable.tableName
                        : ''}
                </span>
            </ListGroupItem>
            <Collapse
                isOpen={isOpen}
                onEnter={() => setIsShow(true)}
                onExited={() => setIsShow(false)}
            >
                {/* When it's not mismatch */}
                {isShow &&
                    table !== undefined &&
                    table.columns.map((column, index) => (
                        <ColumnItem
                            key={`not-mismatch-${index}`}
                            column={column}
                            type={type}
                        />
                    ))}

                {/* When it's mismatch */}
                {/* Column not matches */}
                {isShow &&
                    misMatchTable !== undefined &&
                    misMatchTable.columnNotMatches.map((column, index) => (
                        <ColumnItem
                            key={`col-not-match-${index}`}
                            column={column}
                            type={ResultTypes.NotMatch}
                        />
                    ))}

                {/* Column mismatches */}
                {isShow &&
                    misMatchTable !== undefined &&
                    misMatchTable.columnMisMatches.map((column, index) => (
                        <ColumnItem
                            key={`col-mismatch-${index}`}
                            column={column}
                            type={ResultTypes.MisMatch}
                        />
                    ))}

                {/* Column matches */}
                {isShow &&
                    misMatchTable !== undefined &&
                    misMatchTable.columnMatches.map((column, index) => (
                        <ColumnItem
                            key={`col-match-${index}`}
                            column={column}
                            type={ResultTypes.Match}
                        />
                    ))}
            </Collapse>
        </React.Fragment>
    );
}

export default TableItem;
