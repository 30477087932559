import 'assets/style/style.css';

import React from 'react';
import { observer } from 'mobx-react';
import { Nav, NavItem, NavLink, TabContent, TabPane } from 'reactstrap';

import ServerMonitorTableComponent from './components/ServerMonitorTableComponent';
import ServerMonitorViewModel from './ServerMonitor.viewmodel';

const ServerMonitor = () => {
    const {
        activeTab,
        onSelectTab,
        handleClick,
        monitorServers,
        onchangeSwitch,
        autoRefresh,
        toggleRefresh,
        authenticationUser
    } = ServerMonitorViewModel();

    return (
        <React.Fragment>
            <Nav tabs>
                <NavItem>
                    <NavLink
                        style={{ cursor: 'pointer' }}
                        className={activeTab == '1' ? 'active' : ''}
                        onClick={() => onSelectTab('1')}
                    >
                        Server Monitoring
                    </NavLink>
                </NavItem>
            </Nav>
            <TabContent
                activeTab={activeTab}
                className="flex flex-1 flex-col py-2"
            >
                {activeTab === '1' && (
                    <TabPane
                        tabId="1"
                        className="flex-1"
                    >
                        <ServerMonitorTableComponent
                            handleClickEvent={handleClick}
                            monitorServers={monitorServers}
                            onToggleSwitch={onchangeSwitch}
                            isAutoRefresh={autoRefresh}
                            toggleRefresh={toggleRefresh}
                        />
                    </TabPane>
                )}
            </TabContent>
        </React.Fragment>
    );
};

export default observer(ServerMonitor);
