import AutoSizer from 'react-virtualized-auto-sizer';
import React, { useRef } from 'react';
import MonacoEditor from '@monaco-editor/react';
import { observer } from 'mobx-react';
import { Button, useBoolean } from '@chakra-ui/react';
import { Tree } from 'react-arborist';
import { twMerge } from 'tailwind-merge';
import { FaFolder } from 'react-icons/fa6';
import { TbFileTypeSql } from 'react-icons/tb';
import ScriptDBErrorModal from './ScriptDBErrorModal';

interface RunScriptDBProps {
}

const STATEMENT_STATUS_CLASSES = {
    success: 'bg-green-600 text-white',
    error: 'bg-red-600 text-white',
    exec: 'bg-yellow-400 text-white'
};

function RunScriptDB(props: RunScriptDBProps) {
    const [showSkippableModal, setShowSkippableModal] = useBoolean();
    const skipResolverRef = useRef<(value: 'skip' | 'skip_all' | 'cancel') => void>();



    return (
        <React.Fragment>
            <div className="border rounded px-3 py-2">
                {/*<ConnectionForm onClickConnect={handleClickConnect} />*/}
                <div className="flex border rounded-sm p-2 justify-between items-center">
                    <span>Conection</span>
                    <Button onClick={() => setShowSkippableModal.on()}>
                        Test Error
                    </Button>
                </div>

                <div className="mt-[16px] flex gap-2">
                    <Button
                        size="sm"
                        onClick={() => console.log('run')}
                    >
                        Run
                    </Button>
                    <Button
                        size="sm"
                        onClick={() => console.log('run all')}
                    >
                        Run All
                    </Button>
                </div>

                <div className="flex flex-1 px-2 pt-2">
                    <div className="w-[253px] border">
                        <AutoSizer>
                            {({ height }) => (
                                <Tree
                                    //ref={fileTreeRef}
                                    //data={folders}
                                    idAccessor="id"
                                    //childrenAccessor={d => d.files as any}
                                    openByDefault={true}
                                    width={250}
                                    height={height}
                                    padding={4}
                                    //onSelect={handleSelectFiles}
                                    disableDrag={false}
                                >
                                    {({ node, style, dragHandle }) => (
                                        <div
                                            ref={dragHandle}
                                            style={{
                                                ...style,
                                                paddingLeft:
                                                    ((style.paddingLeft ?? 0) as number) + 8,
                                                paddingRight:
                                                    ((style.paddingRight ?? 0) as number) + 8
                                            }}
                                            className={twMerge(
                                                'cursor-pointer hover:bg-neutral-200',
                                                node.isSelected &&
                                                'bg-blue-300 hover:bg-blue-400 focus-visible:border-none'
                                            )}
                                        >
                                            <div className="flex items-center gap-2">
                                                {!node.isLeaf ? <FaFolder /> : <TbFileTypeSql />}
                                                {/*<span className="flex-1">{node.data.id}</span>*/}
                                                <span>id</span>
                                            </div>
                                        </div>
                                    )}
                                </Tree>
                            )}
                        </AutoSizer>
                    </div>

                    <div className="flex flex-1 flex-col">
                        {/*Editor*/}
                        <div className="flex-1 border min-h-[45vh]">
                            <MonacoEditor
                                language="sql"
                                theme="vs-light"
                                //value={selectedFileContent}
                                options={{ selectOnLineNumbers: true }}
                            />
                        </div>

                        {/*Output Result*/}
                        <div
                            //ref={virtualTerminalRef}
                            className="h-[150px] overflow-y-auto border p-2"
                        >
                            <Button
                                size="sm"
                                type="button"
                                //onClick={handleClickExportRunningLog}
                                className="absolute right-10 h-8"
                            >
                                Export
                            </Button>
                            <div id="output-log-container">
                                {[{ detail: { status: '', message: '', elapsed_time: 0 }, folder: '', id: '' }]
                                    .map(log => (
                                        <p>
                                            <span
                                                className={twMerge(
                                                    STATEMENT_STATUS_CLASSES[log.detail.status],
                                                    'rounded-md px-2'
                                                )}
                                            >
                                                [{log.detail.status}]
                                            </span>
                                            <span>
                                                [{log.folder}/{log.id}]
                                            </span>
                                            : <span>{log.detail.message}</span>
                                            {log.detail.status && (
                                                <span
                                                    className='px-2'
                                                >
                                                    [Elapsed Time - {log.detail.elapsed_time}]
                                                </span>
                                            )}
                                        </p>
                                ))}
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <ScriptDBErrorModal
                noCloseButton={true}
                isOpen={showSkippableModal}
                onClose={setShowSkippableModal.off}
                error={{ fileName: 'test', indexOfTransaction: 0, message: 'no error' }}
                skipResolverRef={skipResolverRef}
            />
        </React.Fragment>
    )
}

export default observer(RunScriptDB);