import './DBComparison.css';

import React, { useMemo } from 'react';
import { connect } from 'react-redux';
import { Container, Row, Col } from 'reactstrap';
import { ApplicationState } from '../../store';
import { DBComparisonStoreState, ResultCompareTable } from '../../models/DBComparison';
import { actionCreators } from '../../store/DBComparison';

import DBSearchCard from './DBSearchCard';
import DBDetail from './DBDetail';
import { ResponseList } from '../../models/Common';

interface Props extends DBComparisonStoreState { }

const DBComparison = (props: Props) => {
    const { responseDBInformation } = props;

    const memoResponseDbInformation = useMemo<ResponseList<ResultCompareTable[]>>(() => {
        if (responseDBInformation === undefined) {
            return {
                totalCount: 0,
                resultList: []
            }
        }

        return responseDBInformation;
    }, [responseDBInformation]);

    return (
        <Container fluid>
            <DBSearchCard />
            <Row className="mt-4">
                {
                    memoResponseDbInformation.resultList.length >= 2
                    && (
                        <React.Fragment>
                            <Col sm={6}>
                                <DBDetail
                                    databaseName={memoResponseDbInformation.resultList[0].databaseName}
                                    resultCompareTable={memoResponseDbInformation.resultList[0]}
                                />
                            </Col>
                            <Col sm={6}>
                                <DBDetail
                                    databaseName={memoResponseDbInformation.resultList[1].databaseName}
                                    resultCompareTable={memoResponseDbInformation.resultList[1]}
                                />
                            </Col>
                        </React.Fragment>
                    )
                }
            </Row>
        </Container>
    )
}

export default connect(
    (state: ApplicationState) => state.dbComparison,
    actionCreators
)(DBComparison);