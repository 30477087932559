import { AxiosError } from 'axios';

import { validateDataResponse } from '../../../commons/helpers';
import { PortConfigResponse, PortConfigUpdateRequest } from 'data/schemas/portConfig';
import { retrievePortConfigList, updateConfigPort } from 'data/datasources/v1/portConfig';

export async function getPortConfigList(): Promise<PortConfigResponse[]> {
    try {
        const response = await retrievePortConfigList();
        const result: PortConfigResponse[] = validateDataResponse(response);

        return result;
    } catch (error) {
        if (error instanceof AxiosError && error.response) {
            throw new Error(validateDataResponse(error.response).message);
        }

        throw error;
    }
}

export async function updateStatusPortConfig(body: PortConfigUpdateRequest): Promise<string> {
    try {
        const response = await updateConfigPort(body);
        const result: string = validateDataResponse(response);

        return result;
    } catch (error) {
        if (error instanceof AxiosError && error.response) {
            throw new Error(validateDataResponse(error.response).message);
        }

        throw error;
    }
}
