import React, { useState } from 'react';
import { connect } from 'react-redux';
import {
    Button,
    Card,
    CardHeader,
    Col,
    Form,
    FormGroup,
    Input,
    Label,
    Row,
    Collapse,
    CardBody,
    Spinner
} from 'reactstrap';
import { ConnectionForm } from '../../models/DBComparison';
import { ApplicationState } from '../../store';
import { actionCreators } from '../../store/DBComparison';
import { BsChevronUp } from 'react-icons/bs';
import useDisclosure from '../../commons/hooks/useDisclosure';

enum InputIds {
    INPUT_CONNECTION = 'input-connection',
    SELECT_DATABASE = 'select-database'
}

function DBSearchCard(props: any) {
    const { isLoadingDBInformation, statusDBInformation } = props;
    const [compareCount] = useState<number>(2);
    const [connectionForms, setConnectionForms] = useState<ConnectionForm[]>(
        Array.from({ length: compareCount }).map<ConnectionForm>(
            (_, index) => ({
                connectionString: index === 0 ? '' : '',
                databaseType: 'SQL SERVER'
            })
        )
    );
    const [errorInputIds, setErrorInputIds] = useState<string[]>([]);
    const { isOpen, onToggle } = useDisclosure(true);

    const handleChangeInput = (
        index: number,
        key: keyof ConnectionForm
    ): React.ChangeEventHandler<HTMLInputElement> => {
        return event => {
            event.persist();
            const value = event.target.value;
            const newConnectionForms = [...connectionForms];

            newConnectionForms[index][key] = value;

            setConnectionForms(newConnectionForms);
        };
    };

    const validationForm = (): string[] => {
        const errorInputIds: string[] = [];

        for (const [index, connectionForm] of connectionForms.entries()) {
            for (const property in connectionForm) {
                const key: keyof ConnectionForm =
                    property as keyof ConnectionForm;

                if (connectionForm[key].length === 0) {
                    switch (key) {
                        case 'connectionString':
                            errorInputIds.push(
                                `${InputIds.INPUT_CONNECTION}-${index + 1}`
                            );
                            break;
                        case 'databaseType':
                            errorInputIds.push(
                                `${InputIds.SELECT_DATABASE}-${index + 1}`
                            );
                            break;
                    }
                }
            }
        }

        return errorInputIds;
    };

    const handleSubmitForm: React.FormEventHandler<HTMLFormElement> = event => {
        event.preventDefault();

        const errorInputIds = validationForm();

        if (errorInputIds.length > 0) {
            setErrorInputIds(errorInputIds);
            return;
        }

        setErrorInputIds([]);

        props.requestDbInformation({ method: 'POST', body: connectionForms });
    };

    return (
        <Card className="mt-3">
            <CardHeader>
                <Row>
                    <Col>Database Connection</Col>
                    <Col className="text-right">
                        <BsChevronUp
                            style={{
                                cursor: 'pointer',
                                transform: isOpen
                                    ? 'rotate(0deg)'
                                    : 'rotate(-180deg)',
                                transition: 'all 0.2s ease'
                            }}
                            onClick={() => onToggle()}
                        />
                    </Col>
                </Row>
            </CardHeader>
            <Collapse isOpen={isOpen}>
                <CardBody>
                    <Form className="px-4 py-2" onSubmit={handleSubmitForm}>
                        <Row>
                            {connectionForms.map((form, index) => (
                                <Col key={`form-${index}`} md={6}>
                                    <FormGroup>
                                        <Label
                                            for={`${
                                                InputIds.INPUT_CONNECTION
                                            }-${index + 1}`}
                                        >
                                            Connection {index + 1}
                                        </Label>
                                        <Row>
                                            <Col lg={8}>
                                                <Input
                                                    id={`${
                                                        InputIds.INPUT_CONNECTION
                                                    }-${index + 1}`}
                                                    placeholder={`Connection String ${
                                                        index + 1
                                                    }`}
                                                    value={
                                                        form.connectionString
                                                    }
                                                    onChange={handleChangeInput(
                                                        index,
                                                        'connectionString'
                                                    )}
                                                    invalid={errorInputIds.includes(
                                                        `${
                                                            InputIds.INPUT_CONNECTION
                                                        }-${index + 1}`
                                                    )}
                                                />
                                            </Col>
                                            <Col lg={4}>
                                                <Input
                                                    id={`${
                                                        InputIds.SELECT_DATABASE
                                                    }-${index + 1}`}
                                                    type="select"
                                                    value={form.databaseType}
                                                    onChange={handleChangeInput(
                                                        index,
                                                        'databaseType'
                                                    )}
                                                    invalid={errorInputIds.includes(
                                                        `${
                                                            InputIds.SELECT_DATABASE
                                                        }-${index + 1}`
                                                    )}
                                                >
                                                    <option value="" disabled>
                                                        Type
                                                    </option>
                                                    <option value="SQL SERVER">
                                                        SQL Server
                                                    </option>
                                                    <option value="PostgreSQL">
                                                        PostgreSQL
                                                    </option>
                                                </Input>
                                            </Col>
                                        </Row>
                                    </FormGroup>
                                </Col>
                            ))}
                        </Row>
                        <Row>
                            <Col className="text-center">
                                {!isLoadingDBInformation ? (
                                    <Button type="submit" color="primary">
                                        Compare
                                    </Button>
                                ) : (
                                    <Spinner color="primary" />
                                )}
                            </Col>
                        </Row>
                    </Form>
                </CardBody>
            </Collapse>
        </Card>
    );
}

export default connect(
    (state: ApplicationState) => state.dbComparison,
    actionCreators
)(DBSearchCard);
