import React, { useState, forwardRef, useImperativeHandle } from 'react';
import { twMerge } from 'tailwind-merge';
import { Badge, FormControl, Input, Select } from '@chakra-ui/react';
import { TransportRequestDetail, TransportRequestTag } from 'data/schemas/TransportRequest';

type Props = {
    fileUpload: TransportRequestDetail;
    handleTransportDetailChange: (
        index: number,
        key: keyof TransportRequestDetail,
        value: TransportRequestDetail[keyof TransportRequestDetail]
    ) => void;
    index: number;
}

function CardTransportRequestEdit(props: Props) {
    const { fileUpload, handleTransportDetailChange, index } = props;

    const cardBorderColor =
        fileUpload.module === 'FAPP'
            ? 'border-l-8 border-l-green-500'
            : fileUpload.module === 'FGUI'
                ? 'border-l-8 border-l-teal-400'
                : 'border-l-8 border-l-violet-600';

    return (
        <div
            className={twMerge(
                'mb-2 flex flex-col gap-2 rounded-md border-b border-r border-t border-neutral-400',
                cardBorderColor
            )}
        >
            <div className="p-2.5">
                <div
                    id="row-0"
                    className="flex flex-col gap-1"
                >
                    <div className='flex flex-row justify-between'>
                        <Badge
                            variant="outline"
                            colorScheme={
                                fileUpload.module === 'FAPP'
                                    ? 'green'
                                    : fileUpload.module === 'FGUI'
                                        ? 'teal'
                                        : 'purple'
                            }
                            className="w-fit"
                        >
                            {fileUpload.module}
                        </Badge>
                    </div>
                    <span className="mt-2 text-sm font-bold">
                        File Name : {fileUpload.deploymentObject}
                    </span>
                </div>
                <div className="mt-2">
                    <div
                        id="row-1"
                        className="grid grid-cols-2 gap-2"
                    >
                        <div className="flex flex-col gap-2">
                            <div className="flex flex-col">
                                <span className="text-sm font-medium">
                                    Is Core
                                </span>
                                <FormControl>
                                    <Select
                                        name="core_or_noncore"
                                        size="sm"
                                        className="rounded-sm border-neutral-400 text-black"
                                        value={fileUpload.coreOrNonCore}
                                        onChange={(e) => handleTransportDetailChange(index, 'coreOrNonCore', e.target.value)}
                                    >
                                        <option value="CORE">Core</option>
                                        <option value="NON CORE">Non-Core</option>
                                    </Select>
                                </FormControl>
                            </div>
                        </div>
                        <div className="flex flex-col">
                            <span className="text-sm font-medium">
                                Add / Edit
                            </span>
                            <FormControl>
                                <Select
                                    name="add_or_edit"
                                    size="sm"
                                    className="rounded-sm border-neutral-400 text-black"
                                    value={fileUpload.addOrEdit}
                                    onChange={(e) => handleTransportDetailChange(index, 'addOrEdit', e.target.value)}
                                >
                                    <option value="ADD">ADD</option>
                                    <option value="EDIT">EDIT</option>
                                </Select>
                            </FormControl>
                        </div>
                        <div className="flex flex-col">
                            <span className="text-sm font-medium">
                                Impact
                            </span>
                            <Input
                                name="impact"
                                size="sm"
                                className="rounded-sm border-neutral-400 text-black"
                                value={fileUpload.impact}
                                onChange={(e) => handleTransportDetailChange(index, 'impact', e.target.value)}
                            />
                        </div>
                        <div className="flex flex-col">
                            <span className="text-sm font-medium">
                                Ticket
                            </span>
                            <Input
                                name="ticket"
                                size="sm"
                                className="rounded-sm border-neutral-400 text-black"
                                onChange={(e) => handleTransportDetailChange(index, 'ticket', e.target.value)}
                                value={fileUpload.ticket}
                            />
                        </div>
                        <div className="flex flex-col">
                            <span className="text-sm font-medium">
                                Feature
                            </span>
                            <Input
                                name="feature"
                                size="sm"
                                className="rounded-sm border-neutral-400 text-black"
                                onChange={(e) => handleTransportDetailChange(index, 'feature', e.target.value)}
                                value={fileUpload.feature ?? ''}
                            />
                        </div>
                        <div className="flex flex-col">
                            <span className="text-sm font-medium">
                                Risk Level
                            </span>
                            <Select
                                name="risk_level"
                                className="rounded-sm border-neutral-400 text-black"
                                size="sm"
                                placeholder="Please choose one"
                                value={fileUpload.riskLevel}
                                onChange={(e) => handleTransportDetailChange(index, 'riskLevel', e.target.value)}
                            >
                                <option value="LOW">Low</option>
                                <option value="MEDIUM">Medium</option>
                                <option value="HIGH">High</option>
                            </Select>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default CardTransportRequestEdit;
