import AdminDashboardTableComponent from './AdminDashboardTableComponent';
import { useLoader, useStore } from '../../../../commons/hooks';
import { useEffect } from 'react';
import { webMonitorColumns } from '../data/webMonitorColumns';

function WebMonitorDashboard() {
    const loader = useLoader();
    const { webMonitorStore } = useStore();
    const { webMonitors } = webMonitorStore;

    useEffect(() => {
        if (webMonitors && webMonitors.length > 0) {
            return;
        }

        fetchWebMonitor();
    }, [webMonitors]);

    const fetchWebMonitor = async () => {
        try {
            loader.show();

            await webMonitorStore.fetchWebMonitor();
        } catch {
        } finally {
            loader.hide();
        }
    };

    return (
        <AdminDashboardTableComponent
            columns={webMonitorColumns}
            data={webMonitors ?? []}
            onCheckOnlineStatus={record => record.status}
            handleClickEvent={() => void 0}
            onToggleSwitch={() => void 0}
            isAutoRefresh={false}
            toggleRefresh={fetchWebMonitor}
        />
    );
}

export default WebMonitorDashboard;
