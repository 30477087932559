import axios from 'axios';

import { monitorFappResponse } from '../../../schemas/monitor';
import { FappConfig } from '../../../../models/FappMonitor';

export async function getByDomain(params: { domain: string }) {
    return axios.get<monitorFappResponse[]>('v1/monitor/fappspg', {
        params,
        headers: {
            Authorization: 'Bearer ' + localStorage.getItem('MM_APPLICATION')
        }
    });
}

export async function getAllFappPorts(params: { isPRD: boolean }) {
    return axios.get<FappConfig[]>('v1/config/fapps/ports', {
        params,
        headers: {
            Authorization: 'Bearer ' + localStorage.getItem('MM_APPLICATION')
        }
    });
}
