import { makeAutoObservable, runInAction } from 'mobx';

import RootStore from '../Root.store';
import { AuthenticationResponse } from 'data/schemas/auth';
import { getMasterEmployee } from 'data/repositories/auth/authentication.repository';

class AuthenticationStore {
    private _masterEmployeeResponse: AuthenticationResponse | null = null;

    public get masterEmployeeResponse() {
        return this._masterEmployeeResponse;
    }

    constructor(private rootStore: RootStore) {
        makeAutoObservable(this);
    }

    public async fetchMasterEmployee(citizenId: string) {
        try {
            const masterEmployeeResponse = await getMasterEmployee({ citizenId });
            runInAction(() => {
                this._masterEmployeeResponse = masterEmployeeResponse;
            });
        } catch (error) {
            throw error;
        }
    }
}

export default AuthenticationStore;
