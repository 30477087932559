import { MonitorServerResponse } from '../../schemas/monitor';
import { validateDataResponse } from '../../../commons/helpers';
import { AdminServerState, DataAdminServer } from '../../../models/AdminServer';
import { getAll, getById } from '../../datasources/v1/monitor';
import { getByIdV2 } from '../../datasources/v2/monitor';
import { AxiosError } from 'axios';

export async function getMonitorServerConfigs(): Promise<AdminServerState> {
    try {
        const response = await getAll();
        const result: MonitorServerResponse[] = validateDataResponse(response);

        const res: AdminServerState = {
            isLoadAdminServer: true,
            ResponseAdminServer: result ?? []
        };

        return res;
    } catch (error) {
        if (error instanceof AxiosError && error.response) {
            throw new Error(validateDataResponse(error.response).message);
        }

        throw error;
    }
}

export async function getMonitorServerDetailsByServerId(
    serverId: string
): Promise<AdminServerState> {
    try {
        const response = await getById(serverId);
        const result: MonitorServerResponse[] = validateDataResponse(response);

        const res: AdminServerState = {
            isLoadAdminServer: true,
            ResponseAdminServer: result ?? []
        };

        return res;
    } catch (error) {
        if (error instanceof AxiosError && error.response) {
            throw new Error(validateDataResponse(error.response).message);
        }

        throw error;
    }
}

export async function getMonitorServerByServerIdV2(
    server_id: string,
    params: { tool_update: string }
): Promise<DataAdminServer> {
    try {
        const response = await getByIdV2(server_id, params);
        const result: DataAdminServer = validateDataResponse(response);

        return result;
    } catch (error) {
        if (error instanceof AxiosError && error.response) {
            throw new Error(validateDataResponse(error.response).message);
        }

        throw error;
    }
}
