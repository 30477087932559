import * as React from 'react';
import { BsFillGearFill } from "react-icons/bs";
import { SiChakraui } from "react-icons/si";
import { HiOutlineSearch } from "react-icons/hi";
import ReactDatatable from '@ashvin27/react-datatable';
import { Route, useHistory } from "react-router-dom";
import { Modal, ModalHeader, ModalBody, ModalFooter, Button, Input, Progress, Row, Col, Container, Card, CardBody, CardTitle, Table, Spinner, CardHeader } from "reactstrap";
import BootstrapSwitchButton from 'bootstrap-switch-button-react';

import { AiOutlineReload } from "react-icons/ai";

import Swal from "sweetalert2";
import "../assets/style/style.css"

import Loader from './loader/ContentLoader';
import { configdatatable } from './configdatatable/Config';

import { connect } from 'react-redux';
import { ApplicationState } from '../store';

import * as DBWorkerModels from '../models/DBWorker'
import * as DBWorkerStore from '../store/DBWorker'

type store = DBWorkerModels.GetDBWorkerState & typeof DBWorkerStore.actionCreators

class DBWorker extends React.Component<any, { checkInitialReq: boolean, checkOpenViewModal: boolean, headerViewModal: string, blindingViewTable: boolean, dataViewTable: Array<DBWorkerModels.DBWorker> }, store>{
    constructor(props: any) {
        super(props);
        this.state = {
            checkInitialReq: false,
            checkOpenViewModal: false,
            headerViewModal: "",
            blindingViewTable: false,
            dataViewTable: []
        }
    }

    componentDidMount() {
        if (!this.state.checkInitialReq) {
            //this.props.requestGetDBWorkerInprocess(true, "GET")
            //this.props.requestGetDBWorkerError(true, "GET")

            this.props.requestGetCountDBWorker(true, "GET")
            this.props.requestGetCountDBWorkerErr(true, "GET")

            this.setState({
                checkInitialReq: true
            })
        }
    }
    componentDidUpdate(nextProps: any, nextState: any) {
        const { DBWorker, DBWorkerErr } = this.props
        if (!this.state.blindingViewTable && DBWorker.isLoadDBWorker && DBWorker.statusDBWorker == 200) {
            console.log("set In-process")
            this.setState({
                blindingViewTable: true,
                checkOpenViewModal: true,
                dataViewTable: DBWorker.responseDBWorker["result_list"] != null ? DBWorker.responseDBWorker["result_list"] : [],
            })
            this.props.requestGetDBWorkerInprocess(true, "CLEAR", "")
        }

        if (!this.state.blindingViewTable && DBWorkerErr.isLoadDBWorkerErr && DBWorkerErr.statusDBWorkerErr == 200) {
            console.log("set Error")
            this.setState({
                blindingViewTable: true,
                checkOpenViewModal: true,
                dataViewTable: DBWorkerErr.responseDBWorkerErr["result_list"] != null ? DBWorkerErr.responseDBWorkerErr["result_list"] : []
            })
            this.props.requestGetDBWorkerError(true, "CLEAR", "")
        }
    }

    handleViewDBWorker = (key: string, mode: string) => {
        if (mode == "in-process") {
            this.props.requestGetDBWorkerInprocess(true, "GET", key)
            this.setState({
                headerViewModal: key + " in-process detail"
            })
        } else {
            this.props.requestGetDBWorkerError(true, "GET", key)
            this.setState({
                headerViewModal: key + " error detail"
            })
        }

        console.log(this.state.dataViewTable)
    }

    handleCloseDBWorkerModal() {
        if (this.state.checkOpenViewModal) {

            this.setState({
                checkOpenViewModal: false,
                headerViewModal: "",
                blindingViewTable: false,
                //dataViewTable : []
            })

            this.props.requestGetDBWorkerError(true, "CLEAR", "")
            this.props.requestGetDBWorkerInprocess(true, "CLEAR", "")

        } else {
            this.setState({
                checkOpenViewModal: true
            })
        }
    }

    render() {
        const { DBWorker, DBWorkerErr, CountDBWorker, CountDBWorkerErr } = this.props
        return (
            <Container fluid style={{ height: '80%' }}>
                <Row className="mt-1" style={{ height: '40%' }}>
                    <Col>
                        <Card>
                            <CardHeader tag="h5">
                                In-Process
                            </CardHeader>
                            <CardBody>
                                <Row>
                                    <Col>
                                        <div style={{ display: 'block', overflowY: 'scroll', height: '100%' }}>
                                            <Table bordered hover responsive size="sm">
                                                <thead>
                                                    <tr className="text-center">

                                                        <th style={{ width: '70%' }}>DB Name</th>
                                                        <th style={{ width: '15%' }}>Count</th>
                                                        <th style={{ width: '15%' }}>Detail</th>
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    {!CountDBWorker.isLoadCountDBWorker ? <tr><td colSpan={21} style={{ background: "rgba(209, 250, 221, 1)", color: "black", fontSize: '10pt', textAlign: "center" }}><Spinner animation="border" size="sm" />&nbsp;&nbsp;Loading...</td></tr> : <tr></tr>}
                                                    {CountDBWorker.isLoadCountDBWorker && CountDBWorker.statusCountDBWorker != 200 ? <tr><td colSpan={21} style={{ background: "#b7b7a4", color: "black", fontSize: '10pt', textAlign: "center" }}>&nbsp;&nbsp;No Data...</td></tr> : <tr></tr>}
                                                    {/*{CountDBWorker.isLoadCountDBWorker && CountDBWorker.responseCountDBWorker['result_list'] == null ? <tr><td colSpan={21} style={{ background: "#b7b7a4", color: "black", fontSize: '10pt', textAlign: "center" }}>&nbsp;&nbsp;No Data...</td></tr> : <tr></tr>}*/}
                                                    {CountDBWorker.statusCountDBWorker == 200 && CountDBWorker.isLoadCountDBWorker && CountDBWorker.responseCountDBWorker['result_list'] != null && CountDBWorker.responseCountDBWorker['result_list'].map((item: DBWorkerModels.CountDBWorker, idx: any) => {
                                                        return (
                                                            <tr key={idx}>

                                                                <td style={{ textAlign: 'left' }}>{item.dbName}</td>
                                                                <td className="text-center">{item.count}</td>
                                                                <td className="text-center"><Button color="info" size="sm" onClick={() => this.handleViewDBWorker(item.key, "in-process")}>View</Button></td>
                                                            </tr>
                                                        )
                                                    })}
                                                </tbody>
                                            </Table>
                                        </div>
                                    </Col>
                                </Row>
                            </CardBody>
                        </Card>
                    </Col>
                </Row>
                <Row className='mt-3' style={{ height: '40%' }}>
                    <Col>
                        <Card>
                            <CardHeader tag="h5">
                                Error
                            </CardHeader>
                            <CardBody>
                                <Row>
                                    <Col>
                                        <div style={{ display: 'block', overflowY: 'scroll', height: '100%' }}>
                                            <Table bordered hover responsive size="sm">
                                                <thead>
                                                    <tr className="text-center">

                                                        <th style={{ width: '70%' }}>DB Name</th>
                                                        <th style={{ width: '15%' }}>Count</th>
                                                        <th style={{ width: '15%' }}>Detail</th>
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    {!CountDBWorkerErr.isLoadCountDBWorkerErr ? <tr><td colSpan={21} style={{ background: "rgba(209, 250, 221, 1)", color: "black", fontSize: '10pt', textAlign: "center" }}><Spinner animation="border" size="sm" />&nbsp;&nbsp;Loading...</td></tr> : <tr></tr>}
                                                    {CountDBWorkerErr.isLoadCountDBWorkerErr && CountDBWorkerErr.statusCountDBWorkerErr != 200 ? <tr><td colSpan={21} style={{ background: "#b7b7a4", color: "black", fontSize: '10pt', textAlign: "center" }}>&nbsp;&nbsp;No Data...</td></tr> : <tr></tr>}
                                                    {/*                                                    {CountDBWorkerErr.isLoadCountDBWorkerErr && CountDBWorkerErr.responseCountDBWorkerErr['result_list'] == null ? <tr><td colSpan={21} style={{ background: "#b7b7a4", color: "black", fontSize: '10pt', textAlign: "center" }}>&nbsp;&nbsp;No Data...</td></tr> : <tr></tr>}*/}
                                                    {CountDBWorkerErr.statusCountDBWorkerErr == 200 && CountDBWorkerErr.isLoadCountDBWorkerErr && CountDBWorkerErr.responseCountDBWorkerErr['result_list'] != null && CountDBWorkerErr.responseCountDBWorkerErr['result_list'].map((item: DBWorkerModels.CountDBWorker, idx: any) => {
                                                        return (
                                                            <tr key={idx}>

                                                                <td style={{ textAlign: 'left' }}>{item.dbName}</td>
                                                                <td className="text-center">{item.count}</td>
                                                                <td className="text-center"><Button color="info" size="sm" onClick={() => this.handleViewDBWorker(item.key, "error")}>View</Button></td>
                                                            </tr>
                                                        )
                                                    })}
                                                </tbody>
                                            </Table>
                                        </div>
                                    </Col>
                                </Row>
                            </CardBody>
                        </Card>
                    </Col>
                </Row>

                <Modal isOpen={this.state.checkOpenViewModal} scrollable={true} size="xl" keyboard={false}>
                    <ModalHeader>
                        {this.state.headerViewModal}
                    </ModalHeader>
                    <ModalBody>
                        <Row>
                            <Col>
                                <Table bordered hover responsive size="sm">
                                    <thead>
                                        <tr>
                                            <th>dbName</th>
                                            <th>bufferID</th>
                                            <th>processIndex</th>
                                            <th>sequenceID</th>
                                            <th>tmode</th>
                                            <th>beginT</th>
                                            <th>compleateT</th>
                                            <th>deleteT</th>
                                            <th>errorS</th>
                                            <th>workerName</th>
                                            <th>startProcess</th>
                                            <th>endProcess</th>
                                            <th>createD_BY</th>
                                            <th>createD_ON</th>
                                            <th>updateD_BY</th>
                                            <th>updateD_ON</th>
                                            <th>runCount</th>
                                            <th>msgLog</th>
                                            <th>fromTerminal</th>
                                            <th>fromChannel</th>
                                            <th>xDetail</th>
                                        </tr>
                                    </thead>
                                    <tbody style={{}}>
                                        {/*{!CountDBWorker.isLoadCountDBWorker ? <tr><td colSpan={21} style={{ background: "rgba(209, 250, 221, 1)", color: "black", fontSize: '10pt', textAlign: "center" }}><Spinner animation="border" size="sm" />&nbsp;&nbsp;Loading...</td></tr> : <tr></tr>}*/}
                                        {this.state.blindingViewTable && this.state.dataViewTable.length == 0 ? <tr><td colSpan={21} style={{ background: "#b7b7a4", color: "black", fontSize: '10pt', textAlign: "center" }}>&nbsp;&nbsp;No Data...</td></tr> : <tr></tr>}
                                        {/*{CountDBWorker.isLoadCountDBWorker && CountDBWorker.responseCountDBWorker['result_list'] == null ? <tr><td colSpan={21} style={{ background: "#b7b7a4", color: "black", fontSize: '10pt', textAlign: "center" }}>&nbsp;&nbsp;No Data...</td></tr> : <tr></tr>}*/}
                                        {this.state.blindingViewTable && this.state.dataViewTable.length != 0 && this.state.dataViewTable.map((item: DBWorkerModels.DBWorker, idx: any) => {
                                            return (
                                                <tr key={idx}>
                                                    <td className="text-center">{item.dbName}</td>
                                                    <td className="text-center">{item.bufferID}</td>
                                                    <td className="text-center">{item.processIndex}</td>
                                                    <td className="text-center">{item.sequenceID}</td>
                                                    <td className="text-center">{item.tmode}</td>
                                                    <td className="text-center">{item.beginT}</td>
                                                    <td className="text-center">{item.compleateT}</td>
                                                    <td className="text-center">{item.deleteT}</td>
                                                    <td className="text-center">{item.errorS}</td>
                                                    <td className="text-center">{item.workerName}</td>
                                                    <td className="text-center">{item.startProcess}</td>
                                                    <td className="text-center">{item.endProcess}</td>
                                                    <td className="text-center">{item.createD_BY}</td>
                                                    <td className="text-center">{item.createD_ON}</td>
                                                    <td className="text-center">{item.updateD_BY}</td>
                                                    <td className="text-center">{item.updateD_ON}</td>
                                                    <td className="text-center">{item.runCount}</td>
                                                    <td className="text-center">{item.msgLog}</td>
                                                    <td className="text-center">{item.fromTerminal}</td>
                                                    <td className="text-center">{item.fromChannel}</td>
                                                    <td className="text-center">{item.xDetail}</td>
                                                </tr>
                                            )
                                        })}
                                    </tbody>
                                </Table>
                            </Col>
                        </Row>
                    </ModalBody>
                    <ModalFooter>
                        <Button onClick={() => this.handleCloseDBWorkerModal()}>
                            Close
                        </Button>
                    </ModalFooter>
                </Modal>

            </Container>
        )
    }
} export default connect((state: ApplicationState) => ({ ...state.DBWorker }), ({ ...DBWorkerStore.actionCreators }))(DBWorker)