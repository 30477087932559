import { Fragment, useCallback, useRef } from 'react';
import {
    Button,
    Modal,
    ModalBody,
    ModalCloseButton,
    ModalContent,
    ModalFooter,
    ModalHeader,
    ModalOverlay
} from '@chakra-ui/react';

import alert from 'assets/sounds/alert.mp3';

type Props = {
    noCloseButton?: boolean;
    isOpen: boolean;
    error: { fileName: string; message: string; indexOfTransaction: number };
    onClose: () => void;
    skipResolverRef: React.MutableRefObject<
        ((value: 'skip' | 'skip_all' | 'cancel') => void) | undefined
    >;
};

function SkippableModal(props: Props) {
    const { noCloseButton, isOpen, error, skipResolverRef, onClose } = props;

    const alertAudio = useRef(new Audio(alert));

    const PARSE_ERR_INDEX_OF_TRANSACTION = -1;

    useCallback(() => {
        if (isOpen) {
            alertAudio.current.loop = true;
            alertAudio.current.play();
        } else {
            alertAudio.current.pause();
        }
    }, [isOpen]);

    const handleClickInteraction = useCallback(
        (type: 'skip' | 'skip_all' | 'cancel') => {
            skipResolverRef.current && skipResolverRef.current(type);
            onClose();
        },
        [skipResolverRef]
    );

    return (
        <Modal
            closeOnEsc={false}
            closeOnOverlayClick={false}
            isOpen={isOpen}
            onClose={onClose}
        >
            <ModalOverlay />
            <ModalContent>
                <ModalHeader>Error in {error.fileName}</ModalHeader>
                {!noCloseButton && <ModalCloseButton />}
                <ModalBody>
                    <span>{error.message}</span>
                </ModalBody>

                <ModalFooter>
                    <Button onClick={() => handleClickInteraction('cancel')}>Cancel</Button>
                    {error.indexOfTransaction > PARSE_ERR_INDEX_OF_TRANSACTION && (
                        <Fragment>
                            <Button onClick={() => handleClickInteraction('skip')}>Skip</Button>
                            <Button
                                colorScheme="red"
                                onClick={() => handleClickInteraction('skip_all')}
                            >
                                Skip All
                            </Button>
                        </Fragment>
                    )}
                </ModalFooter>
            </ModalContent>
        </Modal>
    );
}

export default SkippableModal;
