import {
    Button,
    Modal,
    ModalBody,
    ModalCloseButton,
    ModalContent,
    ModalFooter,
    ModalHeader,
    ModalOverlay,
    Badge,
    Input,
    FormControl,
    Select,
    Textarea
} from '@chakra-ui/react';
import { TransportRequestDetail, TransportRequestTag } from 'data/schemas/TransportRequest';
import { useCallback, useEffect, useState } from 'react';
import CardTransportRequest from './CardTransportRequestEdit';
import CardTransportRequestEdit from './CardTransportRequestEdit';

type Props = {
    isOpen: boolean;
    onClose: () => void;
    transportRequest: TransportRequestTag;
    handleEditOrUpdate: (body: TransportRequestTag) => void
}

function ModalEditTransportRequest(props: Props) {
    const { isOpen, onClose, transportRequest, handleEditOrUpdate } = props;

    const [transportRequestEdit, setTransportRequestEdit] = useState<TransportRequestTag>();

    useEffect(() => {
        setTransportRequestEdit(transportRequest);
    }, [transportRequest])

    const handleCloseModal = useCallback(() => {
        //clean up
        setTransportRequestEdit(transportRequest)

        onClose();
    }, [onClose]);

    const handleTransportRequestEditChange = (
        key: keyof TransportRequestTag,
        value: TransportRequestTag[keyof TransportRequestTag]
    ) => {
        setTransportRequestEdit(prevState => {
            if (!prevState) return prevState;
            return {
                ...prevState,
                [key]: value
            };
        });
    };

    const handleTransportDetailChange = (
        index: number,
        key: keyof TransportRequestDetail,
        value: TransportRequestDetail[keyof TransportRequestDetail]
    ) => {
        setTransportRequestEdit(prevState => {
            if (!prevState) return prevState;

            const updatedDetails = prevState.transportRequestDetail.map((detail, i) =>
                i === index ? { ...detail, [key]: value } : detail
            );

            return {
                ...prevState,
                transportRequestDetail: updatedDetails
            };
        });
    };

    return (
        <Modal
            size="6xl"
            isOpen={isOpen}
            onClose={handleCloseModal}
            closeOnOverlayClick={false}
            scrollBehavior="inside"
        >
            <ModalOverlay />
            <ModalContent className="h-screen">
                <ModalHeader className="border-b-2">Edit TransportRequest</ModalHeader>
                <ModalCloseButton className="border hover:bg-red-300" />
                <ModalBody>
                    {transportRequestEdit && (
                        <div className="flex h-full flex-row">
                            <div className="flex h-full w-2/4 flex-1 flex-col gap-y-4 pr-2">
                                <div className="flex flex-col gap-y-2 rounded-md border border-neutral-400 p-2">
                                    <div
                                        id="row-1"
                                        className="grid grid-cols-2 gap-x-2"
                                    >
                                        <div className="flex flex-col gap-2">
                                            <div className="flex flex-col">
                                                <span className="text-sm font-medium">
                                                    UAT Tag
                                                </span>
                                                <Input
                                                    name="UAT TAG"
                                                    size="sm"
                                                    className="rounded-sm border-neutral-400 text-black"
                                                    value={transportRequestEdit.uatTag}
                                                    errorBorderColor="crimson"
                                                    disabled
                                                />
                                            </div>
                                            <div className="flex flex-col">
                                                <span className="text-sm font-medium">
                                                    Detail
                                                </span>
                                                <Textarea
                                                    name="detail"
                                                    size="sm"
                                                    className="flex-1 rounded-sm border-neutral-400 text-black"
                                                    placeholder="กรุณาระบุรายละเอียดงาน"
                                                    errorBorderColor="crimson"
                                                    onChange={(e) => handleTransportRequestEditChange('details', e.target.value)}
                                                    value={transportRequestEdit.details}
                                                />
                                            </div>
                                            <div className="flex flex-col">
                                                <span className="text-sm font-medium">Remark</span>
                                                <Textarea
                                                    name="remark"
                                                    size="sm"
                                                    className="flex-1 rounded-sm border-neutral-400 text-black"
                                                    placeholder="หมายเหตุเพิ่มเติม"
                                                    onChange={(e) => handleTransportRequestEditChange('remark', e.target.value)}
                                                    value={transportRequestEdit.remark}
                                                />
                                            </div>
                                        </div>
                                        <div>
                                            {transportRequestEdit.transportRequestDetail.map((value, index) => (
                                                <CardTransportRequestEdit
                                                    key={index}
                                                    index={index}
                                                    fileUpload={value}
                                                    handleTransportDetailChange={handleTransportDetailChange}
                                                />
                                            ))}
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    )}
                </ModalBody>
                <ModalFooter>
                    <Button
                        colorScheme="green"
                        onClick={() => handleEditOrUpdate(transportRequestEdit!)}
                    >
                        Save
                    </Button>
                </ModalFooter>
            </ModalContent>
        </Modal>
    )
}

export default ModalEditTransportRequest