import { type } from 'os';
import { Action, Reducer } from 'redux';
import { AppThunkAction } from '.';

import * as Models from '../models/OverView'

interface LoadOverView {
    type: 'LOAD_OVERVIEW'
}

interface LoadedOverView {
    type: 'LOADED_OVERVIEW';
    response: Models.DataAdminOverView;
    status: number,
    statusMessage: string
}

interface FailLoadedOverView {
    type: 'LOAD_OVERVIEW_FAIL'
    status: number,
    statusMessage: any
}

interface ClearOverView {
    type: 'CLEAR_OVERVIEW'
}

type KnownAction = LoadOverView | LoadedOverView | FailLoadedOverView | ClearOverView;

export const actionCreators = {
    requestGetOverview: (startDateIndex: number): AppThunkAction<KnownAction> => (dispatch, getState) => {
        const appState = getState();
        if (startDateIndex == 100) {
            fetch(`v1/monitor/overview`)
                .then((response) => {
                    if (!response.ok) {
                        throw new Error(response.json() as any)
                    } else {
                        return response.json() as Promise<Models.DataAdminOverView>
                    }
                })
                .then((data) => {
                    dispatch({ type: 'LOADED_OVERVIEW', response: data, status: 200, statusMessage: '' });
                })
                .catch(error => {
                    dispatch({ type: 'LOAD_OVERVIEW_FAIL', status: 204, statusMessage: 'errorfetch' });
                })
            dispatch({ type: 'LOAD_OVERVIEW' })
        }
    }
};


const unloadedState: Models.AdminOverViewState = { isLoadAdminOverView: false, ResponseAdminOverView: [] };

export const reducer: Reducer<Models.AdminOverViewState> = (state: Models.AdminOverViewState | undefined, incomingAction: Action): Models.AdminOverViewState => {
    if (state === undefined) {
        return unloadedState;
    }
    const action = incomingAction as KnownAction;
    switch (action.type) {
        case 'LOAD_OVERVIEW':
            return {
                isLoadAdminOverView: false,
                ResponseAdminOverView: []
            }

        case 'LOADED_OVERVIEW':
            return {
                ResponseAdminOverView: action.response as any,
                isLoadAdminOverView: true,
                statusAdminOverView: action.status,
                messageAdminOverView: action.statusMessage
            }
        case 'LOAD_OVERVIEW_FAIL':
            return {
                isLoadAdminOverView: false,
                messageAdminOverView: action.statusMessage,
                statusAdminOverView: action.status,
                ResponseAdminOverView: []
            }
        default: return state;
    }

};