import React, { useState } from 'react';
import { Input, Select, Table, TableContainer, Tbody, Td, Th, Thead, Tr } from '@chakra-ui/react';
import DatePicker from 'react-datepicker';
import { CiCalendarDate } from 'react-icons/ci';

import 'react-datepicker/dist/react-datepicker.css';
import F1ApiLogViewModel from './F1ApiLog.viewmodel';
import { convertToYYYYMMDD, formatDateTime } from 'helpers/formatDateTime';
import { IoIosSearch } from 'react-icons/io';
import ReactPaginate from 'react-paginate';
import ModalDetailApiLog from './components/ModalDetailApiLog';
import ModalDeleteLogApi from './components/ModalDeleteLogApi';

function F1ApiLogView() {
    const {
        headers,
        company,
        companyList,
        dateForm,
        dateTo,
        searchHelp,
        totalPages,
        f1ApiLogResponse,
        f1ApiLogDetail,
        showF1ApiLogDetail,
        f1ApiLogSizeResponse,
        showModalDeleteLogF1Api,

        setDateTo,
        setDateForm,
        handlePageChange,
        handleSearchChange,
        handleSearchApiLog,
        setShowF1ApiLogDetail,
        handleClearLog,
        handleOpenModalShowDetailF1ApiLog,
        setShowModalDeleteLogF1Api,
        handleChangeDeleteLogParams,
        handleOpenModalShowDeleteF1ApiLog
    } = F1ApiLogViewModel();

    return (
        <React.Fragment>
            <div className="flex flex-row justify-evenly">
                <div className="grid grid-cols-2 gap-4">
                    <div className="flex items-center gap-2">
                        <label
                            htmlFor="company-header"
                            className="w-1/4"
                        >
                            Company:{' '}
                        </label>
                        <Select
                            placeholder="กรุณาระบุ Company"
                            onChange={e => {
                                handleSearchChange('company', e.target.value);
                                handleChangeDeleteLogParams('company', e.target.value);
                            }}
                            width="sm"
                        >
                            {companyList &&
                                companyList.map((value, index) => (
                                    <option
                                        key={index}
                                        value={value.value}
                                    >
                                        {value.name}
                                    </option>
                                ))}
                        </Select>
                    </div>
                    <div className="flex flex-col gap-4">
                        <div className="flex items-center gap-2">
                            <label className="w-1/4">Api Name: </label>
                            <Input
                                onChange={e => {
                                    handleSearchChange('apiName', e.target.value);
                                }}
                            />
                        </div>
                    </div>
                    <div className="flex flex-col gap-4">
                        <div className="flex items-center gap-2">
                            <label>Created From: </label>
                            <DatePicker
                                showIcon
                                selected={dateForm}
                                showTimeSelect
                                timeFormat="HH:mm"
                                timeIntervals={30}
                                timeCaption="time"
                                dateFormat="MMMM d, yyyy h:mm aa"
                                onChange={date => {
                                    setDateForm(date!);
                                    handleSearchChange(
                                        'createdFrom',
                                        convertToYYYYMMDD(date?.toISOString()!)
                                    );
                                }}
                                className="rounded-md border"
                            />
                        </div>
                    </div>
                    <div className="flex flex-col gap-4">
                        <div className="flex items-center gap-2">
                            <label>Created To: </label>
                            <DatePicker
                                showIcon
                                selected={dateTo}
                                showTimeSelect
                                timeFormat="HH:mm"
                                timeIntervals={30}
                                timeCaption="time"
                                dateFormat="MMMM d, yyyy h:mm aa"
                                onChange={date => {
                                    setDateTo(date!);
                                    handleSearchChange(
                                        'createdTo',
                                        convertToYYYYMMDD(date?.toISOString()!)
                                    );
                                }}
                                className="rounded-md border"
                            />
                        </div>
                    </div>
                </div>

                <div className="flex flex-col items-end gap-5">
                    <button
                        className="rounded-2xl border-2 px-4 py-2"
                        onClick={handleOpenModalShowDeleteF1ApiLog}
                    >
                        Clear F1 Api Log
                    </button>
                    <button
                        className="rounded-2xl border-2 px-4 py-2"
                        onClick={handleSearchApiLog}
                    >
                        Search
                    </button>
                </div>
            </div>
            <div className="mt-5 border-t-2">
                <TableContainer
                    whiteSpace="wrap"
                    className="mt-2"
                >
                    <Table
                        size="sm"
                        variant="unstyled"
                    >
                        <Thead>
                            <Tr>
                                {headers.map(header => (
                                    <Th
                                        key={header}
                                        className="border text-center"
                                        style={{
                                            borderColor:
                                                'rgb(212 212 212 / var(--tw-border-opacity)) !important'
                                        }}
                                    >
                                        {header}
                                    </Th>
                                ))}
                            </Tr>
                        </Thead>
                        <Tbody>
                            {f1ApiLogResponse ? (
                                f1ApiLogResponse.map((value, index) => (
                                    <Tr
                                        key={index}
                                        className="[&>td]:border [&>td]:border-neutral-300"
                                    >
                                        <Td className="text-center">{value.id}</Td>
                                        <Td>{value.apiName}</Td>
                                        <Td className="text-center">{value.level}</Td>
                                        <Td className="text-center">
                                            {formatDateTime(value.timeStamp)}
                                        </Td>
                                        <Td>{value.createdBy}</Td>
                                        <Td className="text-center">
                                            <button
                                                onClick={() => {
                                                    handleOpenModalShowDetailF1ApiLog(value);
                                                }}
                                            >
                                                <IoIosSearch size={20} />
                                            </button>
                                        </Td>
                                    </Tr>
                                ))
                            ) : (
                                <tr className="text-center">
                                    <td colSpan={headers.length}>
                                        <span>No data..</span>
                                    </td>
                                </tr>
                            )}
                        </Tbody>
                    </Table>
                </TableContainer>
                <ReactPaginate
                    breakLabel="..."
                    nextLabel=">"
                    previousLabel="<"
                    pageRangeDisplayed={5}
                    pageCount={totalPages}
                    onPageChange={handlePageChange}
                    renderOnZeroPageCount={null}
                    activeClassName="active"
                    containerClassName="pagination flex justify-end mt-2"
                    pageLinkClassName="pagination flex justify-center items-center w-8 h-8 border border-gray-300 rounded-full mr-2 cursor-pointer"
                    activeLinkClassName="bg-blue-500 text-white"
                    previousClassName="pagination flex justify-center items-center w-8 h-8 border border-gray-300 rounded-full mr-2 cursor-pointer"
                    nextClassName="pagination flex justify-center items-center w-8 h-8 border border-gray-300 rounded-full cursor-pointer"
                />
            </div>
            <ModalDetailApiLog
                f1ApiLogDetail={f1ApiLogDetail!}
                isOpenModal={showF1ApiLogDetail}
                onClose={setShowF1ApiLogDetail.off}
            />
            <ModalDeleteLogApi
                handleChangeDeleteParams={handleChangeDeleteLogParams}
                handleClearLog={handleClearLog}
                selectdCompany={company!}
                logSize={f1ApiLogSizeResponse!}
                isOpenModal={showModalDeleteLogF1Api}
                onClose={setShowModalDeleteLogF1Api.off}
            />
        </React.Fragment>
    );
}

export default F1ApiLogView;
