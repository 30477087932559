import React from 'react';
import ReactPaginate from 'react-paginate';

import TransportRequestUATViewModel from './TransportRequestUAT.viewmodel';
import ModalAddTransportRequest from './components/ModalAddTransportRequest';
import TransportRequestTable from './components/TransportRequestTable';
import ActionPane from './components/ActionPane';
import NavigateDeploymentUAT from './components/NavigateDeploymentUAT';
import ModalCreateTransportRequest from './components/ModalCreateTransportRequest';
import ModalEditTransportRequest from './components/ModalEditTransportRequest';
import ModalReadyForPRD from './components/ModalReadyForPRD';

function TransportRequestUATView() {
    const {
        fappPorts,
        totalPages,
        currentItems,
        selectedDeploy,
        isOpenModalDeploy,
        refTransportRequest,
        showReadyForPRDModal,
        showAddTransportRequestModal,
        showEditransportRequestModal,

        handleDeployDll,
        handlePageChange,
        toggleModalDeploy,
        handleSearchChange,
        handleShowModalEdit,
        handleConfirmCreateTR,
        handleConfirmDeployTR,
        fetchTransportRequest,
        setShowReadyForPRDModal,
        handleConfirmCreateTRPRD,
        handleShowModalReadyForPRD,
        updateTransportRequestResult,
        handleFetchGetPathFileByUatTag,
        setShowAddTransportRequestModal,
        setShowEditTransportRequestModal,
        handleConfirmEditOrUpdateTransportRequest
    } = TransportRequestUATViewModel();

    return (
        <React.Fragment>
            <ActionPane
                onClickAddTransportRequest={setShowAddTransportRequestModal.on}
                onClickSearchTransportRequest={fetchTransportRequest}
                onSearchTransportRequest={handleSearchChange}
            />

            <TransportRequestTable
                transportRequestTag={currentItems!}
                onClickDeploy={handleDeployDll}
                onClickShowModalEdit={handleShowModalEdit}
                onClickDowloadFile={handleFetchGetPathFileByUatTag}
                onClickShowModalReadyForPrd={handleShowModalReadyForPRD}
            />

            <ReactPaginate
                breakLabel="..."
                nextLabel=">"
                previousLabel="<"
                pageRangeDisplayed={5}
                pageCount={totalPages}
                onPageChange={handlePageChange}
                renderOnZeroPageCount={null}
                activeClassName="active"
                containerClassName="pagination flex justify-end mt-2"
                pageLinkClassName="pagination flex justify-center items-center w-8 h-8 border border-gray-300 rounded-full mr-2 cursor-pointer"
                activeLinkClassName="bg-blue-500 text-white"
                previousClassName="pagination flex justify-center items-center w-8 h-8 border border-gray-300 rounded-full mr-2 cursor-pointer"
                nextClassName="pagination flex justify-center items-center w-8 h-8 border border-gray-300 rounded-full cursor-pointer"
            />
            {/* <ModalAddTransportRequest
                isOpen={showAddTransportRequestModal}
                onClose={setShowAddTransportRequestModal.off}
                handleConfirmCreate={handleConfirmCreateTR}
            /> */}
            <ModalCreateTransportRequest
                isOpen={showAddTransportRequestModal}
                onClose={setShowAddTransportRequestModal.off}
                handleConfirmCreate={handleConfirmCreateTR}
            />
            <NavigateDeploymentUAT
                isOpenModal={isOpenModalDeploy}
                toggleModal={toggleModalDeploy}
                selectedDeploy={selectedDeploy}
                handleConfirmDeploy={handleConfirmDeployTR}
                deployTransportRequestResult={updateTransportRequestResult}
                fappPorts={fappPorts}
            />
            <ModalEditTransportRequest
                isOpen={showEditransportRequestModal}
                onClose={setShowEditTransportRequestModal.off}
                transportRequest={refTransportRequest.current!}
                handleEditOrUpdate={handleConfirmEditOrUpdateTransportRequest}
            />
            <ModalReadyForPRD
                isOpen={showReadyForPRDModal}
                onClose={setShowReadyForPRDModal.off}
                selectedTagForPRD={selectedDeploy}
                handleConfirmCreateTR={handleConfirmCreateTRPRD}
            />
        </React.Fragment>
    );
}

export default TransportRequestUATView;
