import '../../../../assets/style/style.css';

import React from 'react';
import ReactDatatable from '@ashvin27/react-datatable';
import { Button } from 'reactstrap';
import BootstrapSwitchButton from 'bootstrap-switch-button-react';
import { AiOutlineReload } from 'react-icons/ai';

import { config } from '../../../../constants';
import { AdminDashboardOnClickType } from '../AdminDashboard.viewmodel';
import QuickBox from './QuickBox';

type AdminDashboardTableProps<T> = {
    columns: {
        key: string;
        text: string;
        className: string;
        sortable: boolean;
        cell: (record: T, index: number) => React.JSX.Element;
    }[];
    data?: T[];
    onCheckOnlineStatus: (item: T) => boolean;
    handleClickEvent: (key: AdminDashboardOnClickType, value: string) => void;
    onToggleSwitch: (checked: boolean) => void;
    isAutoRefresh: boolean;
    toggleRefresh: () => void;
};

function AdminDashboardTableComponent<T>(props: AdminDashboardTableProps<T>) {
    const { columns, data, onCheckOnlineStatus } = props;

    return (
        <div>
            {data && (
                <div
                    className="totalserver"
                    style={{ fontSize: '14px' }}
                >
                    {/* Header */}
                    <div className="d-flex align-items-center justify-content-end">
                        <div>
                            <span className="m-2 text-base">Auto Refresh</span>
                        </div>
                        <div>
                            <BootstrapSwitchButton
                                size="sm"
                                onlabel="ON"
                                offlabel="OFF"
                                checked={props.isAutoRefresh}
                                onChange={(checked: boolean) => {
                                    props.onToggleSwitch(checked);
                                }}
                                onstyle="primary"
                            />
                        </div>
                        <div>
                            <h3 className="m-2">|</h3>
                        </div>
                        <div>
                            <Button
                                color="primary"
                                onClick={props.toggleRefresh}
                                className="inline-flex items-center gap-2 px-2 py-1.5 text-base"
                            >
                                Refresh
                                <AiOutlineReload size={18} />
                            </Button>
                        </div>
                        <div className="d-flex m-2">
                            <QuickBox title="Total Server ">
                                <>
                                    &nbsp;{data.length}
                                    &nbsp;
                                </>
                            </QuickBox>
                            <QuickBox title="ON">
                                <span style={{ color: 'green' }}>
                                    {' '}
                                    &nbsp;
                                    {data.filter(onCheckOnlineStatus).length}
                                    &nbsp;
                                </span>
                            </QuickBox>
                            <QuickBox title="OFF">
                                <span style={{ color: 'red' }}>
                                    {' '}
                                    &nbsp;
                                    {data.filter(item => !onCheckOnlineStatus(item)).length}
                                    &nbsp;
                                </span>
                            </QuickBox>
                        </div>
                    </div>

                    {/* Table - Server Config Detail */}
                    <div className="[&_.asrt-page-length]:items-center">
                        <ReactDatatable
                            className="table-striped table-hover table"
                            tHeadClassName="custom-header-style"
                            config={config.configdatatable}
                            records={data}
                            columns={columns}
                        />
                    </div>
                </div>
            )}
        </div>
    );
}

export default AdminDashboardTableComponent;
