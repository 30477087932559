export const configdatatable = {
    page_size: 10,
    length_menu: [10, 20, 30],
    show_filter: true,
    show_pagination: true,
    pagination: 'advance',
    button: {
        excel: false,
        print: false
    }
}

export const configdatatableOverView = {
    page_size: 100,
    length_menu: [100, 10, 20, 30],
    show_filter: true,
    show_pagination: true,
    pagination: 'advance',
    button: {
        excel: false,
        print: false
    }
}