import React from 'react';
import PortConfigViewModel from './PortConfig.viewmodel';
import { observer } from 'mobx-react';
import PortConfigTable from './components/PortConfigTable';

function PortConfigView() {
    const {
        portListResponse,
        handleChangeStatusPort
    } = PortConfigViewModel();

    return (
        <div>
            <PortConfigTable portList={portListResponse!} handleChangeStatusPort={handleChangeStatusPort} />
        </div>
    );
}

export default PortConfigView;