import React, { useState } from 'react';
import { Col, Collapse, ListGroupItem, Row } from 'reactstrap';
import { Column } from '../../models/DBComparison';
import { HiPlusSm, HiMinusSm } from 'react-icons/hi';
import useDisclosure from '../../commons/hooks/useDisclosure';
import { ResultTypes } from './DBDetail';

interface Props {
    column: Column;
    type: ResultTypes;
}

function ColumnItem({ column, type }: Props) {
    const { isOpen, onToggle } = useDisclosure();
    const [isShow, setIsShow] = useState<boolean>(false);
    const style =
        type === ResultTypes.NotMatch
            ? 'text-danger'
            : type === ResultTypes.MisMatch
            ? 'text-warning'
            : '';
    const propertiesStyle =
        type === ResultTypes.NotMatch
            ? 'text-white bg-danger'
            : type === ResultTypes.MisMatch
            ? 'text-white bg-warning'
            : '';

    return (
        <React.Fragment>
            <ListGroupItem
                className={`column-item py-0 ${style}`}
                onClick={() => onToggle()}
            >
                {!isShow ? (
                    <HiPlusSm className="mr-2" />
                ) : (
                    <HiMinusSm className="mr-2" />
                )}
                <span>{column.columnName}</span>
            </ListGroupItem>
            <Collapse
                isOpen={isOpen}
                onEnter={() => setIsShow(true)}
                onExited={() => setIsShow(false)}
            >
                {isShow && (
                    <ListGroupItem
                        className={`properties-item py-0 ${propertiesStyle}`}
                    >
                        <Row>
                            <Col>Data Type</Col>
                            <Col sm={7}>{column.dataType}</Col>
                        </Row>
                        <Row>
                            <Col>Length</Col>
                            <Col sm={7}>{column.characterMaximumLength}</Col>
                        </Row>
                        <Row>
                            <Col>Nullable</Col>
                            <Col sm={7}>{column.isNullable}</Col>
                        </Row>
                        <Row>
                            <Col>Default</Col>
                            <Col sm={7}>{column.columnDefault}</Col>
                        </Row>
                        <Row>
                            <Col>Ordinal Position</Col>
                            <Col sm={7}>{column.ordinalPosition}</Col>
                        </Row>
                        <Row>
                            <Col>Octet Length</Col>
                            <Col sm={7}>{column.characterOctetLength}</Col>
                        </Row>
                        <Row>
                            <Col>Numberic Precision</Col>
                            <Col sm={7}>{column.numbericPrecision}</Col>
                        </Row>
                        <Row>
                            <Col>numberic Precision Radix</Col>
                            <Col sm={7}>{column.numbericPrecisionRadix}</Col>
                        </Row>
                        <Row>
                            <Col>Numberic Scale</Col>
                            <Col sm={7}>{column.numbericScale}</Col>
                        </Row>
                        <Row>
                            <Col>Date Time Precision</Col>
                            <Col sm={7}>{column.dateTimePrecision}</Col>
                        </Row>
                        <Row>
                            <Col>Character Set Catalog</Col>
                            <Col sm={7}>{column.characterSetCatalog}</Col>
                        </Row>
                        <Row>
                            <Col>Character Set Schema</Col>
                            <Col sm={7}>{column.characterSetSchema}</Col>
                        </Row>
                        <Row>
                            <Col>Character Set Name</Col>
                            <Col sm={7}>{column.characterSetName}</Col>
                        </Row>
                        <Row>
                            <Col>Collation Catalog</Col>
                            <Col sm={7}>{column.collationCatalog}</Col>
                        </Row>
                        <Row>
                            <Col>Collation Schema</Col>
                            <Col sm={7}>{column.collationSchema}</Col>
                        </Row>
                        <Row>
                            <Col>Collation Name</Col>
                            <Col sm={7}>{column.collationName}</Col>
                        </Row>
                        <Row>
                            <Col>Domain Catalog</Col>
                            <Col sm={7}>{column.domainCatalog}</Col>
                        </Row>
                        <Row>
                            <Col>Domain Schema</Col>
                            <Col sm={7}>{column.domainSchema}</Col>
                        </Row>
                        <Row>
                            <Col>Domain Name</Col>
                            <Col sm={7}>{column.domainName}</Col>
                        </Row>
                    </ListGroupItem>
                )}
            </Collapse>
        </React.Fragment>
    );
}

export default ColumnItem;
