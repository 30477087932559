import { AxiosError } from 'axios';

import { validateDataResponse } from '../../../commons/helpers';
import { AuthenticationResponse } from 'data/schemas/auth';
import { retrieveMasterEmployee } from 'data/datasources/v1/auth';

export async function getMasterEmployee(params: {
    citizenId: string;
}): Promise<AuthenticationResponse> {
    try {
        const response = await retrieveMasterEmployee(params);
        const result: AuthenticationResponse = validateDataResponse(response);

        return result;
    } catch (error) {
        if (error instanceof AxiosError && error.response) {
            throw new Error(validateDataResponse(error.response).message);
        }

        throw error;
    }
}
