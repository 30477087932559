import { useState, useEffect, useCallback, useMemo, useRef } from 'react';
import { useParams } from 'react-router-dom';
import Swal from 'sweetalert2';

import {
    DataAdminServer,
    ServerConfig,
    FappUpdateRequest,
    CommandType,
    RunCommandResponse,
    CommandLog
} from '../../../models/AdminServer';
import { useLoader } from '../../../commons/hooks';
import { getMonitorServerByServerIdV2 } from '../../../data/repositories/monitor/monitorServer.repository';
import { updateFappOrWebWithCommand } from '../../../data/repositories/fapp/fappUpdate.repository';
import {
    getCommandConfigsByDomainAndFilterWithPortName,
    getCommandConfigsMaster
} from '../../../data/repositories/command/commandConfig.repository';
import { validateNumberOnly } from '../../../commons/helpers';
import { CommandConfigMaster } from '../../../models/CommandConfig';

interface ParamTypes {
    id: string;
}

interface ToolTabType {
    tabNumber: number;
    tabName: 'SVN' | 'GIT';
}

function AdminDashboardControlViewModel() {
    const screenLoader = useLoader();

    const [isOpenModal, setOpenModal] = useState(false);
    const toggleModal = () => setOpenModal(!isOpenModal);

    const [isOpenCommandVerifyModal, setOpenCommandVerifyModal] =
        useState(false);
    const toggleCommandVerifyModal = () =>
        setOpenCommandVerifyModal(!isOpenCommandVerifyModal);

    const [refresh, setRefresh] = useState(false);
    const toggleRefresh = () => setRefresh(!refresh);

    const [monitorServer, setMonitorServer] = useState<DataAdminServer>();
    const [serverConfig, setServerConfig] = useState<ServerConfig>({
        id: '',
        group: '',
        type: '',
        name: '',
        ipAddress: ''
    });
    const [svnRevision, setSvnRevision] = useState<string>('');
    const [selectedToolTab, setSelectedToolTab] = useState<ToolTabType>({
        tabNumber: 2,
        tabName: 'GIT'
    });
    const [selectedPort, setSelectedPort] = useState<string>('');
    const [outputLog, setOutputLog] = useState<RunCommandResponse[]>([]);
    const [commandConfigsMaster, setCommandConfigsMaster] = useState<
        CommandConfigMaster[]
    >([]);
    const [commandRequest, setCommandRequest] = useState<FappUpdateRequest>({
        arguments: '',
        domain: '',
        port: '',
        command_name: '',
        cmd_repo_path: '',
        time_out: 0,
        tool_update: '',
        log_application_target: '',
        log_developer: '',
        log_tag: '',
        log_description: ''
    });

    const { id } = useParams<ParamTypes>();

    const [inputErrors, setInputErrors] = useState<
        Partial<Record<'developerInput' | 'applicationTargetInput', boolean>>
    >({});

    useEffect(() => {
        _fetchMonitorServer(id);
    }, []);

    const portOptions = useMemo(() => {
        if (!monitorServer) {
            return [];
        }

        // const domain = encodeURIComponent(monitorServer.domain);
        // console.log('[Debug] domain', domain);

        switch (selectedToolTab.tabName) {
            case 'SVN': {
                const webSvnPortOptions = monitorServer.webPortList
                    ? monitorServer.webPortList
                          .split('|')
                          .map(port => ({ label: port, value: port }))
                    : undefined;

                return webSvnPortOptions ?? [];
            }
            default: {
                const fappPortOptions = monitorServer.portList
                    ? monitorServer.portList
                          .split('|')
                          .map(port => ({ label: port, value: port }))
                    : undefined;

                const webGitPortOptions =
                    monitorServer.toolUpdate === selectedToolTab.tabName
                        ? monitorServer.webPortList
                              .split('|')
                              .map(port => ({ label: port, value: port }))
                        : undefined;

                const gitPortOptions = [
                    ...(fappPortOptions ?? []),
                    ...(webGitPortOptions ?? [])
                ];

                return gitPortOptions;
            }
        }
    }, [selectedToolTab, monitorServer]);

    useEffect(() => {
        if (monitorServer) {
            _fetchMonitorServer(id);
        }

        _fetchCommandConfigsMaster(selectedToolTab.tabName);
    }, [selectedToolTab]);

    const onSelectToolTab = (toolTab: ToolTabType) => {
        setSelectedToolTab(toolTab);
        setSelectedPort('');
    };

    const onChangeServerConfig = (value: ServerConfig) => {
        // console.log('[Debug] config changed', value);
        setServerConfig(prevState => ({ ...prevState, ...value }));
    };

    const onClickSaveServerConfig = async (mode: 'edit' | 'delete') => {
        const result = await Swal.fire({
            title: 'คุณแน่ใจไหม?',
            text: 'ในการ แก้ไขหรือลบ ข้อมูล!',
            icon: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#3085d6',
            cancelButtonColor: '#d33',
            cancelButtonText: 'ยกเลิก',
            confirmButtonText: 'ยืนยัน'
        });

        if (result.isConfirmed) {
            switch (mode) {
                case 'edit': {
                    const reqBody = {
                        id: serverConfig.id,
                        group: serverConfig.group,
                        type: serverConfig.type,
                        name: serverConfig.name,
                        domain: serverConfig.ipAddress,
                        created_by: ''
                    };

                    await _saveEditServerConfig(reqBody);

                    break;
                }
                case 'delete': {
                    await _saveDeleteServerConfig(serverConfig.id);

                    break;
                }
                default:
                    break;
            }
        }
    };

    const onSelectPort = (port: string) => {
        // console.log('[Debug] selected port', port);
        if (!port) {
            Swal.fire('ไม่มี port นี้!', '', 'error');
        }

        setSelectedPort(port);
    };

    const onChangeSvnRevision = (revision: string) => {
        setSvnRevision(revision);
    };

    const onChangeCommandVerify = (value: CommandLog) => {
        setCommandRequest({
            ...commandRequest,
            log_application_target: value.application_target,
            log_developer: value.developer,
            log_tag: value.tag,
            log_description: value.description
        });
    };

    const onClickCommandBtn = (command: CommandType, path: string) => {
        if (!selectedPort) {
            Swal.fire('Warning!', 'ไม่พบ port ที่เลือก', 'warning');

            return;
        }

        if (command.name === 'SVN UPDATE' && svnRevision === '') {
            Swal.fire('Warning!', 'กรุณาระบุเลข revision', 'warning');
            setSvnRevision('');

            return;
        }

        const isNumberic = validateNumberOnly(svnRevision);
        if (!isNumberic && command.name === 'SVN UPDATE') {
            Swal.fire(
                'Warning!',
                'เลข revision ต้องเป็นตัวเลขเท่านั้น',
                'warning'
            );
            setSvnRevision('');

            return;
        }

        // Prepare request body
        setCommandRequest({
            arguments: command.script,
            domain: serverConfig.ipAddress,
            port: selectedPort,
            command_name: command.name,
            cmd_repo_path: path,
            time_out: 5,
            tool_update: selectedToolTab.tabName,
            log_application_target: '',
            log_developer: '',
            log_tag: '',
            log_description: ''
        });

        // Pre confirm before run command.
        toggleCommandVerifyModal();
    };

    const onClickConfirmRunCommand = () => {
        // Validate data form before call API run command
        const nextInputErrors: typeof inputErrors = {};

        // Set input error is true
        // input border color change by condition
        if (commandRequest.log_developer === '') {
            nextInputErrors.developerInput = true;
        }

        if (commandRequest.log_application_target === '') {
            nextInputErrors.applicationTargetInput = true;
        }

        // Found an errors
        // Do nothing after this
        if (Object.keys(nextInputErrors).length > 0) {
            setInputErrors(nextInputErrors);

            return;
        }

        // Reset border color
        setInputErrors(nextInputErrors);

        // Call API run command
        console.log('[Debug 1]', commandRequest);
        _runCommand(commandRequest);

        // Close verify modal
        toggleCommandVerifyModal();
    };

    // ==========================================
    // Now call API using fetch
    // chang this to use Repository
    const _saveEditServerConfig = async reqBody => {
        const requestOptions = {
            method: 'PUT',
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify(reqBody)
        };

        const response = await fetch(`/v1/config/server/edit/`, requestOptions);

        validateResponse(response);
    };

    const _saveDeleteServerConfig = async (id: string) => {
        const response = await fetch(`/v1/config/server/delete/` + id, {
            method: 'DELETE'
        });

        validateResponse(response);
    };

    const validateResponse = async (response: Response) => {
        if (!response.ok) {
            Swal.fire('ไม่สำเร็จ!', '', 'error');

            return;
        }

        const result = await response.json();
        console.log('[Debug] result', result);

        Swal.fire('สำเร็จ!', '', 'success');
        setOpenModal(false);
        setTimeout(() => toggleRefresh(), 2000);
    };
    // ==========================================

    const _runCommand = useCallback(async (reqBody: FappUpdateRequest) => {
        try {
            screenLoader.show();

            const result = await updateFappOrWebWithCommand(reqBody);

            // console.log('[Debug] response result', result);
            setOutputLog(result);
        } catch (error) {
            if (error instanceof Error) {
                Swal.fire('เกิดข้อผิดพลาด!', error.message, 'error');
            }
        } finally {
            setSvnRevision('');
            screenLoader.hide();
        }
    }, []);

    const _fetchMonitorServer = useCallback(
        async (serverId: string) => {
            try {
                screenLoader.show();

                const monitorServer = await getMonitorServerByServerIdV2(
                    serverId,
                    {
                        tool_update: selectedToolTab.tabName
                    }
                );

                setMonitorServer(monitorServer);
                setServerConfig({
                    id: monitorServer.id,
                    group: monitorServer.group,
                    type: monitorServer.type,
                    name: monitorServer.name,
                    ipAddress: monitorServer.domain
                });
            } catch (error) {
                console.log('error', error);

                if (error instanceof Error) {
                    Swal.fire('เกิดข้อผิดพลาด!', error.message, 'error');
                }
            } finally {
                screenLoader.hide();
            }
        },
        [selectedToolTab]
    );

    const _fetchCommnadConfigs = useCallback(
        async (domain: string, portName: string) => {
            try {
                screenLoader.show();

                const resultList =
                    await getCommandConfigsByDomainAndFilterWithPortName({
                        domain,
                        port_name: portName
                    });

                console.log('[Debug] command name', resultList);
            } catch (error) {
                if (error instanceof Error) {
                    Swal.fire('เกิดข้อผิดพลาด!', error.message, 'error');
                }
            } finally {
                screenLoader.hide();
            }
        },
        []
    );

    const _fetchCommandConfigsMaster = useCallback(async (filter: string) => {
        try {
            screenLoader.show();

            const commandConfigMasterList = await getCommandConfigsMaster({
                filter
            });

            // console.log('[Debug] command config master', commandConfigMasterList);
            setCommandConfigsMaster(commandConfigMasterList);
        } catch (error) {
            if (error instanceof Error) {
                Swal.fire('เกิดข้อผิดพลาด!', error.message, 'error');
            }
        } finally {
            screenLoader.hide();
        }
    }, []);

    return {
        monitorServer,
        isOpenModal,
        toggleModal,
        selectedToolTab,
        onSelectToolTab,
        onChangeServerConfig,
        onClickSaveServerConfig,
        serverConfig,
        onSelectPort,
        portOptions,
        onClickCommandBtn,
        outputLog,
        onChangeSvnRevision,
        svnRevision,
        commandConfigsMaster,
        isOpenIdentityModal: isOpenCommandVerifyModal,
        toggleIdentityModal: toggleCommandVerifyModal,
        commandRequest,
        onClickConfirmRunCommand,
        onChangeCommandVerify,
        inputErrors
    };
}

export default AdminDashboardControlViewModel;
