import axios from 'axios';

import { PortConfigResponse, PortConfigUpdateRequest } from 'data/schemas/portConfig';

export async function retrievePortConfigList() {
    return axios.get<PortConfigResponse[]>('v1/getlistportconfig');
}

export async function updateConfigPort(body: PortConfigUpdateRequest) {
    return axios.post<string>('v1/configport', body);
}
