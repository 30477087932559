export function convertToYYYYMMDD(dateTimeString: string): string {
    const date = new Date(dateTimeString);
    const year = date.getFullYear();
    const month = (date.getMonth() + 1).toString().padStart(2, '0');
    const day = date.getDate().toString().padStart(2, '0');
    const hours = date.getHours().toString().padStart(2, '0');
    const minutes = date.getMinutes().toString().padStart(2, '0');
    const seconds = date.getSeconds().toString().padStart(2, '0');

    return `${year}${month}${day}${hours}${minutes}${seconds}`;
}

export function formatDateTime(input: string): string {
    const year = input.substring(0, 4);
    const monthNumber = parseInt(input.substring(4, 6), 10);
    const day = input.substring(6, 8);
    const hours = input.substring(8, 10);
    const minutes = input.substring(10, 12);
    const seconds = input.substring(12, 14);

    const monthNames = [
        'Jan',
        'Feb',
        'Mar',
        'Apr',
        'May',
        'Jun',
        'Jul',
        'Aug',
        'Sep',
        'Oct',
        'Nov',
        'Dec'
    ];
    const monthName = monthNames[monthNumber - 1];

    return `${year}-${monthName}-${day} ${hours}:${minutes}:${seconds}`;
}
