import React, { useCallback, useState, useRef, useMemo } from 'react';
import { observer } from 'mobx-react';
import {
    Button,
    Modal,
    ModalBody,
    ModalCloseButton,
    ModalContent,
    ModalFooter,
    ModalHeader,
    ModalOverlay,
    Badge,
    Input,
    FormControl,
    Select,
    Textarea
} from '@chakra-ui/react';
import { useStore } from 'commons/hooks';
import UploadFiles from './UploadFiles';
import CardTransportRequest, { CardTransportRequestRefHandle } from './CardTransportRequest';
import { CreateTransportRequest, TransportRequestItem } from 'data/schemas/TransportRequest';

type TransportRequestHeaderInputFields = {
    ticket_no: string;
    detail: string;
    impact: string;
    risk_level: string;
    // Do not validate
    remark?: string;
    feature?: string;
};

export type TransportRequestItemsInputFields = {
    file_name: string;
    version: string;
    module: string;
    add_or_edit: string;
    core_or_noncore: string;
    file: File;
};

const INITIAL_INPUT_HEADER_FIELDS_STATE: TransportRequestHeaderInputFields = {
    ticket_no: '',
    detail: '',
    impact: '',
    risk_level: '',
    feature: '',
    remark: ''
};

const INITIAL_INPUT_ITEMS_FIELDS_STATE: TransportRequestItemsInputFields[] = [];

interface CreateTransportRequestProps {
    isOpen: boolean;
    onClose: () => void;
    handleConfirmCreate: (req: CreateTransportRequest) => void;
}

function ModalCreateTransportRequest(props: CreateTransportRequestProps) {
    const { isOpen, onClose, handleConfirmCreate } = props;
    const { authentication } = useStore();
    const citizenId = authentication.masterEmployeeResponse?.ctizenID;
    const [filesUpload, setFilesUpload] = useState(INITIAL_INPUT_ITEMS_FIELDS_STATE);
    const [inputHeaderFields, setInputHeaderFields] = useState(INITIAL_INPUT_HEADER_FIELDS_STATE);
    const [errorFields, setErrorFields] = useState({} as TransportRequestHeaderInputFields);

    const cardsRef = useRef<(CardTransportRequestRefHandle | null)[]>([]);

    const fileLengths = useMemo(
        () =>
            filesUpload.reduce(
                (acc, current) => {
                    acc['*'] += 1;
                    acc[current.module] += 1;

                    return acc;
                },
                { '*': 0, FAPP: 0, FGUI: 0, ScriptDB: 0 }
            ),
        [filesUpload]
    );

    const handleAddFiles = (id: string, files: File[]) => {
        const nextFilesUpload = files.map<TransportRequestItemsInputFields>(file => ({
            file_name: file.name,
            module: id,
            version: '',
            add_or_edit: '',
            core_or_noncore: '',
            file
        }));

        const seenFiles = new Set<{ file_name: string; module: string }>();
        const filteredDuplicateFiles = [] as TransportRequestItemsInputFields[];
        const concatFiles = [...filesUpload, ...nextFilesUpload];
        concatFiles.forEach(file => {
            if (!seenFiles.has({ file_name: file.file_name, module: file.module })) {
                filteredDuplicateFiles.push(file);
                seenFiles.add({ file_name: file.file_name, module: file.module });
            }
        });

        setFilesUpload([...filteredDuplicateFiles]);
    };

    const handleInputFileChange = <K extends keyof TransportRequestItemsInputFields>(
        fileUpload: TransportRequestItemsInputFields,
        key: K,
        value: TransportRequestItemsInputFields[K]
    ) => {
        const nextFilesUpload = [...filesUpload];
        const foundFileUpload = nextFilesUpload.find(f => f === fileUpload);

        if (!foundFileUpload) {
            console.warn('handleInputFileChange', 'foundFileUpload not found!');

            return;
        }

        foundFileUpload[key] = value;
        setFilesUpload(nextFilesUpload);
    };

    const handleInputFieldsChange = <K extends keyof TransportRequestHeaderInputFields>(
        key: K,
        value: TransportRequestHeaderInputFields[K]
    ) => {
        setInputHeaderFields(prevState => ({
            ...prevState,
            [key]: value
        }));

        setErrorFields(prevState => {
            const nextState = { ...prevState };
            delete nextState[key];

            return nextState;
        });
    };

    const handleValidateHeaderFields = () => {
        const requirefields: (keyof TransportRequestHeaderInputFields)[] = [
            'ticket_no',
            'detail',
            'impact',
            'risk_level'
        ];

        const nextErrorFields = requirefields.reduce((errorFields, field) => {
            if (inputHeaderFields[field] === '') {
                errorFields[field] = '';
            }

            return errorFields;
        }, {} as TransportRequestHeaderInputFields);

        setErrorFields(nextErrorFields);

        const isInvalid = Object.keys(nextErrorFields).some(field => nextErrorFields[field] === '');

        return !isInvalid;
    };

    const handleConfirmCreateTransportRequest = () => {
        const isCardFormsValid = cardsRef.current
            .map(ref => ref?.validateFields())
            .every(form => form);
        const isHeaderFormValid = handleValidateHeaderFields();
        console.log('isCardFormsValid', isCardFormsValid);

        if (!isHeaderFormValid || !isCardFormsValid) {
            console.log('invalid forms');
            return;
        }

        const owner = citizenId ?? 'No CitizenId from Client';

        const createTransportRequestModel = {} as CreateTransportRequest;

        createTransportRequestModel.FappFiles = filesUpload
            .filter(file => file.module.toUpperCase() === 'FAPP')
            .map(file => file.file);
        createTransportRequestModel.FguiFiles = filesUpload
            .filter(file => file.module.toUpperCase() === 'FGUI')
            .map(file => file.file);
        createTransportRequestModel.ScriptDbFiles = filesUpload
            .filter(file => file.module.toUpperCase() === 'SCRIPTDB')
            .map(file => file.file);

        createTransportRequestModel.TransportRequestHeader = {
            Ticket: inputHeaderFields.ticket_no,
            Developer: owner,
            Details: inputHeaderFields.detail,
            ServerTarget: 'UAT',
            Remark: inputHeaderFields.remark,
            Feature: inputHeaderFields.feature,
            Impact: inputHeaderFields.impact,
            RiskLevel: inputHeaderFields.risk_level,
            TransportRequestItems: filesUpload.map(
                item =>
                    ({
                        Module: item.module,
                        FileName: item.file_name,
                        CoreOrNonCore: item.core_or_noncore,
                        AddOrEdit: item.add_or_edit
                    }) as TransportRequestItem
            )
        };

        console.log('payload', createTransportRequestModel);

        handleConfirmCreate(createTransportRequestModel);
        handleCloseModal();
    };

    const handleCloseModal = useCallback(() => {
        // Clean-up states
        setFilesUpload(INITIAL_INPUT_ITEMS_FIELDS_STATE);
        setInputHeaderFields(INITIAL_INPUT_HEADER_FIELDS_STATE);
        setErrorFields({} as TransportRequestHeaderInputFields);

        onClose();
    }, [onClose]);

    const handleDeleteFile = (fileDetail: TransportRequestItemsInputFields, index: number) => {
        cardsRef.current.splice(index, 1);

        setFilesUpload((prevFileList) => prevFileList.filter(file => file.file_name !== fileDetail.file_name));
    }

    return (
        <Modal
            //isCentered
            size="6xl"
            isOpen={isOpen}
            onClose={handleCloseModal}
            closeOnOverlayClick={false}
            scrollBehavior="inside"
        >
            <ModalOverlay />
            <ModalContent className="h-screen">
                <ModalHeader className="border-b-2">Create TransportRequest</ModalHeader>
                <ModalCloseButton className="border hover:bg-red-300" />
                <ModalBody>
                    <div className="flex h-full flex-row">
                        <div className="flex h-full w-2/4 flex-1 flex-col gap-y-4 pr-2">
                            <div className="flex flex-col gap-y-2 rounded-md border border-neutral-400 p-2">
                                <div
                                    id="row-1"
                                    className="grid grid-cols-2 gap-x-2"
                                >
                                    <div className="flex flex-col gap-2">
                                        <div className="flex flex-col">
                                            <span className="text-sm font-medium">
                                                Ticket
                                                <span className="relative bottom-1 font-bold text-red-600">
                                                    *
                                                </span>
                                            </span>
                                            <Input
                                                name="ticket_no"
                                                size="sm"
                                                className="rounded-sm border-neutral-400 text-black"
                                                placeholder="Enter The Ticket Number"
                                                isInvalid={errorFields.ticket_no === ''}
                                                value={inputHeaderFields.ticket_no}
                                                errorBorderColor="crimson"
                                                onChange={e =>
                                                    handleInputFieldsChange(
                                                        'ticket_no',
                                                        e.target.value
                                                    )
                                                }
                                            />
                                        </div>
                                        <div className="flex flex-col">
                                            <span className="text-sm font-medium">
                                                Impact
                                                <span className="relative bottom-1 font-bold text-red-600">
                                                    *
                                                </span>
                                            </span>
                                            <Input
                                                name="impact"
                                                placeholder="กรุณาระบุผลกระทบ"
                                                size="sm"
                                                className="rounded-sm border-neutral-400 text-black"
                                                value={inputHeaderFields.impact}
                                                isInvalid={errorFields.impact === ''}
                                                errorBorderColor="crimson"
                                                onChange={e =>
                                                    handleInputFieldsChange(
                                                        'impact',
                                                        e.target.value
                                                    )
                                                }
                                            />
                                        </div>
                                        <div className="flex flex-col">
                                            <span className="text-sm font-medium">
                                                Risk Level
                                                <span className="relative bottom-1 font-bold text-red-600">
                                                    *
                                                </span>
                                            </span>
                                            <FormControl>
                                                <Select
                                                    name="risk_level"
                                                    className="rounded-sm border-neutral-400 text-black"
                                                    size="sm"
                                                    placeholder="Please choose one"
                                                    value={inputHeaderFields.risk_level}
                                                    isInvalid={errorFields.risk_level === ''}
                                                    errorBorderColor="crimson"
                                                    onChange={e =>
                                                        handleInputFieldsChange(
                                                            'risk_level',
                                                            e.target.value
                                                        )
                                                    }
                                                >
                                                    <option value="LOW">Low</option>
                                                    <option value="MEDIUM">Medium</option>
                                                    <option value="HIGH">High</option>
                                                </Select>
                                            </FormControl>
                                        </div>
                                    </div>
                                    <div className="flex flex-col">
                                        <span className="text-sm font-medium">
                                            Detail
                                            <span className="relative bottom-1 font-bold text-red-600">
                                                *
                                            </span>
                                        </span>
                                        <Textarea
                                            name="detail"
                                            size="sm"
                                            className="flex-1 rounded-sm border-neutral-400 text-black"
                                            placeholder="กรุณาระบุรายละเอียดงาน"
                                            value={inputHeaderFields.detail}
                                            isInvalid={errorFields.detail === ''}
                                            errorBorderColor="crimson"
                                            onChange={e =>
                                                handleInputFieldsChange('detail', e.target.value)
                                            }
                                        />
                                    </div>
                                </div>
                                <div
                                    id="row-2"
                                    className="grid grid-cols-2 gap-x-2"
                                >
                                    <div className="flex flex-col justify-center gap-2">
                                        <div className="flex flex-col">
                                            <span className="text-sm font-medium">Feature</span>
                                            <Textarea
                                                name="feature"
                                                size="sm"
                                                className="flex-1 rounded-sm border-neutral-400 text-black"
                                                placeholder="กรุณาระบุรายละของ Feature"
                                                value={inputHeaderFields.feature}
                                                onChange={e =>
                                                    handleInputFieldsChange(
                                                        'feature',
                                                        e.target.value
                                                    )
                                                }
                                            />
                                        </div>
                                    </div>
                                    <div className="flex flex-col justify-center gap-2">
                                        <div className="flex flex-col">
                                            <span className="text-sm font-medium">Remark</span>
                                            <Textarea
                                                name="remark"
                                                size="sm"
                                                className="flex-1 rounded-sm border-neutral-400 text-black"
                                                placeholder="หมายเหตุเพิ่มเติม"
                                                value={inputHeaderFields.remark}
                                                onChange={e =>
                                                    handleInputFieldsChange(
                                                        'remark',
                                                        e.target.value
                                                    )
                                                }
                                            />
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div
                                id="upload-file-btn"
                                className="flex flex-col gap-y-2"
                            >
                                <div className="flex flex-1 gap-x-2">
                                    <div className="flex w-1/2 flex-1 flex-col gap-y-2">
                                        <UploadFiles
                                            id="FAPP"
                                            label="FAPP"
                                            fileType=".dll"
                                            handleUploadFiles={(Files, Id) =>
                                                handleAddFiles(Id, Files)
                                            }
                                            className="flex items-center justify-center"
                                            fileUploadedLength={fileLengths.FAPP}
                                        />
                                        <UploadFiles
                                            id="FGUI"
                                            label="FGUI"
                                            fileType=".dll"
                                            handleUploadFiles={(Files, Id) =>
                                                handleAddFiles(Id, Files)
                                            }
                                            className="flex items-center justify-center"
                                            fileUploadedLength={fileLengths.FGUI}
                                        />
                                        <UploadFiles
                                            id="ScriptDB"
                                            label="ScriptDB"
                                            fileType=".sql"
                                            handleUploadFiles={(Files, Id) =>
                                                handleAddFiles(Id, Files)
                                            }
                                            className="flex items-center justify-center"
                                            fileUploadedLength={fileLengths.ScriptDB}
                                        />
                                    </div>
                                    <div className="flex w-1/2 flex-1 flex-col gap-y-2 rounded-md border border-neutral-400 px-3 py-2">
                                        <div className="flex flex-col items-end">
                                            <Badge className="text-neutral-900">Total Files</Badge>
                                            <span className="text-2xl font-semibold text-neutral-800">
                                                {fileLengths['*']}
                                            </span>
                                        </div>
                                        <div className="flex flex-col items-end">
                                            <Badge className="text-green-500">FAPP</Badge>
                                            <span className="text-2xl font-semibold text-neutral-800">
                                                {fileLengths.FAPP}
                                            </span>
                                        </div>
                                        <div className="flex flex-col items-end">
                                            <Badge className="text-teal-400">FGUI</Badge>
                                            <span className="text-2xl font-semibold text-teal-400">
                                                {fileLengths.FGUI}
                                            </span>
                                        </div>
                                        <div className="flex flex-col items-end">
                                            <Badge className="text-violet-600">Script DB</Badge>
                                            <span className="text-2xl font-semibold text-neutral-800">
                                                {fileLengths.ScriptDB}
                                            </span>
                                        </div>
                                    </div>
                                </div>
                                <span className="text-wrap text-right text-sm text-red-600">
                                    *กรุณาตรวจสอบไฟล์และ version ก่อนนำขึ้นระบบ
                                </span>
                            </div>
                        </div>
                        <div className="flex h-full w-2/4 flex-1 flex-col rounded-md border border-neutral-400">
                            <div className="overflow-y-auto p-2">
                                {filesUpload.map((fileUpload, index) => (
                                    <CardTransportRequest
                                        key={index}
                                        ref={ref => (cardsRef.current[index] = ref)}
                                        fileUpload={fileUpload}
                                        onInputFileChange={handleInputFileChange}
                                        handleDeleteFile={handleDeleteFile}
                                        index={index}
                                    />
                                ))}
                            </div>
                        </div>
                    </div>
                </ModalBody>
                <ModalFooter>
                    <Button
                        colorScheme="green"
                        onClick={handleConfirmCreateTransportRequest}
                    >
                        Confirm
                    </Button>
                </ModalFooter>
            </ModalContent>
        </Modal>
    );
}

export default observer(ModalCreateTransportRequest);
