import { AxiosError } from 'axios';

import { validateDataResponse } from '../../../commons/helpers';
import { getIndayById } from '../../datasources/v1/monitor';
import { MonitorServerResponse } from '../../schemas/monitor';
import { AdminServerState } from '../../../models/AdminServer';

export async function getMonitorServersIndayById(serverId: string): Promise<AdminServerState> {
    try {
        const response = await getIndayById(serverId);
        const result: MonitorServerResponse[] = validateDataResponse(response);

        const res: AdminServerState = {
            isLoadAdminServer: true,
            ResponseAdminServer: result
        };

        return res;
    } catch (error) {
        if (error instanceof AxiosError && error.response) {
            throw new Error(validateDataResponse(error.response).message);
        }

        throw error;
    }
}
