import { AxiosError, AxiosResponse } from 'axios';
import { HttpStatusCode } from '../../data/protocols/http';
import { InvalidCredentialsError, UnexpectedError } from '../../domain/errors';
import { ResponseError } from '../../models/common/Response.model';

export const validateDataResponse = (response: AxiosResponse) => {
    switch (response.status) {
        case HttpStatusCode.ok:
            return response.data;
        case HttpStatusCode.unauthorized:
            return new InvalidCredentialsError();
        case HttpStatusCode.badRequest:
            return response.data;
        default:
            return new UnexpectedError();
    }
};
