import { useCallback, useState } from 'react';
import { Button, FormControl, FormLabel, Input, Select } from '@chakra-ui/react';

import type { WebSocketRequest } from 'models/Migration';
import useMigrationViewModel from '../Migration.viewmodel';

type Props = {
    onClickConnect: (connection: WebSocketRequest) => void;
};

function ConnectionForm(props: Props) {
    const { onClickConnect } = props;

    const { scriptGroupDatabase } = useMigrationViewModel();

    const [connection, setConnection] = useState<WebSocketRequest>({
        username: '',
        script_group: '',
        client_action: 'connect',
        host: '',
        database: ''
    });

    const handleChangeInput = useCallback((event: React.ChangeEvent<HTMLInputElement>) => {
        setConnection(prevState => ({ ...prevState, [event.target.name]: event.target.value }));
    }, []);

    const handleClickConnect = useCallback(() => {
        onClickConnect(connection);
    }, [connection, onClickConnect]);

    return (
        <form className="flex items-end gap-2">
            <FormControl>
                <FormLabel className="text-sm">Script Group</FormLabel>
                <Select
                    size="sm"
                    name="script_group"
                    placeholder="Select script group"
                    value={connection.script_group}
                    onChange={
                        handleChangeInput as unknown as React.ChangeEventHandler<HTMLSelectElement>
                    }
                >
                    {scriptGroupDatabase &&
                        scriptGroupDatabase.map((value, index) => (
                            <option
                                key={index}
                                value={value.script_group}
                            >
                                {value.script_group}
                            </option>
                        ))}
                </Select>
            </FormControl>
            <FormControl>
                <FormLabel className="text-sm">Host</FormLabel>
                <Input
                    size="sm"
                    name="host"
                    value={connection.host}
                    onChange={handleChangeInput}
                />
            </FormControl>
            <FormControl>
                <FormLabel className="text-sm">Database</FormLabel>
                <Select
                    size="sm"
                    name="database"
                    placeholder="Database"
                    value={connection.database}
                    onChange={
                        handleChangeInput as unknown as React.ChangeEventHandler<HTMLSelectElement>
                    }
                >
                    {scriptGroupDatabase &&
                        scriptGroupDatabase.map((value, index) => (
                            <option
                                key={index}
                                value={value.database}
                            >
                                {value.database}
                            </option>
                        ))}
                </Select>
            </FormControl>
            <FormControl>
                <FormLabel className="text-sm">Username</FormLabel>
                <Input
                    size="sm"
                    name="username"
                    value={connection.username}
                    onChange={handleChangeInput}
                />
            </FormControl>
            <Button
                size="sm"
                onClick={handleClickConnect}
                className="min-w-24"
            >
                Connect
            </Button>
        </form>
    );
}

export default ConnectionForm;
