import { AxiosError } from 'axios';

import { getByDomain, getAllFappPorts } from '../../datasources/v1/monitor/monitorFapp.datasource';
import { validateDataResponse } from '../../../commons/helpers';
import { FappMonitor, FappMonitorState, FappConfig } from '../../../models/FappMonitor';

export async function getFappMonitorByDomain(params: {
    domain: string;
}): Promise<FappMonitorState> {
    try {
        const response = await getByDomain(params);
        const result: FappMonitor[] = validateDataResponse(response);

        const resultState: FappMonitorState = {
            isLoaded: true,
            payload: result
        };

        return resultState;
    } catch (error) {
        if (error instanceof AxiosError && error.response) {
            throw new Error(validateDataResponse(error.response).message);
        }

        throw error;
    }
}

export async function getFappPorts(params: {
    isPRD: boolean;
}): Promise<FappConfig[]> {
    try {
        const response = await getAllFappPorts(params);
        const result: FappConfig[] = validateDataResponse(response);

        return result;
    } catch (error) {
        if (error instanceof AxiosError && error.response) {
            throw new Error(validateDataResponse(error.response).message);
        }

        throw error;
    }
}
