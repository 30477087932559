import React from 'react';
import { Tabs, TabList, TabPanels, Tab, TabPanel } from '@chakra-ui/react';
import {
    Modal,
    ModalContent,
    ModalHeader,
    ModalBody,
    ModalFooter,
    ModalOverlay,
    ModalCloseButton
} from '@chakra-ui/react';
import ModalDeploy from './ModalDeploy';
import { TransportRequestTag, UpdateTransportRequestResult } from '../../../../data/schemas/TransportRequest';
import { FappConfig } from '../../../../models/FappMonitor';
import RunScriptDB from './RunScriptDB';

interface NavigatorProps {
    isOpenModal: boolean;
    toggleModal: () => void;
    selectedDeploy: TransportRequestTag;
    handleConfirmDeploy: (portList: string[], uatTag: string) => void;
    deployTransportRequestResult: UpdateTransportRequestResult,
    fappPorts: FappConfig[];
}

function NavigatorTransportRequest(props: NavigatorProps) {
    const { isOpenModal, toggleModal, deployTransportRequestResult, fappPorts, handleConfirmDeploy, selectedDeploy } = props;

    return (
        <React.Fragment>
            <Modal
                isOpen={isOpenModal}
                onClose={toggleModal}
                size="xl"
                closeOnOverlayClick={false}
                scrollBehavior="inside"
            >
                <ModalOverlay />
                <ModalContent className="min-w-[90vw] min-h-[85vh]">
                    <ModalHeader>Deploy TransportRequest</ModalHeader>
                    <ModalCloseButton />
                    <ModalBody className="flex max-h">
                        <Tabs isLazy variant='unstyled' className="min-w-full">
                            <TabList>
                                <Tab _selected={{ color: 'white', bg: 'blue.500' }}>Update FAPP</Tab>
                                <Tab _selected={{ color: 'white', bg: 'green.400' }} isDisabled>Run ScriptDB</Tab>
                            </TabList>
                            <TabPanels>
                                <TabPanel className="p-0">
                                    <ModalDeploy
                                        selectedDeploy={selectedDeploy}
                                        handleConfirmDeploy={handleConfirmDeploy}
                                        deployTransportRequestResult={deployTransportRequestResult}
                                        fappPorts={fappPorts}
                                    />
                                </TabPanel>
                                <TabPanel className="p-0">
                                    <RunScriptDB />
                                </TabPanel>
                            </TabPanels>
                        </Tabs>
                    </ModalBody>
                    <ModalFooter>
                    </ModalFooter>
                </ModalContent>
            </Modal>
        </React.Fragment>
    )
}

export default NavigatorTransportRequest;
