import {
    Modal,
    ModalBody,
    ModalCloseButton,
    ModalContent,
    ModalHeader,
    ModalOverlay
} from '@chakra-ui/react';
import { getSizeLogF1Api } from 'data/repositories/apiLog/f1ApiLog.repository';
import {
    F1ApiLogDeleteParams,
    F1ApiLogSizeParams,
    F1ApiLogSizeResponse
} from 'data/schemas/apiLog';
import { convertToYYYYMMDD } from 'helpers/formatDateTime';
import { useCallback, useEffect, useState } from 'react';
import DatePicker from 'react-datepicker';
import { CiCalendarDate } from 'react-icons/ci';

interface ModalProps {
    isOpenModal: boolean;
    onClose: () => void;
    selectdCompany: string;
    logSize: F1ApiLogSizeResponse;
    handleClearLog: () => void;
    handleChangeDeleteParams: <K extends keyof F1ApiLogDeleteParams>(
        key: K,
        value: F1ApiLogDeleteParams[K]
    ) => void;
}

function ModalDeleteLogApi(props: ModalProps) {
    const { isOpenModal, onClose, logSize, handleClearLog, handleChangeDeleteParams } = props;

    const handleClose = useCallback(() => {
        onClose();
    }, [onClose]);

    const [dateForm, setDateForm] = useState<Date>();
    const [dateTo, setDateTo] = useState<Date>();

    const handleDeleteLog = () => {
        handleClearLog();
    };

    return (
        <Modal
            scrollBehavior="inside"
            isCentered
            size="6xl"
            isOpen={isOpenModal}
            onClose={handleClose}
        >
            <ModalOverlay />
            <ModalContent>
                <ModalHeader>F1 Api Log</ModalHeader>
                <ModalCloseButton />
                <ModalBody className="p-4">
                    <div className="flex flex-row justify-evenly">
                        <div className="flex items-center gap-2">
                            <label>Created From: </label>
                            <DatePicker
                                showIcon
                                selected={dateForm}
                                showTimeSelect
                                timeFormat="HH:mm"
                                timeIntervals={10}
                                timeCaption="time"
                                dateFormat="MMMM d, yyyy h:mm aa"
                                onChange={date => {
                                    setDateForm(date!);
                                    handleChangeDeleteParams(
                                        'createdFrom',
                                        convertToYYYYMMDD(date?.toISOString()!)
                                    );
                                }}
                                className="rounded-md border"
                            />
                        </div>
                        <div className="flex items-center gap-2">
                            <label>Created To: </label>
                            <DatePicker
                                showIcon
                                selected={dateTo}
                                showTimeSelect
                                timeFormat="HH:mm"
                                timeIntervals={10}
                                timeCaption="time"
                                dateFormat="MMMM d, yyyy h:mm aa"
                                onChange={date => {
                                    setDateTo(date!);
                                    handleChangeDeleteParams(
                                        'createdTo',
                                        convertToYYYYMMDD(date?.toISOString()!)
                                    );
                                }}
                                className="rounded-md border"
                            />
                        </div>
                    </div>
                    <div className="flex justify-center">
                        <button
                            className="rounded-2xl border-2 px-4 py-2"
                            onClick={handleDeleteLog}
                        >
                            Clear
                        </button>
                    </div>
                    {logSize && (
                        <div className="flex flex-row justify-evenly">
                            <div className="flex gap-2">
                                <span>F1 Log Rows:</span>
                                <span>{logSize.rows} Rows</span>
                            </div>
                            <div className="flex gap-2">
                                <span>F1 Log Size:</span>
                                <span>{logSize.totalSpaceMb} MB</span>
                            </div>
                        </div>
                    )}
                </ModalBody>
            </ModalContent>
        </Modal>
    );
}

export default ModalDeleteLogApi;
