import { AxiosError } from 'axios';

import { validateDataResponse } from '../../../commons/helpers';
import { getGoogleMetrics } from 'data/datasources/v1/google';
import { MetricMonitor } from '../../../models/Google'
import { GoogleRequest } from 'data/schemas/google';


export async function googleMetrics(params: GoogleRequest): Promise<MetricMonitor> {
    try {
        const response = await getGoogleMetrics(params);
        const result: MetricMonitor = validateDataResponse(response);

        return result;
    } catch (error) {
        if (error instanceof AxiosError && error.response) {
            throw new Error(validateDataResponse(error.response).message);
        }

        throw error;
    }
}