import { makeAutoObservable, runInAction } from 'mobx';

import RootStore from '../Root.store';
import axios, { AxiosError } from 'axios';
import { FTPMonitor } from '../../models/FTPMonitor';

class FTPMonitorStore {
    public fetching = false;
    public ftpMonitors?: FTPMonitor[] = undefined;

    constructor(private rootStore: RootStore) {
        makeAutoObservable(this);
    }

    public async fetchFTPMonitors() {
        try {
            this.fetching = true;

            const response = await axios.get<FTPMonitor[]>('/v1/monitor/ftps', {
                headers: {
                    Authorization: 'Bearer ' + localStorage.getItem('MM_APPLICATION')
                }
            });
            const ftpMonitors = response.data.sort((a, b) =>
                a.name < b.name ? -1 : a.name > b.name ? 1 : 0
            );

            runInAction(() => (this.ftpMonitors = ftpMonitors));
        } catch (error) {
            console.error('fetchFTPMonitors', (error as AxiosError).response?.data);

            throw error;
        } finally {
            this.fetching = false;
        }
    }
}

export default FTPMonitorStore;
