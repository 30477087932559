import axios from 'axios';

import { MonitorServerResponse } from '../../../schemas/monitor';

export async function getAll() {
    return axios.get<MonitorServerResponse[]>('v1/monitor/servers', {
        headers: {
            Authorization: 'Bearer ' + localStorage.getItem('MM_APPLICATION')
        }
    });
}

export async function getById(serverId: string) {
    return axios.get<MonitorServerResponse[]>('v1/monitor/server/' + serverId, {
        headers: {
            Authorization: 'Bearer ' + localStorage.getItem('MM_APPLICATION')
        }
    });
}
