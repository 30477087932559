export const fetchWithTimeout = async (
    url: RequestInfo,
    { timeout = 300000, ...options }: RequestInit & { timeout?: number }
) => {
    const controller = new AbortController();
    const id = setTimeout(() => controller.abort(), timeout);

    try {
        const response = await fetch(url, {
            ...options,
            signal: controller.signal
        });

        if (!response.ok) {
            const error = await response.json();
            throw error;
        }

        clearTimeout(id);
        return response;
    } catch (error) {
        clearTimeout(id);

        throw error;
    }
};
