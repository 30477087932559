import { AxiosError } from 'axios';
import { validateDataResponse } from '../../../commons/helpers';
import { RunCommandResponse } from '../../../models/AdminServer';
import { runCommand } from '../../datasources/v1/fapp';
import { FappUpdateRequest } from '../../schemas/fapp';

export async function updateFappOrWebWithCommand(
    params: FappUpdateRequest
): Promise<RunCommandResponse[]> {
    try {
        const response = await runCommand(params);
        const result: RunCommandResponse[] = validateDataResponse(response);

        return result;
    } catch (error) {
        if (error instanceof AxiosError && error.response) {
            throw new Error(validateDataResponse(error.response).message);
        }

        throw error;
    }
}
