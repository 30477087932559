import { Button, Select, Input, InputGroup, InputLeftAddon } from '@chakra-ui/react';
import { memo, Dispatch } from 'react';

import 'react-datepicker/dist/react-datepicker.css';
type Props = {
    onClickExport: () => void;
    setSearcFromShowDisplay: Dispatch<React.SetStateAction<string>>;
    setSearcToShowDisplay: Dispatch<React.SetStateAction<string>>;
    monitorName: string;
};

function ActionPane(props: Props) {
    const { onClickExport, setSearcFromShowDisplay, setSearcToShowDisplay, monitorName } = props;

    const handleSelectChange = (event: React.ChangeEvent<HTMLSelectElement>) => {
        const select = event.target.value;
        const currentTime = new Date();
        const formatCurrentTime = formatTime(currentTime);
        setSearcToShowDisplay(formatCurrentTime);
        switch (select) {
            case '1':
                setSearcFromShowDisplay(
                    formatTime(currentTime.setMinutes(currentTime.getMinutes() - 15))
                );
                break;
            case '2':
                setSearcFromShowDisplay(
                    formatTime(currentTime.setMinutes(currentTime.getMinutes() - 30))
                );
                break;
            case '3':
                setSearcFromShowDisplay(
                    formatTime(currentTime.setHours(currentTime.getHours() - 1))
                );
                break;
            case '4':
                setSearcFromShowDisplay(
                    formatTime(currentTime.setHours(currentTime.getHours() - 3))
                );
                break;
            case '5':
                setSearcFromShowDisplay(
                    formatTime(currentTime.setHours(currentTime.getHours() - 6))
                );
                break;
            case '6':
                setSearcFromShowDisplay(
                    formatTime(currentTime.setHours(currentTime.getHours() - 12))
                );
                break;
            case '7':
                setSearcFromShowDisplay(formatTime(currentTime.setDate(currentTime.getDate() - 1)));
                break;
            case '8':
                setSearcFromShowDisplay(formatTime(currentTime.setDate(currentTime.getDate() - 2)));
                break;
            case '9':
                setSearcFromShowDisplay(formatTime(currentTime.setDate(currentTime.getDate() - 7)));
                break;
            case '10':
                setSearcFromShowDisplay(
                    formatTime(currentTime.setDate(currentTime.getDate() - 14))
                );
                break;
            case '11':
                setSearcFromShowDisplay(
                    formatTime(currentTime.setMonth(currentTime.getMonth() - 1))
                );
                break;
        }
    };
    const formatTime = time => {
        const date = new Date(time);
        const year = date.getFullYear();
        const month = (date.getMonth() + 1).toString().padStart(2, '0');
        const day = date.getDate().toString().padStart(2, '0');
        const hours = date.getHours().toString().padStart(2, '0');
        const minutes = date.getMinutes().toString().padStart(2, '0');
        const seconds = date.getSeconds().toString().padStart(2, '0');

        const formattedTime = `${year}${month}${day}${hours}${minutes}${seconds}`;
        return formattedTime;
    };

    return (
        <div className="flex justify-end gap-2">
            <InputGroup>
                <InputLeftAddon>Monitor Name</InputLeftAddon>
                <Input
                    value={monitorName}
                    readOnly
                    width="200"
                    className="placeholder-center text-center"
                />
            </InputGroup>

            <Select
                placeholder="Relative Time (15m, 1h, 1d, 1w)"
                width="300px"
                onChange={handleSelectChange}
            >
                <option value="1">Last 15 minutes</option>
                <option value="2">Last 30 minutes</option>
                <option value="3">Last 1 hours</option>
                <option value="4">Last 3 hours</option>
                <option value="5">Last 6 hours</option>
                <option value="6">Last 12 hours</option>
                <option value="7">Last 1 day</option>
                <option value="8">Last 2 days</option>
                <option value="9">Last 7 days</option>
                <option value="10">Last 14 days</option>
                <option value="11">Last 1 month</option>
            </Select>

            <Button onClick={onClickExport}>Export</Button>
        </div>
    );
}

export default memo(ActionPane);
