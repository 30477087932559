export function dbDateTimeToDisplayString(dbDateTimeString: string) {
    // Ex. 2022-06-02T18:03:49.302+07:00 to dd/MM/yyyy hh:mm:ss

    const updatedOnArr = dbDateTimeString.split('T');
    let updatedDate = updatedOnArr[0];
    let updatedTime = updatedOnArr[1].substring(0, 8);

    const date = updatedDate.substring(8, 10);
    const month = updatedDate.substring(5, 7);
    const year = updatedDate.substring(0, 4);
    updatedDate = `${date}/${month}/${year}`;

    return updatedDate + ' ' + updatedTime;
}

export function getDbDateTime() {
    const today = new Date();
    const year = today.getFullYear();
    const month = ('0' + (today.getMonth() + 1)).slice(-2);
    const day = ('0' + today.getDate()).slice(-2);

    return year + month + day;
}
