import React, { useEffect, useState } from 'react';
import { BsFillGearFill } from "react-icons/bs";
import { SiChakraui } from "react-icons/si";
import { RouteComponentProps, useHistory } from "react-router-dom";
import ReactDatatable from '@ashvin27/react-datatable';
import { Modal, ModalHeader, ModalBody, ModalFooter, Button, Input, Progress } from "reactstrap";
import ReactTagInput from "@pathofdev/react-tag-input";
import "@pathofdev/react-tag-input/build/index.css";

import { AiOutlineReload } from "react-icons/ai";
import Swal from "sweetalert2";
import "../assets/style/style.css"

import Loader from './loader/ContentLoader'
import { configdatatable } from './configdatatable/Config';

import { connect } from 'react-redux';
import { ApplicationState } from '../store';


import * as AdminWebStore from '../store/AdminWeb';
import * as AdminWebModels from '../models/AdminWeb';





type adminPropsaction = AdminWebModels.AdminWebState & typeof AdminWebStore.actionCreators

const QuickBox = ({ title, children }) => {
    return (
        <div className="QuickBox">
            <p>{title} : </p>
            <p>{children}</p>
        </div>
    );
}

const AdminDashboardWeb = (adminProps: adminPropsaction,) => {
    let history = useHistory();

    const [modalIsOpen, setModalIsOpen] = useState(false);
    const toggle = () => setModalIsOpen(!modalIsOpen);

    const [refresh, setrefresh] = useState(false);
    const togglerefresh = () => setrefresh(!refresh);

    const [modalId, setModalId] = useState('');
    const [modalGroup, setModalGroup] = useState('');
    const [modalType, setModalmodalType] = useState('');
    const [modalName, setModalmodalName] = useState('');
    const [modalIpaddress, setModalIpaddress] = useState('');

    useEffect(() => {
        console.log("เริ่ม")
        adminProps.requestGetAdminWeb(100);
    }, [refresh]);



    const handleClick = (Manage) => {
        history.push("/Manageadmindashboard?id=" + Manage);

    }

    const editdata = () => {

        console.log(modalId)
        console.log(modalGroup)
        console.log(modalType)
        console.log(modalName)
        console.log(modalIpaddress)

        Swal.fire({
            title: 'คุณแน่ใจไหม ?', text: "ในการแก้ไขข้อมูล!", icon: 'warning', showCancelButton: true,
            confirmButtonColor: '#3085d6', cancelButtonColor: '#d33', cancelButtonText: 'ยกเลิก', confirmButtonText: 'ยืนยัน'
        }).then((result) => {
            //console.log(result)
            if (result.isConfirmed === true) {
                //console.log(Neweditport);
                const editadminwebconfig = {
                    method: 'PUT',
                    headers: { 'Content-Type': 'application/json' },
                    body: JSON.stringify({
                        "id": modalId,
                        "group": modalGroup,
                        "type": modalType,
                        "name": modalName,
                        "domain": modalIpaddress,
                        "created_by": ""
                    })
                };
                fetch(`/v1/config/web/edit/`, editadminwebconfig)
                    .then((response) => {
                        return response
                    })
                    .then((data) => {
                        //console.log(data)
                        if (!data.ok) {
                            Swal.fire('ไม่สำเร็จ!', '', 'error')
                        } else {
                            Swal.fire('สำเร็จ!', '', 'success')
                            setModalIsOpen(false);
                            let timer = setTimeout(() => togglerefresh(), 2000)
                        }
                    })
            }
        })

    }

    const deletedata = () => {
        console.log(modalId)
        Swal.fire({
            title: 'คุณแน่ใจไหม ?', text: "ในลบข้อมูล!", icon: 'warning', showCancelButton: true,
            confirmButtonColor: '#3085d6', cancelButtonColor: '#d33', cancelButtonText: 'ยกเลิก', confirmButtonText: 'ยืนยัน'
        }).then((result) => {
            if (result.isConfirmed === true) {
                console.log(result)
                fetch(`/v1/config/web/delete/` + modalId, {
                    method: 'DELETE'
                })
                    .then((response) => {
                        return response
                    })
                    .then((data) => {
                        console.log(data)
                        if (!data.ok) {
                            Swal.fire('ไม่สำเร็จ!', '', 'error')
                        } else {
                            Swal.fire('สำเร็จ!', '', 'success')
                            setModalIsOpen(false);
                            let timer = setTimeout(() => togglerefresh(), 2000)
                        }
                    })
            }
        })



    }


    const modal = (index) => {

        setModalId(adminProps.ResponseAdminWeb[index - 1].id)
        setModalGroup(adminProps.ResponseAdminWeb[index - 1].group);
        setModalmodalType(adminProps.ResponseAdminWeb[index - 1].type);
        setModalmodalName(adminProps.ResponseAdminWeb[index - 1].name);
        setModalIpaddress(adminProps.ResponseAdminWeb[index - 1].domain);
        setModalIsOpen(true)
    }

    const columns = [
        {
            key: "status", text: "Status", className: "Status", sortable: true,
            cell: (record, index) => {
                return (<SiChakraui style={{ color: (record.status === "false") ? 'red' : 'green' }} />);
            }
        },
        {
            key: "group", text: "Group", className: "Group", sortable: true
        },
        {
            key: "type", text: "Type", className: "Type", sortable: true
        },
        {
            key: "name", text: "Name", className: "Name", sortable: true
        },
        {
            key: "domain", text: "Ipaddress", className: "Ipaddress",
        },
        {
            key: "latency", text: "Latency", className: "Latency", sortable: true,
            cell: (record, index) => {
                return (
                    <div>{record.latency} ms</div>
                );
            }
        },
        {
            key: "manage", text: "Manage", className: "Manage",
            cell: (record, index) => {
                return (
                    <div style={{ textAlign: 'center', cursor: 'pointer' }}>
                        <BsFillGearFill onClick={() => modal(index + 1)} />
                    </div>
                );
            }
        }

    ];

    return (

        <div >
            {(adminProps.isLoadAdminWeb === true)
                ? <div className="totalserver" style={{ fontSize: '14px' }}>
                    <div className="total">
                        <div style={{ marginRight: '9px' }}>
                            <Button style={{ padding: '0.9px' }} color="primary" onClick={togglerefresh}>
                                Refresh<AiOutlineReload />
                            </Button>
                        </div>

                        <QuickBox title="Total Server ">
                            &nbsp;{adminProps.ResponseAdminWeb.length}&nbsp;
                        </QuickBox>
                        <QuickBox title="ON">
                            <span style={{ color: 'green' }}> &nbsp;{adminProps.ResponseAdminWeb.filter(run => run.status === "true").length} &nbsp;</span>
                        </QuickBox>
                        <QuickBox title="OFF">
                            <span style={{ color: 'red' }}> &nbsp;{adminProps.ResponseAdminWeb.filter(item => item.status === "false").length}&nbsp;</span>
                        </QuickBox>
                    </div>
                    <ReactDatatable
                        className="table table-striped table-hover"
                        tHeadClassName="custom-header-style"
                        config={configdatatable}
                        records={adminProps.ResponseAdminWeb}
                        columns={columns} />

                    <form>
                        <Modal isOpen={modalIsOpen} toggle={toggle} fullscreen="sm" centered
                            size="xl" >
                            <ModalHeader toggle={toggle}>Manage AdminApplicationServer</ModalHeader>
                            <ModalBody>
                                <div className="row">
                                    <div className="col-2">
                                        Group:
                                        <Input value={modalGroup} name="firstName" onChange={e => setModalGroup(e.target.value)} />
                                    </div>
                                    <div className="col-2">
                                        Type:
                                        <Input value={modalType} name="firstName" onChange={e => setModalmodalType(e.target.value)} />
                                    </div>
                                    <div className="col-3">
                                        Name:
                                        <Input value={modalName} name="firstName" onChange={e => setModalmodalName(e.target.value)} />
                                    </div>
                                    <div className="col-5">
                                        Ip address:
                                        <Input value={modalIpaddress} name="firstName" onChange={e => setModalIpaddress(e.target.value)} />
                                    </div>
                                </div>
                            </ModalBody>

                            <ModalFooter style={{ justifyContent: 'flex-start' }}>
                                <Button className="btn btn-danger" onClick={deletedata}>delete</Button>
                                <div style={{ marginLeft: 'auto', }}>
                                    <Button className="btn btn-primary" style={{ marginRight: '5px' }} onClick={editdata}>OK</Button>
                                    <Button className="btn btn-primary" onClick={toggle}>Close</Button>
                                </div>

                            </ModalFooter>
                        </Modal>
                    </form>



                </div>

                : <div className="Loader">
                    <Loader />
                </div>
            }
        </div >
    );

}
export default connect(
    (state: ApplicationState) => state.adminweb,
    AdminWebStore.actionCreators
)(AdminDashboardWeb as any);
