import './UpdateLog.css';
import React, { useState } from 'react';
import 'bootstrap/dist/css/bootstrap.min.css';
import {
    Button,
    Card,
    CardHeader,
    Col,
    Form,
    FormGroup,
    Input,
    Label,
    Row,
    Collapse,
    CardBody,
    Spinner,
    Modal,
    ModalBody,
    ModalHeader,
    ModalFooter
} from 'reactstrap';
import { BiAddToQueue } from 'react-icons/bi';

export default function UpdateLog() {
    const [basicModal, setBasicModal] = useState(false);

    const toggleShow = () => setBasicModal(!basicModal);

    const [OpenModal, setOpenModal] = useState(false);
    const toggleShows = () => setOpenModal(true);
    const CloseModal = () => setOpenModal(false);

    return (
        <div className="card">
            <CardHeader>
                <Row>
                    <Col>
                        {' '}
                        <h5>Update Log</h5>
                    </Col>

                    <Col className="text-right">
                        <BiAddToQueue
                            style={{
                                cursor: 'pointer',
                                transform: 'rotate(-150deg)',
                                transition: 'all 0.2s ease'
                            }}
                            onClick={toggleShows}
                        />
                    </Col>
                </Row>
            </CardHeader>

            <i className="bi bi-plus-square-fill"></i>
            <CardBody>
                <div className="row">
                    <div className="col">
                        <label htmlFor="exampleDataList" className="form-label">
                            TAG UAT
                        </label>
                        <input
                            className="form-control"
                            list="datalistOptions"
                            id="tag_uat"
                            placeholder=""
                        />
                    </div>
                    <div className="col">
                        <label htmlFor="exampleDataList" className="form-label">
                            Devaloper
                        </label>
                        <input
                            className="form-control"
                            list="datalistOptions"
                            id="devaloper"
                            placeholder=""
                        />
                    </div>
                    <div className="col">
                        <label htmlFor="exampleDataList" className="form-label">
                            Module
                        </label>
                        <input
                            className="form-control"
                            list="datalistOptions"
                            id="module"
                            placeholder=""
                        />
                    </div>
                    <div className="col">
                        <label htmlFor="exampleDataList" className="form-label">
                            Development Oject
                        </label>
                        <input
                            className="form-control"
                            list="datalistOptions"
                            id="development"
                            placeholder=""
                        />
                    </div>
                </div>
                <br />
                <div className="row">
                    <div className="col">
                        <label htmlFor="exampleDataList" className="form-label">
                            Version
                        </label>
                        <input
                            className="form-control"
                            list="datalistOptions"
                            id="tag_uat"
                            placeholder=""
                        />
                    </div>
                    <div className="col">
                        <label htmlFor="exampleDataList" className="form-label">
                            Details
                        </label>
                        <input
                            className="form-control"
                            list="datalistOptions"
                            id="devaloper"
                            placeholder=""
                        />
                    </div>
                    <div className="col">
                        <label htmlFor="exampleDataList" className="form-label">
                            Deploy By
                        </label>
                        <input
                            className="form-control"
                            list="datalistOptions"
                            id="module"
                            placeholder=""
                        />
                    </div>
                    <div className="col">
                        <label htmlFor="exampleDataList" className="form-label">
                            Deploy Data
                        </label>
                        <input
                            className="form-control"
                            list="datalistOptions"
                            id="development"
                            placeholder=""
                        />
                    </div>

                    <div className="col">
                        <label htmlFor="exampleDataList" className="form-label">
                            Deploy Time
                        </label>
                        <input
                            className="form-control"
                            list="datalistOptions"
                            id="development"
                            placeholder=""
                        />
                    </div>
                </div>
                <br />

                <a href="#" className="btn btn-primary">
                    Search
                </a>
            </CardBody>
            <div>
                <Modal
                    isOpen={OpenModal}
                    toggle={function noRefCheck() {}}
                    style={{ maxWidth: '1000px' }}
                >
                    <ModalHeader toggle={CloseModal}>Modal title</ModalHeader>
                    <ModalBody>
                        <div className="row">
                            <div className="col">
                                <label htmlFor="exampleDataList" className="form-label">
                                    TAG UAT
                                </label>
                                <input
                                    className="form-control"
                                    list="datalistOptions"
                                    id="tag_uat"
                                    placeholder=""
                                />
                            </div>
                            <div className="col">
                                <label htmlFor="exampleDataList" className="form-label">
                                    Devaloper
                                </label>
                                <input
                                    className="form-control"
                                    list="datalistOptions"
                                    id="devaloper"
                                    placeholder=""
                                />
                            </div>
                            <div className="col">
                                <label htmlFor="exampleDataList" className="form-label">
                                    Module
                                </label>
                                <input
                                    className="form-control"
                                    list="datalistOptions"
                                    id="module"
                                    placeholder=""
                                />
                            </div>
                            <div className="col">
                                <label htmlFor="exampleDataList" className="form-label">
                                    Development Oject
                                </label>
                                <input
                                    className="form-control"
                                    list="datalistOptions"
                                    id="development"
                                    placeholder=""
                                />
                            </div>
                        </div>
                        <br />
                        <div className="row">
                            <div className="col">
                                <label htmlFor="exampleDataList" className="form-label">
                                    Version
                                </label>
                                <input
                                    className="form-control"
                                    list="datalistOptions"
                                    id="tag_uat"
                                    placeholder=""
                                />
                            </div>
                            <div className="col">
                                <label htmlFor="exampleDataList" className="form-label">
                                    Details
                                </label>
                                <input
                                    className="form-control"
                                    list="datalistOptions"
                                    id="devaloper"
                                    placeholder=""
                                />
                            </div>
                            <div className="col">
                                <label htmlFor="exampleDataList" className="form-label">
                                    Deploy By
                                </label>
                                <input
                                    className="form-control"
                                    list="datalistOptions"
                                    id="module"
                                    placeholder=""
                                />
                            </div>
                            <div className="col">
                                <label htmlFor="exampleDataList" className="form-label">
                                    Deploy Data
                                </label>
                                <input
                                    className="form-control"
                                    list="datalistOptions"
                                    id="development"
                                    placeholder=""
                                />
                            </div>

                            <div className="col">
                                <label htmlFor="exampleDataList" className="form-label">
                                    Deploy Time
                                </label>
                                <input
                                    className="form-control"
                                    list="datalistOptions"
                                    id="development"
                                    placeholder=""
                                />
                            </div>
                        </div>
                    </ModalBody>
                    <ModalFooter>
                        <Button color="primary" onClick={function noRefCheck() {}}>
                            Do Something
                        </Button>{' '}
                        <Button onClick={function noRefCheck() {}}>Cancel</Button>
                    </ModalFooter>
                </Modal>
            </div>
        </div>
    );
}
