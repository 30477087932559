import { makeAutoObservable, runInAction } from 'mobx';

import RootStore from '../Root.store';
import {
    getMonitorServerDetailsByServerId,
    getMonitorServerConfigs
} from '../../data/repositories/monitor/monitorServer.repository';
import { AdminServerState, DataAdminServer } from '../../models/AdminServer';

class ServerConfigStore {
    private _monitorServers: AdminServerState = {
        isLoadAdminServer: false,
        ResponseAdminServer: []
    };

    private _selectedServer: DataAdminServer | null = null;

    public get monitorServers() {
        return this._monitorServers;
    }

    public get selectedServer() {
        return this._selectedServer;
    }

    constructor(private rootStore: RootStore) {
        makeAutoObservable(this);
    }

    public async fetchMonitorServers(callback?: (response: AdminServerState) => void) {
        try {
            const monitorServerConfigs = await getMonitorServerConfigs();

            if (callback) {
                callback(monitorServerConfigs);
            }

            monitorServerConfigs.ResponseAdminServer.sort((a, b) =>
                a.name < b.name ? -1 : a.name > b.name ? 1 : 0
            );

            runInAction(() => {
                this._monitorServers = monitorServerConfigs;
            });
        } catch (error) {
            throw error;
        }
    }

    public async fetchMonitorServersById(serverId: string) {
        try {
            const monitorServersById = await getMonitorServerDetailsByServerId(serverId);

            runInAction(() => {
                this._monitorServers = monitorServersById;
            });
        } catch (error) {
            throw error;
        }
    }

    public setSelectedServer(serverId: string | DataAdminServer) {
        if (typeof serverId !== 'string') {
            runInAction(() => {
                this._selectedServer = serverId;
            });

            return;
        }

        if (this._monitorServers.ResponseAdminServer.length === 0) {
            throw new Error('Server Config no contents.');
        }

        const selectedMonitorServer = this._monitorServers.ResponseAdminServer.find(
            r => r.id === serverId
        );
        if (!selectedMonitorServer) {
            throw new Error(`${serverId} : this server id not found.`);
        }

        runInAction(() => {
            this._selectedServer = selectedMonitorServer;
        });
    }
}

export default ServerConfigStore;
