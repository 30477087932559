import React from 'react';

import AdminDashboardControlViewModel from './AdminDashboardControl.viewmodel';
import { Input, Nav, NavItem, NavLink, TabContent, TabPane } from 'reactstrap';

import '../../../assets/style/style.css';
import ServerConfigModalComponent from './components/ServerConfigModalComponent';
import GitUpdateComponent from './components/GitUpdateComponent';
import SvnUpdateComponent from './components/SvnUpdateComponent';
import CommandVerificationModalComponent from './components/CommandVerificationModalComponent';

const AdminDashboardControl = () => {
    const {
        monitorServer,
        isOpenModal,
        toggleModal,
        selectedToolTab,
        onSelectToolTab,
        onChangeServerConfig,
        onClickSaveServerConfig,
        serverConfig,
        onSelectPort,
        portOptions,
        onClickCommandBtn,
        outputLog,
        onChangeSvnRevision,
        svnRevision,
        commandConfigsMaster,
        isOpenIdentityModal,
        toggleIdentityModal,
        commandRequest,
        onClickConfirmRunCommand,
        onChangeCommandVerify,
        inputErrors
    } = AdminDashboardControlViewModel();

    return (
        <>
            {monitorServer && (
                <div className="container">
                    <h5 className="headTBox">Manage AdminServer</h5>
                    <div className="headerbody">
                        <div className="row">
                            <div className="col-2">
                                Group:
                                <Input
                                    value={monitorServer.group}
                                    readOnly
                                    onClick={toggleModal}
                                    style={{ cursor: 'pointer' }}
                                />
                            </div>
                            <div className="col-2">
                                Type:
                                <Input
                                    value={monitorServer.type}
                                    readOnly
                                    onClick={toggleModal}
                                    style={{ cursor: 'pointer' }}
                                />
                            </div>
                            <div className="col-3">
                                Name:
                                <Input
                                    value={monitorServer.name}
                                    readOnly
                                    onClick={toggleModal}
                                    style={{ cursor: 'pointer' }}
                                />
                            </div>
                            <div className="col-5">
                                Ip address:
                                <Input
                                    value={monitorServer.domain}
                                    readOnly
                                    onClick={toggleModal}
                                    style={{ cursor: 'pointer' }}
                                />
                            </div>
                        </div>
                    </div>
                    <br />

                    {/* <h5 className="headTBox">Version Control</h5> */}
                    {/* <label>Version Control Path</label> */}
                    {/* <Input /> */}
                    <br />

                    {/* Update FAPP or Web */}
                    <div className="row">
                        {/* Select tool */}
                        <div className="col-sm-4">
                            <label className="headTBox">
                                Version Control Tool
                            </label>
                            <Nav className="nav nav-pills">
                                <NavItem>
                                    <NavLink
                                        style={{ cursor: 'pointer' }}
                                        className={
                                            selectedToolTab.tabNumber === 1
                                                ? 'active'
                                                : ''
                                        }
                                        onClick={() =>
                                            onSelectToolTab({
                                                tabNumber: 1,
                                                tabName: 'SVN'
                                            })
                                        }
                                    >
                                        SVN (Web)
                                    </NavLink>
                                    <NavLink
                                        style={{ cursor: 'pointer' }}
                                        className={
                                            selectedToolTab.tabNumber === 2
                                                ? 'active'
                                                : ''
                                        }
                                        onClick={() =>
                                            onSelectToolTab({
                                                tabNumber: 2,
                                                tabName: 'GIT'
                                            })
                                        }
                                    >
                                        GIT (FAPP, Web)
                                    </NavLink>
                                </NavItem>
                            </Nav>
                        </div>

                        {/* Fire command */}
                        <div className="col-sm-8">
                            <TabContent activeTab={selectedToolTab.tabNumber}>
                                {selectedToolTab.tabNumber === 1 && (
                                    <TabPane tabId={1}>
                                        <SvnUpdateComponent
                                            ports={portOptions}
                                            onSelectPort={onSelectPort}
                                            onClickRunCommand={
                                                onClickCommandBtn
                                            }
                                            output={outputLog}
                                            onChangeRevision={
                                                onChangeSvnRevision
                                            }
                                            revision={svnRevision}
                                            commandConfigsMaster={
                                                commandConfigsMaster
                                            }
                                        />
                                    </TabPane>
                                )}
                                {selectedToolTab.tabNumber === 2 && (
                                    <TabPane tabId={2}>
                                        <GitUpdateComponent
                                            ports={portOptions}
                                            onSelectPort={onSelectPort}
                                            onClickRunCommand={
                                                onClickCommandBtn
                                            }
                                            output={outputLog}
                                            commandConfigsMaster={
                                                commandConfigsMaster
                                            }
                                        />
                                    </TabPane>
                                )}
                            </TabContent>
                        </div>
                    </div>

                    {/* Edit Server Config */}
                    <form>
                        <ServerConfigModalComponent
                            isOpenModal={isOpenModal}
                            toggleModal={toggleModal}
                            onChangeServerConfig={onChangeServerConfig}
                            onClickSaveServerConfig={onClickSaveServerConfig}
                            serverConfigInfo={serverConfig}
                        />
                    </form>

                    {/* Pre confirm Update Web or Fapp */}
                    <form>
                        <CommandVerificationModalComponent
                            isOpenModal={isOpenIdentityModal}
                            toggleModal={toggleIdentityModal}
                            commandInfo={commandRequest}
                            runCommand={onClickConfirmRunCommand}
                            onChangeCommandInfo={onChangeCommandVerify}
                            inputErrors={inputErrors}
                        />
                    </form>
                </div>
            )}
        </>
    );
};

export default AdminDashboardControl;
