import AdminDashboardTableComponent from './AdminDashboardTableComponent';
import { useLoader, useStore } from '../../../../commons/hooks';
import { useEffect } from 'react';
import { fappMonitorColumns } from '../data/fappMonitorColumns';

function FAPPMonitorDashboard() {
    const loader = useLoader();
    const { fappMonitorStore } = useStore();
    const { fappMonitors } = fappMonitorStore;

    useEffect(() => {
        if (fappMonitors && fappMonitors.length > 0) {
            return;
        }

        fetchFAPPMonitors();
    }, [fappMonitors]);

    const fetchFAPPMonitors = async () => {
        try {
            loader.show();

            await fappMonitorStore.fetchFAPPMonitors();
        } catch {
        } finally {
            loader.hide();
        }
    };

    return (
        <AdminDashboardTableComponent
            columns={fappMonitorColumns}
            data={fappMonitors ?? []}
            onCheckOnlineStatus={record => record.status}
            handleClickEvent={() => void 0}
            onToggleSwitch={() => void 0}
            isAutoRefresh={false}
            toggleRefresh={fetchFAPPMonitors}
        />
    );
}

export default FAPPMonitorDashboard;
