import { AxiosError } from "axios";
import { CheckDllVersionParams, CheckDllVersionResponse } from "./listDll.schema";
import { validateDataResponse } from "commons/helpers";
import { checkedDllVersion, updatedDllVersion } from "./listDll.datasource";
import { TransportRequestTag } from "data/TransportRequest";

export async function checkDllVersion(params: CheckDllVersionParams) {
    try {
        const response = await checkedDllVersion(params);
        const result: CheckDllVersionResponse = validateDataResponse(response)

        return result;
    } catch (error) {
        if (error instanceof AxiosError && error.response) {
            throw new Error(validateDataResponse(error.response).message);
        }

        throw error;
    }
}

export async function updateDllVersion(body: TransportRequestTag) {
    try {
        const response = await updatedDllVersion(body);
        const result: string = validateDataResponse(response)

        return result;
    } catch (error) {
        if (error instanceof AxiosError && error.response) {
            throw new Error(validateDataResponse(error.response).message);
        }

        throw error;
    }
}