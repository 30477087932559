import { makeAutoObservable, runInAction } from 'mobx';

import { ResponseList } from 'models/Common';

import RootStore from '../Root.store';
import { PortConfigResponse, PortConfigUpdateRequest } from 'data/schemas/portConfig';
import { getPortConfigList, updateStatusPortConfig } from 'data/repositories/portConfig/portConfig.repository';

class PortConfigStore {
    private _portConfigResponse: PortConfigResponse[] | null = null
    private _updateStatusResponse: string = ''

    public get portConfigResponse() {
        return this._portConfigResponse;
    }

    public get updateStatusResponse() {
        return this._updateStatusResponse
    }

    constructor(private rootStore: RootStore) {
        makeAutoObservable(this);
    }

    public async fetchListPortConfig() {
        try {
            const response = await getPortConfigList();

            runInAction(() => {
                this._portConfigResponse = response;
            });
        } catch (error) {
            throw error
        }
    }

    public async updateStatusProt(body: PortConfigUpdateRequest) {
        try {
            const response = await updateStatusPortConfig(body)

            runInAction(() => {
                this._updateStatusResponse = response;
            });
        } catch (error) {
            throw error
        }
    }
}

export default PortConfigStore;