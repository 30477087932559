import { useStore } from 'commons/hooks';
import React, { useEffect } from 'react';
import { Redirect, useLocation } from 'react-router';
import { Extra } from 'models';
import { observer } from 'mobx-react';
import Swal from 'sweetalert2';
import { AuthenticationResponse } from 'data/schemas/auth';
import { parseJwt } from 'helpers/decodeLocalStoreage';

const Ingress = () => {
    const useQuery = () => {
        const { search } = useLocation();

        return React.useMemo(() => new URLSearchParams(search), [search]);
    };

    let query = useQuery();
    const token = query.get('token');
    const redirect = query.get('redirect');
    const { authentication } = useStore();

    useEffect(() => {
        if (token !== null) {
            var jwt = parseJwt(token!);
            var extra: Extra = JSON.parse(jwt.extra);
            var data = {
                access_token: token
            };

            localStorage.removeItem('MM_APPLICATION');
            localStorage.setItem('MM_APPLICATION', JSON.stringify(data));

            (async () => {
                await authentication.fetchMasterEmployee(extra.citizen_id);
                const masterEmployee = authentication.masterEmployeeResponse;
                const statusUser = masterEmployee?.status;
                if (!masterEmployee?.ctizenID || statusUser === 'inactive') {
                    Swal.fire({
                        icon: 'error',
                        title: 'User not Permission',
                        text: `user: ${extra.citizen_id ?? 'ของคุณ'} ไม่มีสิทธ์เข้าใช้งาน`,
                        allowOutsideClick: false,
                        preConfirm() {
                            if (!redirect) {
                                window.close();
                            } else {
                                window.location.replace(redirect);
                            }
                        }
                    });
                }
            })();
        }
    }, []);

    return <Redirect to='' />;
};

export default observer(Ingress);
