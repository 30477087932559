import React, { Fragment, useEffect, useState } from 'react';
import { BsFillGearFill } from "react-icons/bs";
import { SiChakraui } from "react-icons/si";
import { HiOutlineSearch } from "react-icons/hi";
import { useHistory } from "react-router-dom";
import ReactDatatable from '@ashvin27/react-datatable';
import { Modal, ModalHeader, ModalBody, ModalFooter, Button, Input, Progress } from "reactstrap";
import ReactTagInput from "@pathofdev/react-tag-input";
import "@pathofdev/react-tag-input/build/index.css";
import BootstrapSwitchButton from 'bootstrap-switch-button-react';

import { AiOutlineReload } from "react-icons/ai";
import Swal from "sweetalert2";
import "../assets/style/style.css"

import Loader from './loader/ContentLoader'
import { configdatatable } from './configdatatable/Config';


import { connect } from 'react-redux';
import { ApplicationState } from '../store';

import * as AdminApplicationServerStore from '../store/AdminApplicationServer';
import * as AdminApplicationServerModels from '../models/AdminApplicationServer';


type adminApplicationServerProps = AdminApplicationServerModels.AdminApplicationServerState & typeof AdminApplicationServerStore.actionCreators

const QuickBox = ({ title, children }) => {
    return (
        <div>
            <h5>{title} : {children} </h5>
        </div>
    );
}

const AdminDashboardAppicationServer = (adminProps: adminApplicationServerProps) => {
    let history = useHistory();

    const [autorefresh, setautorefresh] = useState(false);

    const [modalIsOpen, setModalIsOpen] = useState(false);
    const toggle = () => setModalIsOpen(!modalIsOpen);

    const [refresh, setrefresh] = useState(false);
    const togglerefresh = () => setrefresh(!refresh);

    //const [modalId, setModalId] = useState('');
    const [modalfappid, setModalfappid] = useState('');
    const [modalGroup, setModalGroup] = useState('');
    const [modalType, setModalmodalType] = useState('');
    const [modalName, setModalmodalName] = useState('');
    const [modalIpaddress, setModalIpaddress] = useState('');
    const [modalport, setModalport] = useState('');


    const [tags, setTags] = useState(['']);


    useEffect(() => {
        console.log("เริ่ม")
        adminProps.requestGetAdminApplicationServer(100);
        autorefreshtime(null);
    }, [refresh]);

    const onchangeSwitch = (checked: boolean) => {
        if (checked === true) {
            setautorefresh(true);
            autorefreshtime(true);
        } else {
            setautorefresh(false);
        }
    }
    const autorefreshtime = (Switch) => {
        if (autorefresh === true || Switch === true) {
            setTimeout(() => {
                togglerefresh();
            }, 60000);
        } else {
            console.log("");
        }
    }

    const editdata = () => {
        console.log(modalfappid)
        console.log(modalGroup)
        console.log(modalType)
        console.log(modalName)
        console.log(modalIpaddress)
        console.log(tags)
        let Neweditport = (tags.toString()).split(',').join('|')

        Swal.fire({
            title: 'คุณแน่ใจไหม ?', text: "ในการแก้ไขข้อมูล!", icon: 'warning', showCancelButton: true,
            confirmButtonColor: '#3085d6', cancelButtonColor: '#d33', cancelButtonText: 'ยกเลิก', confirmButtonText: 'ยืนยัน'
        }).then((result) => {
            //console.log(result)
            if (result.isConfirmed === true) {
                //console.log(Neweditport);
                const editfappconfig = {
                    method: 'PUT',
                    headers: { 'Content-Type': 'application/json' },
                    body: JSON.stringify({
                        "id": modalfappid,
                        "group": modalGroup,
                        "type": modalType,
                        "name": modalName,
                        "domain": modalIpaddress,
                        "portList": Neweditport,
                        "created_by": ""
                    })
                };
                fetch(`/v1/config/fapp/edit/`, editfappconfig)
                    .then((response) => {
                        return response
                    })
                    .then((data) => {
                        //console.log(data)
                        if (!data.ok) {
                            Swal.fire('ไม่สำเร็จ!', '', 'error')
                        } else {
                            Swal.fire('สำเร็จ!', '', 'success')
                            setModalIsOpen(false);
                            let timer = setTimeout(() => togglerefresh(), 2000)
                        }
                    })
            }
        })

    }

    const deletedata = () => {
        //console.log(modalfappid)
        Swal.fire({
            title: 'คุณแน่ใจไหม ?', text: "ในลบข้อมูล!", icon: 'warning', showCancelButton: true,
            confirmButtonColor: '#3085d6', cancelButtonColor: '#d33', cancelButtonText: 'ยกเลิก', confirmButtonText: 'ยืนยัน'
        }).then((result) => {
            if (result.isConfirmed === true) {
                console.log(result)
                fetch(`/v1/config/fapp/delete/` + modalfappid, {
                    method: 'DELETE'
                })
                    .then((response) => {
                        return response
                    })
                    .then((data) => {
                        //console.log(data)
                        if (!data.ok) {
                            Swal.fire('ไม่สำเร็จ!', '', 'error')
                        } else {
                            Swal.fire('สำเร็จ!', '', 'success')
                            setModalIsOpen(false);
                            let timer = setTimeout(() => togglerefresh(), 2000)
                        }
                    })
            }
        })

    }

    const modal = (index) => {

        setModalfappid(adminProps.ResponseAdminApplicationServer[index - 1].fappID)
        setModalGroup(adminProps.ResponseAdminApplicationServer[index - 1].group);
        setModalmodalType(adminProps.ResponseAdminApplicationServer[index - 1].type);
        setModalmodalName(adminProps.ResponseAdminApplicationServer[index - 1].name);
        setModalIpaddress(adminProps.ResponseAdminApplicationServer[index - 1].domain);

        fetch(`/v1/config/fapp/` + (adminProps.ResponseAdminApplicationServer[index - 1].fappID))
            .then((response) => {
                if (!response.ok) {
                    Swal.fire('เกิดข้อผิดพลาด!', '', 'error')
                } else {
                    return response.json()
                }
            })
            .then((data) => {
                if (data != '' || data != undefined) {
                    console.log(data)
                    console.log(data.portList)
                    let portlist = data.portList.split('|')
                    //console.log(portlist)
                    setTags(portlist)
                    setModalIsOpen(true)
                } else {
                    Swal.fire('เกิดข้อผิดพลาด!', '', 'error')
                }
            })


    }


    const handleClick = (View) => {
        console.log(View)
       // history.push(`/viewdashboard/adminApplicationServer/${View}`)
    }
    const handleClickBack = () => {
        history.push(`/`)
    }

    const columns = [
        {
            key: "", text: "", className: "Manage",
            cell: (record, index) => {
                return (
                    <div style={{ display: 'flex', justifyContent: 'center' }}>
                        <div className="mx-1" style={{ textAlign: 'center', cursor: 'pointer' }}>
                            <BsFillGearFill onClick={() => modal(index + 1)} />
                        </div>
                        <div className="mx-1" style={{ textAlign: 'center', cursor: 'pointer' }}>
                            <HiOutlineSearch onClick={() => handleClick(index + 1)} />
                        </div>
                    </div>
                );
            }
        },
        {
            key: "group",
            text: "Group",
            className: "Group",
            sortable: true
        },
        {
            key: "type",
            text: "Type",
            className: "Type",
            sortable: true
        },
        {
            key: "name",
            text: "Name",
            className: "Name",
            sortable: true
        },
        {
            key: "domain",
            text: "Ipaddress",
            className: "Ipaddress",
        },
        {
            key: "port",
            text: "Port",
            className: "Prot",
        },
        {
            key: "status", text: "Status", className: "Status", sortable: true,
            cell: (record, index) => {
                return (<SiChakraui style={{ color: (record.status === "false") ? 'red' : 'green' }} />);
            }
        },
        {
            key: "cpu", text: "CPU", className: "widthProgressbar", sortable: true,
            cell: (record, index) => {
                return (
                    (record.cpu > 80) ? <Progress className="Progressbar" color="danger" style={{ border: '0.08px solid #73879C' }} animated value={record.cpu}><span className="fontProgressbar">{parseFloat(record.cpu).toFixed(2)}%</span></Progress>
                        : <Progress className="Progressbar" animated value={record.cpu}><span className="fontProgressbar">{parseFloat(record.cpu).toFixed(2)}%</span></Progress>)

            }
        },
        {
            key: "totalRam", text: "RAM", className: "widthProgressbar", sortable: true,
            cell: (record, index) => {
                return (
                    (record.totalRam > 80) ? <Progress className="Progressbar" style={{ border: '0.08px solid #73879C' }} color="danger" animated value={record.totalRam}><span className="fontProgressbar">{parseFloat(record.totalRam).toFixed(2)}%</span></Progress>
                        : <Progress className="Progressbar" animated value={record.totalRam}><span className="fontProgressbar">{parseFloat(record.totalRam).toFixed(2)}%</span></Progress>);
            }
        }
    ];

    return (

        <div >
            {(adminProps.isLoadAdminApplicationServer === true)
                ? <div className="totalserver" style={{ fontSize: '14px' }}>
                    <div className="d-flex align-items-center justify-content-between">
                        <div>
                            <Button style={{ padding: '5px' }} color="primary" onClick={() => handleClickBack()} >
                                Back
                            </Button>
                        </div>
                        <div className="d-flex align-items-center">
                            <div>
                                <h5 className="m-2">AutoRefresh</h5>
                            </div>
                            <div>
                                <BootstrapSwitchButton
                                    onlabel='ON'
                                    offlabel='OFF'
                                    checked={autorefresh}
                                    onChange={(checked: boolean) => {
                                        onchangeSwitch(checked);
                                    }}
                                    onstyle="primary" />
                            </div>
                            <div>
                                <h3 className="m-2">|</h3>
                            </div>
                            <div>
                                <Button style={{ padding: '0.9px' }} color="primary" onClick={togglerefresh}>
                                    Refresh<AiOutlineReload />
                                </Button>
                            </div>
                            <div className="d-flex m-2">
                                <QuickBox title="Total Server ">
                                    &nbsp;{adminProps.ResponseAdminApplicationServer.length}&nbsp;
                                </QuickBox>
                                <QuickBox title="ON">
                                    <span style={{ color: 'green' }}> &nbsp;{adminProps.ResponseAdminApplicationServer.filter(item => item.status === "true").length}&nbsp;</span>
                                </QuickBox>
                                <QuickBox title="OFF">
                                    <span style={{ color: 'red' }}> &nbsp;{adminProps.ResponseAdminApplicationServer.filter(item => item.status === "false").length}&nbsp;</span>
                                </QuickBox>
                            </div>
                        </div>
                    </div>
                    <ReactDatatable
                        className="table table-striped table-hover"
                        tHeadClassName="custom-header-style"
                        config={configdatatable}
                        records={adminProps.ResponseAdminApplicationServer}
                        columns={columns} />
                    <form>
                        <Modal isOpen={modalIsOpen} toggle={toggle} fullscreen="sm" centered
                            size="xl" >
                            <ModalHeader toggle={toggle}>Manage AdminApplicationServer</ModalHeader>
                            <ModalBody>
                                <div className="row">
                                    <div className="col-2">
                                        Group:
                                        <Input value={modalGroup} name="firstName" onChange={e => setModalGroup(e.target.value)} />
                                    </div>
                                    <div className="col-2">
                                        Type:
                                        <Input value={modalType} name="firstName" onChange={e => setModalmodalType(e.target.value)} />
                                    </div>
                                    <div className="col-3">
                                        Name:
                                        <Input value={modalName} name="firstName" onChange={e => setModalmodalName(e.target.value)} />
                                    </div>
                                    <div className="col-5">
                                        Ip address:
                                        <Input value={modalIpaddress} name="firstName" onChange={e => setModalIpaddress(e.target.value)} />
                                    </div>
                                </div>
                                <div >
                                    <div >
                                        Port:
                                        <ReactTagInput
                                            tags={tags}
                                            placeholder="Type port and press Enter."
                                            editable={true}
                                            readOnly={false}
                                            removeOnBackspace={true}
                                            onChange={(newTags) => setTags(newTags)}
                                        />
                                    </div>
                                </div>
                            </ModalBody>

                            <ModalFooter style={{ justifyContent: 'flex-start' }}>
                                <Button className="btn btn-danger" onClick={deletedata}>delete</Button>
                                <div style={{ marginLeft: 'auto', }}>
                                    <Button className="btn btn-primary" style={{ marginRight: '5px' }} onClick={editdata}>OK</Button>
                                    <Button className="btn btn-primary" onClick={toggle}>Close</Button>
                                </div>

                            </ModalFooter>
                        </Modal>
                    </form>

                </div>
                : <div className="Loader">
                    <Loader />
                </div>
            }
        </div>
    );
}
export default connect(
    (state: ApplicationState) => state.adminapplicationserver,
    AdminApplicationServerStore.actionCreators
)(AdminDashboardAppicationServer as any)


