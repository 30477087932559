import React from 'react';
import CreatableSelect from 'react-select/creatable';
import {
    Button,
    Input,
    Modal,
    ModalBody,
    ModalFooter,
    ModalHeader
} from 'reactstrap';
import { CommandLog, FappUpdateRequest } from '../../../../models/AdminServer';

interface IdentityModalProps {
    isOpenModal: boolean;
    toggleModal: () => void;
    commandInfo?: FappUpdateRequest;
    runCommand: () => void;
    onChangeCommandInfo: (value: CommandLog) => void;
    inputErrors: Partial<
        Record<'developerInput' | 'applicationTargetInput', boolean>
    >;
}

const CommandVerificationModalComponent = (props: IdentityModalProps) => {
    return (
        <Modal
            isOpen={props.isOpenModal}
            toggle={props.toggleModal}
            fullscreen="sm"
            centered
            size="xl"
        >
            <ModalHeader toggle={props.toggleModal}>
                กรุณาตรวจสอบรายละเอียดก่อนกดยืนยัน
            </ModalHeader>
            <ModalBody>
                <div className="row">
                    {/* Command Update Log */}
                    <div className="col-6">
                        <span>Developer</span>
                        <Input
                            type="text"
                            name="developer"
                            onChange={e =>
                                props.onChangeCommandInfo({
                                    application_target:
                                        props.commandInfo
                                            ?.log_application_target ?? '',
                                    developer: e.target.value,
                                    tag: props.commandInfo?.log_tag ?? '',
                                    description:
                                        props.commandInfo?.log_description ?? ''
                                })
                            }
                            style={{
                                borderColor: props.inputErrors.developerInput
                                    ? 'red'
                                    : undefined
                            }}
                        />
                    </div>

                    <div className="col-6">
                        <span>Tag (UAT/PRD)</span>
                        <Input
                            value={props.commandInfo?.log_tag ?? ''}
                            type="text"
                            name="tag"
                            onChange={e =>
                                props.onChangeCommandInfo({
                                    application_target:
                                        props.commandInfo
                                            ?.log_application_target ?? '',
                                    developer:
                                        props.commandInfo?.log_developer ?? '',
                                    tag: e.target.value,
                                    description:
                                        props.commandInfo?.log_description ?? ''
                                })
                            }
                        />
                    </div>
                    <div className="col-6 mt-2">
                        <span>Application Target (Web/Fapp)</span>
                        <CreatableSelect
                            className="w-100"
                            isClearable={false}
                            options={[
                                { label: 'WEB', value: 'WEB' },
                                { label: 'FAPP', value: 'FAPP' }
                            ]}
                            onChange={app =>
                                props.onChangeCommandInfo({
                                    application_target: app?.value ?? '',
                                    developer:
                                        props.commandInfo?.log_developer ?? '',
                                    tag: props.commandInfo?.log_tag ?? '',
                                    description:
                                        props.commandInfo?.log_description ?? ''
                                })
                            }
                            isValidNewOption={() => false}
                            styles={{
                                control: (baseStyle, state) => ({
                                    ...baseStyle,
                                    borderColor: props.inputErrors
                                        .applicationTargetInput
                                        ? 'red'
                                        : baseStyle.borderColor
                                })
                            }}
                        />
                    </div>

                    <div className="col-6 mt-2">
                        <span>Port Target</span>
                        <Input
                            value={props.commandInfo?.port ?? ''}
                            type="text"
                            name="port-target"
                            // onChange={e => props.onChangeCommandInfo(e.target.value)}
                            disabled
                        />
                    </div>
                    <div className="col-6 mt-2">
                        <span>Server Target</span>
                        <Input
                            value={props.commandInfo?.domain ?? ''}
                            type="text"
                            name="server-target"
                            // onChange={e => props.onChangeCommandInfo(e.target.value)}
                            disabled
                        />
                    </div>
                    <div className="col-6 mt-2">
                        <span>Command Name</span>
                        <Input
                            value={props.commandInfo?.command_name ?? ''}
                            type="text"
                            name="command-name"
                            // onChange={e => props.onChangeCommandInfo(e.target.value)}
                            disabled
                        />
                    </div>
                    <div className="col-12 mt-2">
                        <span>Description</span>
                        <Input
                            // value={props.commandInfo?.command_name ?? ''}
                            type="textarea"
                            name="description"
                            onChange={e =>
                                props.onChangeCommandInfo({
                                    application_target:
                                        props.commandInfo
                                            ?.log_application_target ?? '',
                                    developer:
                                        props.commandInfo?.log_developer ?? '',
                                    tag: props.commandInfo?.log_tag ?? '',
                                    description: e.target.value
                                })
                            }
                        />
                    </div>
                </div>
            </ModalBody>
            <ModalFooter style={{ justifyContent: 'flex-start' }}>
                <div style={{ marginLeft: 'auto' }}>
                    <Button
                        className="btn btn-success"
                        style={{ marginRight: '5px' }}
                        onClick={() => props.runCommand()}
                    >
                        Confirm
                    </Button>
                    <Button
                        className="btn btn-primary"
                        onClick={props.toggleModal}
                    >
                        Close
                    </Button>
                </div>
            </ModalFooter>
        </Modal>
    );
};

export default CommandVerificationModalComponent;
