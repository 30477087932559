import axios from 'axios';

import {
    CommandConfigMasterResponse,
    CommandConfigResponse,
    GetCommandConfigByDomainRequest,
    GetCommandConfigMasterRequest
} from '../../../schemas/command';

export async function getByDomain(params: GetCommandConfigByDomainRequest) {
    return axios.get<CommandConfigResponse[]>('v1/command/configs', {
        params,
        headers: {
            Authorization: 'Bearer ' + localStorage.getItem('MM_APPLICATION')
        }
    });
}

export async function getAllMaster(params: GetCommandConfigMasterRequest) {
    return axios.get<CommandConfigMasterResponse[]>('v1/command/master-configs', {
        params,
        headers: {
            Authorization: 'Bearer ' + localStorage.getItem('MM_APPLICATION')
        }
    });
}
