import 'react-datepicker/dist/react-datepicker.css';
import { useState, useEffect, useCallback } from 'react';
import { useHistory } from 'react-router';
import Swal from 'sweetalert2';

import { useLoader } from 'commons/hooks';
import { googleMetrics } from 'data/repositories/google/google.repository';

import { MonitorSelectorSideNav, ActionPane, MonitorInformation } from './components';
import { MetricMonitor, MetricMonitorGraphValues } from '../../../models/Google';
import type { Monitor } from './components/MonitorSelectorSideNav';

const today = new Date();

const formatDateToNumber = (date: Date) => {
    const year = date.getFullYear();
    const month = (date.getMonth() + 1).toString().padStart(2, '0');
    const day = date.getDate().toString().padStart(2, '0');
    const hours = date.getHours().toString().padStart(2, '0');
    const minutes = date.getMinutes().toString().padStart(2, '0');
    const seconds = date.getSeconds().toString().padStart(2, '0');

    return `${year}${month}${day}${hours}${minutes}${seconds}`;
};

const MonitorPerformance = () => {
    const loader = useLoader();
    const history = useHistory();

    const [selectedMonitor, setSelectedMonitor] = useState<Monitor>({
        available: true,
        name: 'EPS',
        monitorDatabaseId: 'eps-db-v2',
        monitorId: 'eps-prd'
    });

    const [webMonitorGraphValues, setWebMonitorGraphValues] = useState<MetricMonitorGraphValues>();
    const [dbMonitorGraphValues, setDatabaseMonitorGraphValues] =
        useState<MetricMonitorGraphValues>();
    const [searchFromShowDisplay, setSearchFromShowDisplay] = useState(
        formatDateToNumber(new Date(today.getFullYear(), today.getMonth(), 1))
    );
    // const [searchFromShowDisplay, setSearchFromShowDisplay] = useState(
    //     formatDateToNumber(firstMonth)
    // );
    const [searchToShowDisplay, setSearchToShowDisplay] = useState(
        formatDateToNumber(new Date(today.getFullYear(), today.getMonth() + 1, 0))
    );
    // const [searchToShowDisplay, setSearchToShowDisplay] = useState(formatDateToNumber(weekOne));

    useEffect(() => {
        const getMonitorShowDisplay = async () => {
            const { monitorId, monitorDatabaseId, name } = selectedMonitor;

            try {
                loader.show();

                const [responseMetricsPrd, responseMetricsDatabase] = await Promise.allSettled([
                    _googleMetricsPrd(monitorId, searchFromShowDisplay, searchToShowDisplay),
                    _googleMetricsDatabase(
                        monitorDatabaseId,
                        searchFromShowDisplay,
                        searchToShowDisplay
                    )
                ]);

                setWebMonitorGraphValues(
                    responseMetricsPrd.status === 'fulfilled'
                        ? responseMetricsPrd.value
                        : ({} as MetricMonitorGraphValues)
                );
                setDatabaseMonitorGraphValues(
                    responseMetricsDatabase.status === 'fulfilled'
                        ? responseMetricsDatabase.value
                        : ({} as MetricMonitorGraphValues)
                );
            } catch (error) {
                if (error instanceof Error) {
                    Swal.fire(`Cannot get values from ${name}`, error.message);
                }
            } finally {
                loader.hide();
            }
        };

        getMonitorShowDisplay();
    }, [selectedMonitor, searchFromShowDisplay, searchToShowDisplay]);

    const _googleMetricsPrd = async (
        monitor_id: string,
        date_time_from: string,
        date_time_to: string
    ) => {
        const webMonitorResponse = await googleMetrics({
            monitor_id,
            date_time_from,
            date_time_to
        });

        return formatMetricMonitorToMetricMonitorGraphValues(webMonitorResponse);
    };

    const _googleMetricsDatabase = async (
        monitor_id: string,
        date_time_from: string,
        date_time_to: string
    ) => {
        const databaseMonitor = await googleMetrics({
            monitor_id,
            date_time_from,
            date_time_to
        });

        return formatMetricMonitorToMetricMonitorGraphValues(databaseMonitor);
    };

    const formatMetricMonitorToMetricMonitorGraphValues = (monitor: MetricMonitor) => {
        const formatter = new Intl.DateTimeFormat('en-GB', {
            month: 'short',
            day: '2-digit'
        });

        // Convert data from server to google charts format data
        const webMonitorGraphValues = {} as MetricMonitorGraphValues;
        const usedKeys: (keyof MetricMonitor)[] = [
            'cpuUsage',
            'ramUsage',
            'diskUsage',
            'sentBytes',
            'receivedBytes'
        ];
        usedKeys.forEach(key => {
            webMonitorGraphValues[key] = monitor[key].reduce<(string | number)[][]>(
                (accumulate, usage) => {
                    accumulate.push([formatter.format(new Date(usage.time)), usage.value]);

                    return accumulate;
                },
                [['Time', key]]
            );
        });

        return webMonitorGraphValues;
    };

    const handleNavigateToMonitoringSummary = async () => {
        try {
            loader.show();

            const today = new Date();
            const firstDayOfPrevMonth = new Date(today.getFullYear(), today.getMonth() - 1, 1);
            const lastDayOfPrevMonth = new Date();
            lastDayOfPrevMonth.setDate(1);
            lastDayOfPrevMonth.setHours(-1);

            const dayInMillisec = 86400000;
            const weekOneEnd = new Date(firstDayOfPrevMonth.getTime() + 6 * dayInMillisec);

            const weekTwoStart = new Date(weekOneEnd.getTime() + dayInMillisec);
            const weekTwoEnd = new Date(weekTwoStart.getTime() + 6 * dayInMillisec);

            const weekThreeStart = new Date(weekTwoEnd.getTime() + dayInMillisec);
            const weekThreeEnd = new Date(weekThreeStart.getTime() + 6 * dayInMillisec);

            const weekFourStart = new Date(weekThreeEnd.getTime() + dayInMillisec);
            const weekFourEnd = new Date(weekFourStart.getTime() + 6 * dayInMillisec);

            const weekFiveStart = new Date(weekFourEnd.getTime() + dayInMillisec);

            const { monitorId, monitorDatabaseId, name } = selectedMonitor;

            //web
            const [
                webMonitorWeekOne,
                webMonitorWeekTwo,
                webMonitorWeekThree,
                webMonitorWeekFour,
                webMonitorWeekFive,
                webMonitorMonth,
                dbMonitorWeekOne,
                dbMonitorWeekTwo,
                dbMonitorWeekThree,
                dbMonitorWeekFour,
                dbMonitorWeekFive,
                dbMonitorMonth
            ] = await Promise.all([
                _googleMetricsPrd(
                    monitorId,
                    formatDateToNumber(firstDayOfPrevMonth),
                    formatDateToNumber(weekOneEnd)
                ),
                _googleMetricsPrd(
                    monitorId,
                    formatDateToNumber(weekTwoStart),
                    formatDateToNumber(weekTwoEnd)
                ),
                _googleMetricsPrd(
                    monitorId,
                    formatDateToNumber(weekThreeStart),
                    formatDateToNumber(weekThreeEnd)
                ),
                _googleMetricsPrd(
                    monitorId,
                    formatDateToNumber(weekFourStart),
                    formatDateToNumber(weekFourEnd)
                ),
                _googleMetricsPrd(
                    monitorId,
                    formatDateToNumber(weekFiveStart),
                    formatDateToNumber(lastDayOfPrevMonth)
                ),
                _googleMetricsPrd(
                    monitorId,
                    formatDateToNumber(firstDayOfPrevMonth),
                    formatDateToNumber(lastDayOfPrevMonth)
                ),
                _googleMetricsDatabase(
                    monitorDatabaseId,
                    formatDateToNumber(firstDayOfPrevMonth),
                    formatDateToNumber(weekOneEnd)
                ),
                _googleMetricsDatabase(
                    monitorDatabaseId,
                    formatDateToNumber(weekTwoStart),
                    formatDateToNumber(weekTwoEnd)
                ),
                _googleMetricsDatabase(
                    monitorDatabaseId,
                    formatDateToNumber(weekThreeStart),
                    formatDateToNumber(weekThreeEnd)
                ),
                _googleMetricsDatabase(
                    monitorDatabaseId,
                    formatDateToNumber(weekFourStart),
                    formatDateToNumber(weekFourEnd)
                ),
                _googleMetricsDatabase(
                    monitorDatabaseId,
                    formatDateToNumber(weekFiveStart),
                    formatDateToNumber(lastDayOfPrevMonth)
                ),
                _googleMetricsDatabase(
                    monitorDatabaseId,
                    formatDateToNumber(firstDayOfPrevMonth),
                    formatDateToNumber(lastDayOfPrevMonth)
                )
            ]);

            history.push('/summary', {
                webMonitorWeekOne,
                webMonitorWeekTwo,
                webMonitorWeekThree,
                webMonitorWeekFour,
                webMonitorWeekFive,
                webMonitorMonth,
                dbMonitorWeekOne,
                dbMonitorWeekTwo,
                dbMonitorWeekThree,
                dbMonitorWeekFour,
                dbMonitorWeekFive,
                dbMonitorMonth,
                name
            });
        } catch (error) {
            alert(JSON.stringify(error));
        } finally {
            loader.hide();
        }
    };

    const handleChangeMonitor = useCallback((monitor: Monitor) => {
        setSelectedMonitor(monitor);
    }, []);

    return (
        <div className="flex h-full">
            <MonitorSelectorSideNav
                selectedMonitor={selectedMonitor}
                onChange={handleChangeMonitor}
            />
            <div className="w-full p-2">
                <ActionPane
                    monitorName={selectedMonitor.name}
                    onClickExport={handleNavigateToMonitoringSummary}
                    setSearcFromShowDisplay={setSearchFromShowDisplay}
                    setSearcToShowDisplay={setSearchToShowDisplay}
                />
                <MonitorInformation
                    key={`${JSON.stringify(webMonitorGraphValues)}${JSON.stringify(dbMonitorGraphValues)}`}
                    webMonitorGraphValues={webMonitorGraphValues}
                    dbMonitorGraphValues={dbMonitorGraphValues}
                />
            </div>
        </div>
    );
};

export default MonitorPerformance;
