import type { Ping, WebSocketResponse } from 'models/Migration';

export const validateNumberOnly = (str: string) => {
    const re = new RegExp('^[0-9]+$');

    return re.test(str);
};

export const isNumeric = (str: string) => {
    if (typeof str !== 'string') return false; // we only process strings!

    return !isNaN(parseFloat(str));
};

export const isJson = (str: string) => {
    try {
        JSON.parse(str);
    } catch {
        return false;
    }

    return true;
};

export function isPing(obj: object): obj is Ping {
    return 'status' in obj && 'message' in obj;
}

export function isWebSocketResponse(obj: object): obj is WebSocketResponse {
    return 'connection_id' in obj && 'script_group' in obj && 'detail' in obj;
}
