import React, { useState } from "react";
import { CircularProgressbar } from 'react-circular-progressbar';
import { Chart as ChartJS, CategoryScale, LinearScale, PointElement, LineElement, Title, Tooltip, Legend, } from 'chart.js'
import { Line } from 'react-chartjs-2'
ChartJS.register(CategoryScale, LinearScale, PointElement, LineElement, Title, Tooltip, Legend)
import { SiChakraui } from "react-icons/si";
import { useSelector } from 'react-redux';
import { RouteComponentProps, useParams, useHistory, } from "react-router-dom";
import { useEffect } from "react";
import Loader from '../loader/ContentLoader'
import { Row, Button } from "reactstrap";


interface ParamTypes {
    page: string,
    id: string,
}


const ViewDashboard = () => {
    let history = useHistory();

    const storeadminserver = useSelector((state: any) => state.adminserver.ResponseAdminServer)
    const storeadminapplicationserver = useSelector((state: any) => state.adminapplicationserver.ResponseAdminApplicationServer)
    //const StoreAdminWeb = useSelector((state: any) => state.adminserver)


    const { page, id } = useParams<ParamTypes>();
    const newid = (Number(id) - 1).toString();
    const [pageState, setpageState] = useState('');
    const [store, setstore] = useState('');

    const [chartOpen, setchartOpen] = useState(false);


    const [day1, setDay1] = useState('');
    const [day2, setDay2] = useState('');

    const [startDay, setstartDay] = useState('');
    const [lastday, setlastday] = useState('');

    // 1 day
    const initialDates = [''];
    const initialDatesshow = [''];
    const initialDataPointsCPU = [''];
    const initialDataPointsRAM = [''];
    const initialDataPointsHDD = [''];
    const initialDataPointsIISCPU = [''];
    const initialDataPointsIISRAM = [''];


    // all day

    const allinitialDates = [] as any
    const allinitialDatesshow = [] as any
    const allinitialDataPointsCPU = [] as any
    const allinitialDataPointsRAM = [] as any
    const allinitialDataPointsHDD = [] as any
    const allinitialDataPointsIISCPU = [] as any
    const allinitialDataPointsIISRAM = [] as any


    useEffect(() => {
        let start = false
        if (storeadminserver.length === 0 && storeadminapplicationserver.length === 0) {
            start = false
        } else if (storeadminserver.length != 0) {
            start = true
        } else if (storeadminapplicationserver.length != 0) {
            start = true
        }

        if (page === "adminServer" && start === true) {

            console.log("adminServer")
            //console.log(storeadminserver[newid])
            setstore(storeadminserver);
            setpageState("adminServer");
            fetchdataadminServer(storeadminserver[newid].id)

        } else if (page === "adminApplicationServer" && start === true) {

            console.log("adminApplicationServer")
            setstore(storeadminapplicationserver);
            setpageState("adminApplicationServer");

            fetchdataadminApplicationServer(storeadminapplicationserver[newid].fappID, storeadminapplicationserver[newid].port)
        } else {
            console.log("ออก")
            history.push('/');
        }

    }, []);

    const fetchdataadminServer = (ID) => {
        console.log('ทดสอบfatchdata')
        //console.log(ID)

        fetch(`v1/monitor/server/inday/` + ID, {
            method: 'GET'
        })
            .then((response) => {
                if (!response.ok) {
                    console.log('เกิดข้อผิดพลาดfetch')
                    //Swal.fire('เกิดข้อผิดพลาด!', '', 'error')
                } else {
                    return response.json()
                }
            })
            .then((data) => {
                if (data != '' || data != undefined) {
                    // console.log(data)
                    if (page === "adminServer") {

                        const test = data.map((value, index) => {

                            initialDatesshow.push(((value.updateOn).substring(19, 0)).replace("T", " "))
                            initialDates.push((value.updateOn).substring(10, 0))
                            initialDataPointsCPU.push(value.cpu)
                            initialDataPointsRAM.push(value.totalRam)
                            initialDataPointsHDD.push(value.totalStorage)
                            initialDataPointsIISCPU.push(value.iiscpu)
                            initialDataPointsIISRAM.push(value.totalIisRam)
                        })
                    } else {
                        console.log('ผิดพลาด')
                    }
                    setchartOpen(true)
                } else {
                    console.log('ผิดพลาด')
                }
            })

        fetch(`v1/monitor/server/` + ID, {
            method: 'GET'
        })
            .then((response) => {
                if (!response.ok) {
                    console.log('เกิดข้อผิดพลาดfetch')
                } else {
                    return response.json()
                }
            })
            .then((data) => {
                if (data != '' || data != undefined) {
                    if (page === "adminServer") {
                        //console.log(data)

                        const test = data.map((value, index) => {
                            allinitialDatesshow.push(((value.updateOn).substring(19, 0)).replace("T", " "))
                            allinitialDates.push((value.updateOn).substring(10, 0))
                            allinitialDataPointsCPU.push(value.cpu)
                            allinitialDataPointsRAM.push(value.totalRam)
                            allinitialDataPointsHDD.push(value.totalStorage)
                            allinitialDataPointsIISCPU.push(value.iiscpu)
                            allinitialDataPointsIISRAM.push(value.totalIisRam)
                        })
                    }
                } else {
                    console.log('ผิดพลาด')
                }
            })

    }

    const fetchdataadminApplicationServer = (fappID, port) => {

        fetch(`/v1/monitor/fapp/inday?fapp_id=` + fappID + `&port=` + port, {
            method: 'GET'
        })
            .then((response) => {
                if (!response.ok) {
                    console.log('เกิดข้อผิดพลาดfetch')
                    //Swal.fire('เกิดข้อผิดพลาด!', '', 'error')
                } else {
                    return response.json()
                }
            })
            .then((data) => {

                if (data != '' || data != undefined) {
                    // console.log(data)
                    if (page === "adminApplicationServer") {
                        const test = data.map((value, index) => {
                            initialDatesshow.push(((value.updatedOn).substring(19, 0)).replace("T", " "))
                            initialDates.push((value.updatedOn).substring(10, 0))
                            initialDataPointsCPU.push(value.cpu)
                            initialDataPointsRAM.push(value.totalRam)
                        })
                    } else {
                        console.log('ผิดพลาด')
                    }
                    setchartOpen(true)
                } else {
                    console.log('ผิดพลาด')
                }
            })


        fetch(`/v1/monitor/fapp?fapp_id=` + fappID + `&port=` + port, {
            method: 'GET'
        })
            .then((response) => {
                if (!response.ok) {
                    console.log('เกิดข้อผิดพลาดfetch')
                } else {
                    return response.json()
                }
            })
            .then((data) => {
                if (data != '' || data != undefined) {
                    if (page === "adminApplicationServer") {
                        //console.log(data)

                        const test = data.map((value, index) => {

                            allinitialDatesshow.push(((value.updatedOn).substring(19, 0)).replace("T", " "))
                            allinitialDates.push((value.updatedOn).substring(10, 0))
                            allinitialDataPointsCPU.push(value.cpu)
                            allinitialDataPointsRAM.push(value.totalRam)
                            allinitialDataPointsHDD.push(value.totalStorage)
                            allinitialDataPointsIISCPU.push(value.iiscpu)
                            allinitialDataPointsIISRAM.push(value.totalIisRam)
                        })
                    }
                } else {
                    console.log('ผิดพลาด')
                }
            })

    }
    const [dates, setDates] = useState(initialDates);
    const [datesshow, setDatesshow] = useState(initialDatesshow);
    // 1 day
    const [dataPointsCpu, setDataPointsCpu] = useState(initialDataPointsCPU);
    const [dataPointsRam, setDataPointsRam] = useState(initialDataPointsRAM);
    const [dataPointsHdd, setDataPointsHdd] = useState(initialDataPointsHDD);
    const [dataPointsIiscpu, setDataPointsIiscpu] = useState(initialDataPointsIISCPU)
    const [dataPointsIisram, setDataPointsIisram] = useState(initialDataPointsIISRAM)

    // 2 day
    const [alldates, setallDates] = useState(allinitialDates);
    const [alldatesshow, setallDatesshow] = useState(allinitialDatesshow);
    const [alldataPointsCpu, setallDataPointsCpu] = useState(allinitialDataPointsCPU);
    const [alldataPointsRam, setallDataPointsRam] = useState(allinitialDataPointsRAM);
    const [alldataPointsHdd, setallDataPointsHdd] = useState(allinitialDataPointsHDD);
    const [alldataPointsIiscpu, setallDataPointsIiscpu] = useState(allinitialDataPointsIISCPU);
    const [alldataPointsIisram, setallDataPointsIisram] = useState(allinitialDataPointsIISRAM);

    const [Resetdates, setResetdates] = useState(initialDatesshow);
    const [ResetdataPointsCpu, setResetdataPointsCpu] = useState(initialDataPointsCPU);
    const [ResetdataPointsRam, setResetdataPointsRam] = useState(initialDataPointsRAM);
    const [ResetdataPointsHdd, setResetdataPointsHdd] = useState(initialDataPointsHDD);
    const [ResetdataPointsIiscpu, setResetdataPointsIiscpu] = useState(initialDataPointsIISCPU);
    const [ResetdataPointsIisram, setResetdataPointsIisram] = useState(initialDataPointsIISRAM);

    const options = {
        scales: {
            yAxes: [{
                ticks: {
                    fontSize: 40
                }
            }]
        }
    }

    const filterData = () => {

        const dates2 = [...alldates];
        const dates3 = [...alldatesshow];

        const dataPoints2cpu = [...alldataPointsCpu];
        const dataPoints2ram = [...alldataPointsRam];
        const dataPoints2hdd = [...alldataPointsHdd];
        const dataPoints2iiscpu = [...alldataPointsIiscpu];
        const dataPoints2iisram = [...alldataPointsIisram];



        const indexstartdate = dates2.indexOf(day1);
        const indexenddate = dates2.lastIndexOf(day2);

        const filterDate = dates3.slice(indexstartdate, indexenddate + 1);
        //console.log(dates3)
        const filterDataPointscpu = dataPoints2cpu.slice(
            indexstartdate,
            indexenddate + 1
        );
        const filterDataPointsram = dataPoints2ram.slice(
            indexstartdate,
            indexenddate + 1
        );
        const filterDataPointshdd = dataPoints2hdd.slice(
            indexstartdate,
            indexenddate + 1
        );
        const filterDataPointsiiscpu = dataPoints2iiscpu.slice(
            indexstartdate,
            indexenddate + 1
        );
        const filterDataPointsiisram = dataPoints2iisram.slice(
            indexstartdate,
            indexenddate + 1
        );
        setDatesshow(filterDate);
        setDataPointsCpu(filterDataPointscpu);
        setDataPointsRam(filterDataPointsram);
        setDataPointsHdd(filterDataPointshdd);
        setDataPointsIiscpu(filterDataPointsiiscpu);
        setDataPointsIisram(filterDataPointsiisram);
    }

    const Reset = () => {
        //console.log(Resetdates)
        setDatesshow(Resetdates);
        setDataPointsCpu(ResetdataPointsCpu);
        setDataPointsRam(ResetdataPointsRam);
        setDataPointsHdd(ResetdataPointsHdd);
        setDataPointsIiscpu(ResetdataPointsIiscpu);
        setDataPointsIisram(ResetdataPointsIisram);
    }

    const dataadminServer = {
        labels: datesshow,
        datasets: [
            {
                label: 'CPU',
                fill: false,
                //lineTension: 1,
                backgroundColor: '#19e6a0',
                borderColor: '#19e6a0',
                //borderDash: [],
                // borderDashOffset: 0.0,
                pointBorderColor: 'rgba(75,192,192,1)',
                pointBackgroundColor: '#fff',
                pointBorderWidth: 1,
                pointHoverRadius: 5,
                pointHoverBackgroundColor: 'rgba(75,192,192,1)',
                pointHoverBorderColor: 'rgba(220,220,220,1)',
                pointHoverBorderWidth: 2,
                pointRadius: 1,
                pointHitRadius: 10,
                data: dataPointsCpu
            },
            {
                label: 'RAM',
                fill: false,
                // lineTension: 1,
                backgroundColor: '#1798f9',
                borderColor: '#1798f9',
                // borderDash: [],
                //borderDashOffset: 0.0,
                pointBorderColor: '#1798f9',
                pointBackgroundColor: '#fff',
                pointBorderWidth: 1,
                pointHoverRadius: 5,
                pointHoverBackgroundColor: '#1798f9',
                pointHoverBorderColor: '#1798f9',
                pointHoverBorderWidth: 2,
                pointRadius: 1,
                pointHitRadius: 10,
                data: dataPointsRam
            },
            {
                label: 'HDD',
                fill: false,
                // lineTension: 1,
                backgroundColor: '#df513d',
                borderColor: '#df513d',
                //borderDash: [],
                // borderDashOffset: 0.0,
                pointBorderColor: '#df513d',
                pointBackgroundColor: '#fff',
                pointBorderWidth: 1,
                pointHoverRadius: 5,
                pointHoverBackgroundColor: '#df513d',
                pointHoverBorderColor: '#df513d',
                pointHoverBorderWidth: 2,
                pointRadius: 1,
                pointHitRadius: 10,
                data: dataPointsHdd
            },
            {
                label: 'IISCCPU',
                fill: false,
                // lineTension: 1,
                backgroundColor: '#dbb957',
                borderColor: '#dbb957',
                //borderDash: [],
                // borderDashOffset: 0.0,
                pointBorderColor: '#dbb957',
                pointBackgroundColor: '#fff',
                pointBorderWidth: 1,
                pointHoverRadius: 5,
                pointHoverBackgroundColor: '#dbb957',
                pointHoverBorderColor: '#dbb957',
                pointHoverBorderWidth: 2,
                pointRadius: 1,
                pointHitRadius: 10,
                data: dataPointsIiscpu
            },
            {
                label: 'IISRAM',
                fill: false,
                // lineTension: 1,
                backgroundColor: '#242038',
                borderColor: '#242038',
                //borderDash: [],
                // borderDashOffset: 0.0,
                pointBorderColor: '#242038',
                pointBackgroundColor: '#fff',
                pointBorderWidth: 1,
                pointHoverRadius: 5,
                pointHoverBackgroundColor: '#242038',
                pointHoverBorderColor: '#242038',
                pointHoverBorderWidth: 2,
                pointRadius: 1,
                pointHitRadius: 10,
                data: dataPointsIisram
            },
        ]
    };

    const dataadminApplicationServer = {
        labels: datesshow,
        datasets: [
            {
                label: 'CPU',
                fill: false,
                //lineTension: 1,
                backgroundColor: '#19e6a0',
                borderColor: '#19e6a0',
                //borderDash: [],
                // borderDashOffset: 0.0,
                pointBorderColor: 'rgba(75,192,192,1)',
                pointBackgroundColor: '#fff',
                pointBorderWidth: 1,
                pointHoverRadius: 5,
                pointHoverBackgroundColor: 'rgba(75,192,192,1)',
                pointHoverBorderColor: 'rgba(220,220,220,1)',
                pointHoverBorderWidth: 2,
                pointRadius: 1,
                pointHitRadius: 10,
                data: dataPointsCpu
            },
            {
                label: 'RAM',
                fill: false,
                //lineTension: 1,
                backgroundColor: '#1798f9',
                borderColor: '#1798f9',
                //borderDash: [],
                //borderDashOffset: 0.0,
                pointBorderColor: '#1798f9',
                pointBackgroundColor: '#fff',
                pointBorderWidth: 1,
                pointHoverRadius: 5,
                pointHoverBackgroundColor: '#1798f9',
                pointHoverBorderColor: '#1798f9',
                pointHoverBorderWidth: 2,
                pointRadius: 1,
                pointHitRadius: 10,
                data: dataPointsRam
            }
        ]
    };

    const handleClickApplicationServer = () => {
        history.push(`/adminApplicationServer`);
    }
    const handleClickOverview = () => {
        history.push(`/admindashboardoverview`);
    }

    return (
        <>
            {(pageState != '' && store != '')
                ? <div className="container-fluid">
                    {/*{console.log(store[newid])}*/}
                    <div className=" shadow-lg h-100 p-4">
                        <div className="col">
                            <div className="row-cols-1 h-100 " >
                                <div className="border-bottom " style={{ display: 'flex', flexWrap: 'wrap', justifyContent: 'space-between' }}>
                                    <div className="row">
                                        <h5 className="mr-2">Company : FocusOne</h5>
                                        <div className="mr-2">
                                            <Button style={{ padding: '0.9px', height: '30px' }} color="primary" onClick={() => handleClickApplicationServer()}>
                                                ApplicationServer
                                            </Button>
                                        </div>
                                        <Button style={{ padding: '0.9px', height: '30px' }} color="primary" onClick={() => handleClickOverview()}>
                                            Overview
                                        </Button>
                                    </div>
                                    {(store[newid].status === "false")
                                        ? <h5> Status <SiChakraui className="mb-1" style={{ color: 'red' }} /> </h5>
                                        : <h5> Status : <SiChakraui className="mb-1" style={{ color: 'green' }} /></h5>
                                    }
                                </div>
                                <div className=" border-bottom p-3">
                                    <Row>
                                        <div className="col-md-auto" style={{ alignItems: 'center' }}>
                                            Name : {store[newid].name}
                                        </div>

                                        <div className="col-md-auto" style={{ alignItems: 'center' }}>
                                            Group : {store[newid].group}
                                        </div>
                                        <div className="col-md-auto" style={{ alignItems: 'center' }}>
                                            Type : {store[newid].type}
                                        </div>
                                        <div className="col-md-auto" >
                                            Ipaddress : {store[newid].domain}
                                        </div>
                                        {(pageState === "adminApplicationServer") ? <div className="col-md-auto" style={{ alignItems: 'center' }}> Port : {store[newid].port}</div> : null}
                                    </Row>

                                </div>
                            </div>
                        </div>
                        <div style={{ display: 'flex', justifyContent: 'center' }}>
                            {(pageState === "adminServer")
                                ? <div className="mt-3" style={{ width: 'max-content' }}>
                                    <div style={{ display: 'flex' }}>
                                        <div className=" col">
                                            <CircularProgressbar styles={{ text: { fill: '#19e6a0', fontSize: '16px' }, path: { stroke: '#19e6a0' } }} value={store[newid].cpu} text={`CPU:${Number(store[newid].cpu).toFixed()}%`} />
                                        </div>
                                        <div className="col">
                                            <CircularProgressbar styles={{ text: { fill: '#1798f9', fontSize: '16px' }, path: { stroke: '#1798f9' } }} value={store[newid].totalRam} text={`RAM:${Number(store[newid].totalRam).toFixed()}%`} />
                                        </div>
                                        <div className="col">
                                            <CircularProgressbar styles={{ text: { fill: '#df513d', fontSize: '16px' }, path: { stroke: '#df513d' } }} value={store[newid].totalStorage} text={`HDD:${Number(store[newid].totalStorage).toFixed()}%`} />
                                        </div>

                                        <div className="col">
                                            <CircularProgressbar styles={{ text: { fill: '#dbb957', fontSize: '16px' }, path: { stroke: '#dbb957' } }} value={store[newid].iiscpu} text={`iisCpu:${Number(store[newid].iiscpu).toFixed()}%`} />
                                        </div>
                                        <div className="col">
                                            <CircularProgressbar styles={{ text: { fill: '#242038', fontSize: '16px' }, path: { stroke: '#242038' }, }} value={store[newid].totalIisRam} text={`iisRam:${Number(store[newid].totalIisRam).toFixed()}%`} />
                                        </div>
                                    </div>
                                </div>

                                : <div className="mt-3" style={{ width: 'max-content' }}>
                                    <div style={{ display: 'flex', justifyContent: 'space-evenly' }}>
                                        <div className="col">
                                            <CircularProgressbar styles={{ text: { fill: '#19e6a0', fontSize: '16px' }, path: { stroke: '#19e6a0' } }} value={store[newid].cpu} text={`CPU:${Number(store[newid].cpu)}%`} />
                                        </div>
                                        <div className="col">
                                            <CircularProgressbar styles={{ text: { fill: '#1798f9', fontSize: '16px' }, path: { stroke: '#1798f9' } }} value={store[newid].totalRam} text={`RAM:${Number(store[newid].totalRam)}%`} />
                                        </div>
                                    </div>
                                </div>
                            }
                        </div>
                    </div>
                    <div className="shadow-lg mt-3 p-3">
                        {/*<h5 className="mr-2">[ประวัติ 15-01-2021 TO 31-01-2021]</h5>*/}
                        <input type="date" onChange={e => setDay1(e.target.value)} />
                        <input type="date" onChange={e => setDay2(e.target.value)} />
                        <button onClick={filterData}>Filter</button>
                        <button onClick={Reset}>Reset</button>
                        <div className="p-3">
                            {(pageState === "adminServer")
                                ? (chartOpen === true) ? <Line
                                    data={dataadminServer} options={{
                                        scales: {
                                            x: {
                                                ticks: {
                                                    font: {
                                                        size: 10,
                                                    }
                                                }
                                            }
                                        }

                                    }}
                                /> : <div className="Loader">
                                    <Loader />
                                </div>
                                : (chartOpen === true) ? <Line data={dataadminApplicationServer}
                                    options={{
                                        scales: {
                                            x: {
                                                ticks: {
                                                    font: {
                                                        size: 10,
                                                    }
                                                }
                                            }
                                        }

                                    }} /> : <div className="Loader">
                                    <Loader />
                                </div>
                            }
                        </div>
                    </div>
                </div>
                : <div className="Loader">
                    <Loader />
                </div>
            }
        </>
    );
}

export default ViewDashboard;
