import AdminDashboardTableComponent from './AdminDashboardTableComponent';
import { useLoader, useStore } from '../../../../commons/hooks';
import { useEffect } from 'react';
import { ftpMonitorColumns } from '../data/ftpMonitorColumns';

function FTPMonitorDashboard() {
    const loader = useLoader();
    const { ftpMonitorStore } = useStore();
    const { ftpMonitors } = ftpMonitorStore;

    useEffect(() => {
        if (ftpMonitors && ftpMonitors.length > 0) {
            return;
        }

        fetchFTPMonitors();
    }, [ftpMonitors]);

    const fetchFTPMonitors = async () => {
        try {
            loader.show();

            await ftpMonitorStore.fetchFTPMonitors();
        } catch {
        } finally {
            loader.hide();
        }
    };

    return (
        <AdminDashboardTableComponent
            columns={ftpMonitorColumns}
            data={ftpMonitors ?? []}
            onCheckOnlineStatus={record => record.status}
            handleClickEvent={() => void 0}
            onToggleSwitch={() => void 0}
            isAutoRefresh={false}
            toggleRefresh={fetchFTPMonitors}
        />
    );
}

export default FTPMonitorDashboard;
