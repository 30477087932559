import { Action, Reducer, createStore, combineReducers } from 'redux';
import { useSelector, useDispatch } from "react-redux";
import { AppThunkAction } from '.';
// models
import * as Models from '../models/DBWorker'

// GET
interface LoadDBWorker {
    type: 'LOAD_DBWORKER'
}
interface LoadedDBWorker {
    type: 'LOADEDDBWORKER'
    response: Models.ResponseDBWorker
    status: number,
    statusMessage: string
}
interface FailLoadedDBWorker {
    type: 'LOADDBWORKERFAIL'
    status: number,
    statusMessage: any
}
interface ClearLoadedDBWorker {
    type: 'CLEARLOADEDDBWORKER'
}

interface LoadDBWorkerErr {
    type: 'LOAD_DBWORKER_ERR'
}
interface LoadedDBWorkerErr {
    type: 'LOADEDDBWORKER_ERR'
    response: Models.ResponseDBWorker
    status: number,
    statusMessage: string
}
interface FailLoadedDBWorkerErr {
    type: 'LOADDBWORKERFAIL_ERR'
    status: number,
    statusMessage: any
}
interface ClearLoadedDBWorkerErr {
    type: 'CLEARLOADEDDBWORKER_ERR'
}

//Count
interface LoadCountDBWorker {
    type: 'LOAD_COUNTDBWORKER'
}
interface LoadedCountDBWorker {
    type: 'LOADEDCOUNTDBWORKER'
    response: Models.ResponseCountDBWorker
    status: number,
    statusMessage: string
}
interface FailLoadedCountDBWorker {
    type: 'LOADCOUNTDBWORKERFAIL'
    status: number,
    statusMessage: any
}
interface ClearLoadedCountDBWorker {
    type: 'CLEARLOADEDCOUNTDBWORKER'
}

interface LoadCountDBWorkerErr {
    type: 'LOAD_COUNTDBWORKER_ERR'
}
interface LoadedCountDBWorkerErr {
    type: 'LOADEDCOUNTDBWORKER_ERR'
    response: Models.ResponseCountDBWorker
    status: number,
    statusMessage: string
}
interface FailLoadedCountDBWorkerErr {
    type: 'LOADCOUNTDBWORKERFAIL_ERR'
    status: number,
    statusMessage: any
}
interface ClearLoadedCountDBWorkerErr {
    type: 'CLEARLOADEDCOUNTDBWORKER_ERR'
}

type KnownAction = LoadDBWorker | LoadedDBWorker | FailLoadedDBWorker | ClearLoadedDBWorker | LoadDBWorkerErr | LoadedDBWorkerErr | FailLoadedDBWorkerErr | ClearLoadedDBWorkerErr | LoadCountDBWorker | LoadedCountDBWorker | FailLoadedCountDBWorker | ClearLoadedCountDBWorker | LoadCountDBWorkerErr | FailLoadedCountDBWorkerErr | ClearLoadedCountDBWorkerErr | LoadedCountDBWorkerErr

export const actionCreators = {
    requestGetDBWorkerInprocess: (check: boolean, method: string , key : string ): AppThunkAction<KnownAction> => (dispatch, getState) => {
        const appState = getState();
        if (appState && appState.DBWorker && check && method == 'GET') {
            console.log('call actionCreators : requestGetDBWorkerInprocess')
            fetch(`/v1/check/worker/inprocess?key=` + key, {
                method: 'GET',
                headers: {
                    'Authorization': 'Bearer ' + localStorage.getItem('LG_APPLICATION'),
                },
            })
                .then((response) => {
                    return response
                })
                .then((data) => {
                    console.log(data)
                    /*                    dispatch({ type: 'LOADEDAPICONFIG', response: data, status: 200, statusMessage: '' });*/
                    //dispatch({ type: 'LOADAPICONFIGFAIL', status: 204, statusMessage: error.message });
                    if (!data.ok) {
                        data.json().then(err => {
                            console.log(err.message);
                            dispatch({ type: 'LOADDBWORKERFAIL', status: 204, statusMessage: err.message });
                        })
                    } else {
                        data.json().then(data => {
                            console.log(data)
                            dispatch({ type: 'LOADEDDBWORKER', response: data, status: 200, statusMessage: '' });
                        })
                    }
                });
            dispatch({ type: 'LOAD_DBWORKER' });
        } else if (method == "CLEAR") {
            dispatch({ type: 'CLEARLOADEDDBWORKER' })
        }
    },

    requestGetDBWorkerError: (check: boolean, method: string , key : string): AppThunkAction<KnownAction> => (dispatch, getState) => {
        const appState = getState();
        if (appState && appState.DBWorker && check && method == 'GET') {
            console.log('call actionCreators : requestGetDBWorkerError')
            fetch(`/v1/check/worker/error?key=` + key, {
                method: 'GET',
                //headers: {
                //    'Authorization': 'Bearer ' + localStorage.getItem('LG_APPLICATION'),
                //},
            })
                .then((response) => {
                    return response
                })
                .then((data) => {
                    console.log(data)
                    /*                    dispatch({ type: 'LOADEDAPICONFIG', response: data, status: 200, statusMessage: '' });*/
                    //dispatch({ type: 'LOADAPICONFIGFAIL', status: 204, statusMessage: error.message });
                    if (!data.ok) {
                        data.json().then(err => {
                            console.log(err.message);
                            dispatch({ type: 'LOADDBWORKERFAIL_ERR', status: 204, statusMessage: err.message });
                        })
                    } else {
                        data.json().then(data => {
                            console.log(data)
                            dispatch({ type: 'LOADEDDBWORKER_ERR', response: data, status: 200, statusMessage: '' });
                        })
                    }
                });
            dispatch({ type: 'LOAD_DBWORKER_ERR' });
        } else if (method == "CLEAR") {
            dispatch({ type: 'CLEARLOADEDDBWORKER_ERR' })
        }
    },

    requestGetCountDBWorker: (check: boolean, method: string): AppThunkAction<KnownAction> => (dispatch, getState) => {
        const appState = getState();
        if (appState && appState.DBWorker && check && method == 'GET') {
            console.log('call actionCreators : requestGetCountDBWorker')
            fetch(`/v1/count/worker/inprocess`, {
                method: 'GET',
                //headers: {
                //    'Authorization': 'Bearer ' + localStorage.getItem('LG_APPLICATION'),
                //},
            })
                .then((response) => {
                    return response
                })
                .then((data) => {
                    console.log(data)
                    /*                    dispatch({ type: 'LOADEDAPICONFIG', response: data, status: 200, statusMessage: '' });*/
                    //dispatch({ type: 'LOADAPICONFIGFAIL', status: 204, statusMessage: error.message });
                    if (!data.ok) {
                        data.json().then(err => {
                            console.log(err.message);
                            dispatch({ type: 'LOADCOUNTDBWORKERFAIL', status: 204, statusMessage: err.message });
                        })
                    } else {
                        data.json().then(data => {
                            console.log(data)
                            dispatch({ type: 'LOADEDCOUNTDBWORKER', response: data, status: 200, statusMessage: '' });
                        })
                    }
                });
            dispatch({ type: 'LOAD_COUNTDBWORKER' });
        } else if (method == "CLEAR") {
            dispatch({ type: 'CLEARLOADEDCOUNTDBWORKER' })
        }
    },

    requestGetCountDBWorkerErr: (check: boolean, method: string): AppThunkAction<KnownAction> => (dispatch, getState) => {
        const appState = getState();
        if (appState && appState.DBWorker && check && method == 'GET') {
            console.log('call actionCreators : requestGetCountDBWorkerErr')
            fetch(`/v1/count/worker/error`, {
                method: 'GET',
                //headers: {
                //    'Authorization': 'Bearer ' + localStorage.getItem('LG_APPLICATION'),
                //},
            })
                .then((response) => {
                    return response
                })
                .then((data) => {
                    console.log(data)
                    /*                    dispatch({ type: 'LOADEDAPICONFIG', response: data, status: 200, statusMessage: '' });*/
                    //dispatch({ type: 'LOADAPICONFIGFAIL', status: 204, statusMessage: error.message });
                    if (!data.ok) {
                        data.json().then(err => {
                            console.log(err.message);
                            dispatch({ type: 'LOADCOUNTDBWORKERFAIL_ERR', status: 204, statusMessage: err.message });
                        })
                    } else {
                        data.json().then(data => {
                            console.log(data)
                            dispatch({ type: 'LOADEDCOUNTDBWORKER_ERR', response: data, status: 200, statusMessage: '' });
                        })
                    }
                });
            dispatch({ type: 'LOAD_COUNTDBWORKER_ERR' });
        } else if (method == "CLEAR") {
            dispatch({ type: 'CLEARLOADEDCOUNTDBWORKER_ERR' })
        }
    },
}

const unloadedGetDBWorkerInprocessState: Models.GetDBWorkerState = { isLoadDBWorker: false };
const unloadedGetDBWorkerErrState: Models.GetDBWorkerErrState = { isLoadDBWorkerErr: false };
const unloadedGetCountDBWorkerState: Models.GetCountDBWorkerState = { isLoadCountDBWorker: false };
const unloadedGetCountDBWorkerErrState: Models.GetCountDBWorkerErrState = { isLoadCountDBWorkerErr: false };

const reducerGetDBWorkerInprocess: Reducer<Models.GetDBWorkerState> = (state: Models.GetDBWorkerState | undefined, incomingAction: Action): Models.GetDBWorkerState => {
    if (state == undefined) {
        return unloadedGetDBWorkerInprocessState;
    }
    const action = incomingAction as KnownAction;
    switch (action.type) {
        case 'LOAD_DBWORKER':
            return {
                isLoadDBWorker: false,
            }

        case 'LOADEDDBWORKER':
            return {
                isLoadDBWorker: true,
                responseDBWorker: action.response as any,
                statusDBWorker: action.status
            }

        case 'LOADDBWORKERFAIL':
            return {
                isLoadDBWorker: true,
                messageDBWorker: action.statusMessage,
                statusDBWorker: action.status
            }

        case 'CLEARLOADEDDBWORKER':
            return {
                isLoadDBWorker: false,
                messageDBWorker: "",
                statusDBWorker: 0
            }

        default: return state;
    }
}

const reducerGetDBWorkerErr: Reducer<Models.GetDBWorkerErrState> = (state: Models.GetDBWorkerErrState | undefined, incomingAction: Action): Models.GetDBWorkerErrState => {
    if (state == undefined) {
        return unloadedGetDBWorkerErrState;
    }
    const action = incomingAction as KnownAction;
    switch (action.type) {
        case 'LOAD_DBWORKER_ERR':
            return {
                isLoadDBWorkerErr: false,
            }

        case 'LOADEDDBWORKER_ERR':
            return {
                isLoadDBWorkerErr: true,
                responseDBWorkerErr: action.response as any,
                statusDBWorkerErr: action.status
            }

        case 'LOADDBWORKERFAIL_ERR':
            return {
                isLoadDBWorkerErr: true,
                messageDBWorkerErr: action.statusMessage,
                statusDBWorkerErr: action.status
            }

        case 'CLEARLOADEDDBWORKER_ERR':
            return {
                isLoadDBWorkerErr: false,
                messageDBWorkerErr: "",
                statusDBWorkerErr: 0
            }

        default: return state;
    }
}

const reducerGetCountDBWorker: Reducer<Models.GetCountDBWorkerState> = (state: Models.GetCountDBWorkerState | undefined, incomingAction: Action): Models.GetCountDBWorkerState => {
    if (state == undefined) {
        return unloadedGetCountDBWorkerState;
    }
    const action = incomingAction as KnownAction;
    switch (action.type) {
        case 'LOAD_COUNTDBWORKER':
            return {
                isLoadCountDBWorker: false,
            }

        case 'LOADEDCOUNTDBWORKER':
            return {
                isLoadCountDBWorker: true,
                responseCountDBWorker: action.response as any,
                statusCountDBWorker: action.status
            }

        case 'LOADCOUNTDBWORKERFAIL':
            return {
                isLoadCountDBWorker: true,
                messageCountDBWorker: action.statusMessage,
                statusCountDBWorker: action.status
            }

        case 'CLEARLOADEDCOUNTDBWORKER':
            return {
                isLoadCountDBWorker: false,
                messageCountDBWorker: "",
                statusCountDBWorker: 0
            }

        default: return state;
    }
}

const reducerGetCountDBWorkerErr: Reducer<Models.GetCountDBWorkerErrState> = (state: Models.GetCountDBWorkerErrState | undefined, incomingAction: Action): Models.GetCountDBWorkerErrState => {
    if (state == undefined) {
        return unloadedGetCountDBWorkerErrState;
    }
    const action = incomingAction as KnownAction;
    switch (action.type) {
        case 'LOAD_COUNTDBWORKER_ERR':
            return {
                isLoadCountDBWorkerErr: false,
            }

        case 'LOADEDCOUNTDBWORKER_ERR':
            return {
                isLoadCountDBWorkerErr: true,
                responseCountDBWorkerErr: action.response as any,
                statusCountDBWorkerErr: action.status
            }

        case 'LOADCOUNTDBWORKERFAIL_ERR':
            return {
                isLoadCountDBWorkerErr: true,
                messageCountDBWorkerErr: action.statusMessage,
                statusCountDBWorkerErr: action.status
            }

        case 'CLEARLOADEDCOUNTDBWORKER_ERR':
            return {
                isLoadCountDBWorkerErr: false,
                messageCountDBWorkerErr: "",
                statusCountDBWorkerErr: 0
            }

        default: return state;
    }
}

export const rootReducer = combineReducers({ DBWorker: reducerGetDBWorkerInprocess, DBWorkerErr: reducerGetDBWorkerErr, CountDBWorker: reducerGetCountDBWorker, CountDBWorkerErr: reducerGetCountDBWorkerErr });