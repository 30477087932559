import { Action, Reducer } from 'redux';
import { AppThunkAction } from '.';
import { dbDateTimeToDisplayString } from '../commons/common';

import * as Models from '../models/AdminServer'

interface LoadAdminServer {
    type: 'LOAD_ADMIN_SERVER'
}

interface LoadedAdminServer {
    type: 'LOADED_ADMIN_SERVER';
    response: Models.DataAdminServer[];
    status: number,
    statusMessage: string

}
interface FailLoadedAdminServer {
    type: 'LOAD_ADMIN_SERVER_FAIL'
    status: number,
    statusMessage: any
}

interface ClearAdminServer {
    type: 'CLEAR_ADMIN_SERVER'
}

type KnownAction = LoadedAdminServer | FailLoadedAdminServer | LoadAdminServer | ClearAdminServer;

export const actionCreators = {
    requestGetAdminServer: (startDateIndex: number): AppThunkAction<KnownAction> => (dispatch, getState) => {

        const appState = getState();
        
        if (startDateIndex == 100) {
            fetch(`v1/monitor/servers`)

                .then((response) => {
                    if (!response.ok) {
                        throw new Error(response.json() as any)
                    } else {
                        return response.json() as Promise<Models.DataAdminServer[]>
                    }
                })
                .then((data) => {
                    dispatch({
                        type: 'LOADED_ADMIN_SERVER',
                        response: [...data].map((result, index) => {
                            result.updateOn = dbDateTimeToDisplayString(result.updateOn);

                            return result;
                        }),
                        status: 200,
                        statusMessage: ''
                    });
                })
                .catch(error => {
                    dispatch({ type: 'LOAD_ADMIN_SERVER_FAIL', status: 204, statusMessage: 'errorfetch' });
                })
            dispatch({type:'LOAD_ADMIN_SERVER'})
        }
    }
};


const unloadedState: Models.AdminServerState = { isLoadAdminServer: false, ResponseAdminServer: [] };


export const reducer: Reducer<Models.AdminServerState> = (state: Models.AdminServerState | undefined, incomingAction: Action): Models.AdminServerState => {
    if (state === undefined) {
        return unloadedState;
    }
    const action = incomingAction as KnownAction;
    switch (action.type) {
        case 'LOAD_ADMIN_SERVER':
            return {
                isLoadAdminServer: false,
                ResponseAdminServer:[]
            }
            
        case 'LOADED_ADMIN_SERVER':
            return {
                ResponseAdminServer: action.response as any,
                isLoadAdminServer: true,
                statusAdminServer: action.status,
                messageAdminServer: action.statusMessage
            }
        case 'LOAD_ADMIN_SERVER_FAIL':
            return {
                isLoadAdminServer: false,
                messageAdminServer: action.statusMessage,
                statusAdminServer: action.status,
                ResponseAdminServer:[]
            }
        default: return state;
    }

};