import React, { useEffect, useState } from 'react';
import { RouteComponentProps, useParams, useHistory } from 'react-router-dom';
import { useSelector } from 'react-redux';
import Loader from './loader/ContentLoader';

interface ParamTypes {
    page: string;
    id: string;
}

const ManageAdminDashboard = () => {
    const { page, id } = useParams<ParamTypes>();
    const [pageState, setpageState] = useState('');
    const [store, setstore] = useState('');
    const newid = (Number(id) - 1).toString();
    const storeadminserver = useSelector((state: any) => state.adminserver.ResponseAdminServer);
    const storeadminapplicationserver = useSelector((state: any) => state.adminapplicationserver);
    const storeadminweb = useSelector((state: any) => state.adminweb);

    useEffect(() => {
        if (page === 'adminServer') {
            setstore(storeadminserver);
            setpageState('adminServer');
        } else if (page === 'adminApplicationServer') {
            console.log('adminApplicationServer');

            setstore(storeadminapplicationserver);
            setpageState('adminApplicationServer');
        } else if (page === 'adminWeb') {
            console.log('adminWeb');
            setstore(storeadminweb);
            setpageState('storeadminweb');
        } else {
            console.log('ออก');
        }
    }, []);

    return (
        <>
            {pageState != '' && store != '' ? (
                <div>
                    <div className="container-fluid ">
                        <div
                            className="row"
                            style={{ height: '330px' }}
                        >
                            <div className="col-3 ">
                                <div className="row row-cols-1 p-1">
                                    <div
                                        className="col border shadow pt-1"
                                        style={{ height: '160px' }}
                                    >
                                        <h6>System Detail </h6>
                                        <div className="border-top d-flex flex-column">
                                            <span>Invite :</span>
                                            <span>SID:</span>
                                            <span>ลงทะเบียน:</span>
                                            <span>วันที่เปิดใช้งานระบบ:</span>
                                        </div>
                                    </div>
                                    <div
                                        className="col border shadow mt-2 pt-1"
                                        style={{ height: '150px' }}
                                    >
                                        <h6>Server Connection Status</h6>
                                        <div className="border-top d-flex flex-column">
                                            <span>IP Address : {store[newid].domain} </span>
                                            <span>URL : {store[newid].domain}</span>
                                            <span>
                                                Status : {store[newid].status}{' '}
                                                {/*{console.log(store[id].id)}*/}
                                            </span>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="col-7">
                                <div className="row row-cols-1 p-1  ">
                                    <div className="shadow">
                                        <div
                                            className="col border pt-1 "
                                            style={{ height: '185px' }}
                                        >
                                            <h6>System Detail </h6>
                                            <div className="border-top d-flex flex-row-reverse ">
                                                <div className="d-flex flex-column ">
                                                    <div className="d-flex justify-content-around">
                                                        <span>Usage :</span> 0.00/0.00
                                                    </div>
                                                    <div className="d-flex justify-content-around">
                                                        <span>Quota :</span> 0.00 GB
                                                    </div>
                                                    <div className="d-flex justify-content-around">
                                                        <span>Storage Usage : </span>{' '}
                                                        {store[newid].totalStorage} %
                                                    </div>
                                                    <div className="d-flex justify-content-around">
                                                        <span>Ram :</span> {store[newid].totalRam} %
                                                        | {(store[newid].ram / 1024).toFixed(1)} GB
                                                    </div>
                                                    <div className="d-flex justify-content-around">
                                                        <span>CPU :</span> {store[newid].cpu} %
                                                    </div>
                                                    <div className="d-flex justify-content-around">
                                                        <span>Available :</span> 0.00 GB
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div
                                            className="col border pt-1"
                                            style={{ height: '135px' }}
                                        >
                                            <div className="row">
                                                <div className="col-sm border-right ">
                                                    <h6>Storage Usage Detail</h6>
                                                    <span>
                                                        {' '}
                                                        File Attachment (0.00 files) : 0.00 GB
                                                    </span>
                                                </div>
                                                <div className="col-sm border-right">
                                                    <h6>Ram Usage Detail</h6>
                                                    <div>
                                                        <span>
                                                            Ram Usage :
                                                            {(store[newid].ramUsage / 1024).toFixed(
                                                                1
                                                            )}{' '}
                                                            GB
                                                        </span>
                                                    </div>
                                                    <div>
                                                        <span>
                                                            PersentRam Usage :{' '}
                                                            {store[newid].totalRam} %
                                                        </span>
                                                    </div>
                                                </div>
                                                <div className="col-sm">
                                                    <h6>CPU Usage Detail</h6>
                                                    <span>CPU Usage : {store[newid].cpu} % </span>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="col-2 ">
                                <div className="row row-cols-1 p-1">
                                    <div
                                        className="col border shadow pt-1"
                                        style={{ height: '140px' }}
                                    >
                                        <h6>General Management</h6>
                                        <div className="border-top d-flex flex-column">
                                            <button
                                                type="button"
                                                className="btn btn-primary btn-sm m-1"
                                            >
                                                Manage Quota
                                            </button>
                                        </div>
                                    </div>
                                    <div
                                        className="col border shadow mt-2 pt-1"
                                        style={{ height: '170px' }}
                                    >
                                        <h6>Technical Control</h6>
                                        <div className="border-top d-flex flex-column">
                                            <button
                                                type="button"
                                                className="btn btn-primary btn-sm m-1"
                                            >
                                                PerformanceControl
                                            </button>
                                            <button
                                                type="button"
                                                className="btn btn-primary btn-sm m-1"
                                            >
                                                Remote Update
                                            </button>
                                            <button
                                                type="button"
                                                className="btn btn-primary btn-sm m-1"
                                            >
                                                Service Mail
                                            </button>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div
                            className="row"
                            style={{ height: '330px' }}
                        >
                            <div className="col-3 "></div>
                            <div className="col-7">
                                <div className="row row-cols-1 p-1  ">
                                    <div className="shadow">
                                        <div
                                            className="col border pt-1 "
                                            style={{ height: '200px' }}
                                        >
                                            <h6>Users</h6>
                                            <div className="border-top d-flex flex-row-reverse ">
                                                <div className="d-flex flex-column ">
                                                    <div className="d-flex justify-content-around">
                                                        <span>Usage :</span> 0.00/0.00
                                                    </div>
                                                    <div className="d-flex justify-content-around">
                                                        <span>Quota :</span> 0.00 GB
                                                    </div>
                                                    <div className="d-flex justify-content-around">
                                                        <span>Storage Usage : </span> 0.00 GB
                                                    </div>
                                                    <div className="d-flex justify-content-around">
                                                        <span>Available :</span> 0.00 GB
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="col-2 "></div>
                        </div>
                    </div>
                </div>
            ) : (
                <div className="Loader">
                    <Loader />
                </div>
            )}
        </>

        //
        //  <div className="container-fluid ">
        //    <div className="row" style={{ height: '330px' }}>
        //        <div className="col-3 ">
        //            <div className="row row-cols-1 p-1" >
        //                <div className="col border shadow pt-1" style={{ height: '160px' }}>
        //                    <h6>System Detail </h6>
        //                    <div className="border-top d-flex flex-column">
        //                        <span>Invite :</span>
        //                        <span>SID:</span>
        //                        <span>ลงทะเบียน:</span>
        //                        <span>วันที่เปิดใช้งานระบบ:</span>
        //                    </div>
        //                </div>
        //                <div className="col border shadow mt-2 pt-1" style={{ height: '150px' }}>
        //                    <h6>Server Connection Status</h6>
        //                    <div className="border-top d-flex flex-column">
        //                        <span>IP Address :</span>
        //                        <span>URL :</span>
        //                        <span>Status :</span>
        //                    </div>
        //                </div>
        //            </div>
        //        </div>
        //        <div className="col-7">
        //            <div className="row row-cols-1 p-1  " >
        //                <div className="shadow">
        //                    <div className="col border pt-1 " style={{ height: '185px' }}>
        //                        <h6>System Detail </h6>
        //                        <div className="border-top d-flex flex-row-reverse ">
        //                            <div className="d-flex flex-column ">
        //                                <div className="d-flex justify-content-around"><span>Usage :</span> 0.00/0.00</div>
        //                                <div className="d-flex justify-content-around"><span>Quota :</span> 0.00 GB</div>
        //                                <div className="d-flex justify-content-around"><span>Storage Usage : </span> 0.00 GB</div>
        //                                <div className="d-flex justify-content-around"><span>Ram :</span> 43 % | 210 GB</div>
        //                                <div className="d-flex justify-content-around"><span>CPU :</span> 10 %</div>
        //                                <div className="d-flex justify-content-around"><span>Available :</span> 0.00 GB</div>
        //                            </div>
        //                        </div>
        //                    </div>
        //                    <div className="col border pt-1" style={{ height: '135px' }}>
        //                        <div className="row">
        //                            <div className="col-sm border-right ">
        //                                <h6>Storage Usage Detail</h6>
        //                                <span> File Attachment (0.00 files) : 0.00 GB</span>
        //                            </div>
        //                            <div className="col-sm border-right">
        //                                <h6>Ram Usage Detail</h6>
        //                                <span>Ram Usage : 2.10 GB</span>
        //                                <span>Persent Ram Usage : 43 %</span>
        //                            </div>
        //                            <div className="col-sm">
        //                                <h6>CPU Usage Detail</h6>
        //                                <span>CPU Usage : 10% </span>
        //                            </div>
        //                        </div>
        //                    </div>
        //                </div>
        //            </div>
        //        </div>
        //        <div className="col-2 ">
        //            <div className="row row-cols-1 p-1" >
        //                <div className="col border shadow pt-1" style={{ height: '140px' }}>
        //                    <h6>General Management</h6>
        //                    <div className="border-top d-flex flex-column">
        //                        <button type="button" className="btn btn-primary btn-sm m-1">Manage Quota</button>
        //                    </div>
        //                </div>
        //                <div className="col border shadow mt-2 pt-1" style={{ height: '170px' }}>
        //                    <h6>Technical Control</h6>
        //                    <div className="border-top d-flex flex-column">
        //                        <button type="button" className="btn btn-primary btn-sm m-1">PerformanceControl</button>
        //                        <button type="button" className="btn btn-primary btn-sm m-1">Remote Update</button>
        //                        <button type="button" className="btn btn-primary btn-sm m-1">Service Mail</button>
        //                    </div>
        //                </div>
        //            </div>
        //        </div>
        //    </div>
        //    <div className="row" style={{ height: '330px' }}>
        //        <div className="col-3 ">
        //        </div>
        //        <div className="col-7">
        //            <div className="row row-cols-1 p-1  " >
        //                <div className="shadow">
        //                    <div className="col border pt-1 " style={{ height: '200px' }}>
        //                        <h6>Users</h6>
        //                        <div className="border-top d-flex flex-row-reverse ">
        //                            <div className="d-flex flex-column ">
        //                                <div className="d-flex justify-content-around"><span>Usage :</span> 0.00/0.00</div>
        //                                <div className="d-flex justify-content-around"><span>Quota :</span> 0.00 GB</div>
        //                                <div className="d-flex justify-content-around"><span>Storage Usage : </span> 0.00 GB</div>
        //                                <div className="d-flex justify-content-around"><span>Available :</span> 0.00 GB</div>
        //                            </div>
        //                        </div>
        //                    </div>
        //                </div>
        //            </div>
        //        </div>
        //        <div className="col-2 "></div>
        //    </div>

        //</div>
    );
};
export default ManageAdminDashboard;
