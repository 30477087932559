import { useEffect, useState, useMemo } from 'react';
import { useHistory, useLocation } from 'react-router-dom';

import { useStore } from '../../../commons/hooks';
import useLoader from 'hooks/useLoader';
import Swal from 'sweetalert2';
import { parseJwt } from '../../../helpers/decodeLocalStoreage';
import { Extra } from '../../../models';
import { AdminServerState } from '../../../models/AdminServer';

export type ServerMonitorOnClickType =
    | 'NAVIGATE_TO_SERVER_DASHBOARD_DETAIL'
    | 'NAVIGATE_TO_ADMIN_SERVER_CONTROL';

function ServerMonitorViewModel() {
    const history = useHistory();
    const location = useLocation();
    const screenLoader = useLoader();
    const { serverConfigStore, authentication } = useStore();

    //const monitorServers = serverConfigStore.monitorServers;
    const authenticationUser = authentication.masterEmployeeResponse;

    const [activeTab, setActiveTab] = useState('1');
    const [autoRefresh, setAutoRefresh] = useState(false);

    const [refresh, setRefresh] = useState(false);
    const toggleRefresh = () => setRefresh(!refresh);

    const { token, group } = useMemo(() => {
        const params = new URLSearchParams(location.search);

        window.history.pushState({}, '', window.location.pathname);

        return {
            token: params.get('token'),
            group: params.get('group')
        };
    }, [location]);

    const monitorServers = useMemo(() => {
        if (!group) {
            (async () => {
                const action = await Swal.fire('No content!', 'servers not found.', 'error')

                if (action.isConfirmed) {
                    window.close();
                }
            })();

            return { isLoadAdminServer: false } as AdminServerState;
        }

        const serverMonitors = serverConfigStore.monitorServers;
        const groupLower = group.toLowerCase();

        serverMonitors.ResponseAdminServer = serverMonitors.ResponseAdminServer
            .filter(r => r.group.toLowerCase().includes(groupLower));

        return serverMonitors;
    }, [group, serverConfigStore.monitorServers]);

    useEffect(() => {
        if (!token || !group) {
            (async () => {
                const action = await Swal.fire({
                    icon: 'error',
                    title: 'Access denied!',
                    text: 'user นี้ไม่มีสิทธิ์เข้าใช้งาน',
                    allowOutsideClick: false
                });

                if (action.isConfirmed) {
                    window.close();
                }
            })();

            return;
        }

        const jwt = parseJwt(token!);
        const extra: Extra = JSON.parse(jwt.extra);
        const data = {
            access_token: token
        };

        // get values from session
        const tokenSession = sessionStorage.getItem('MM_APPLICATION');

        // while token doesn't exists in session storage
        if (!tokenSession) {
            // save token
            sessionStorage.setItem('MM_APPLICATION', JSON.stringify(data));
        }
        
        // save group
        sessionStorage.setItem('GROUP', group);

        (async () => {
            await authentication.fetchMasterEmployee(extra.citizen_id);
            const masterEmployee = authentication.masterEmployeeResponse;
            const statusUser = masterEmployee?.status;

            if (!masterEmployee?.ctizenID || statusUser === 'inactive') {
                const action = await Swal.fire({
                    icon: 'error',
                    title: 'User not Permission',
                    text: `user: ${extra.citizen_id ?? 'ของคุณ'} ไม่มีสิทธ์เข้าใช้งาน`,
                    allowOutsideClick: false,
                    //preConfirm() {
                    //    if (!redirect) {
                    //        window.close();
                    //    }
                    //}
                });

                if (action.isConfirmed) {
                    window.close();
                }
            }
        })();
    }, []);

    useEffect(() => {
        _fetchMonitorServers();
        _autoRefreshTime(false);
    }, [refresh]);

    const onSelectTab = (tabNumber: string) => {
        setActiveTab(tabNumber);
    };

    const onchangeSwitch = (checked: boolean) => {
        if (checked === true) {
            setAutoRefresh(true);
            _autoRefreshTime(true);
        } else {
            setAutoRefresh(false);
        }
    };

    const handleClick = (event: ServerMonitorOnClickType, value: string) => {
        switch (event) {
            case 'NAVIGATE_TO_ADMIN_SERVER_CONTROL': {
                _navigateToAdminServerControl(value);

                break;
            }
            case 'NAVIGATE_TO_SERVER_DASHBOARD_DETAIL': {
                _navigateToServerDashboardDetail(value);

                break;
            }
            default:
                break;
        }
    };

    const _fetchMonitorServers = async () => {
        try {
            screenLoader.show();

            await serverConfigStore.fetchMonitorServers();
        } catch (error) {
            console.log('error', error);

            if (error instanceof Error) {
                Swal.fire('เกิดข้อผิดพลาด!', error.message, 'error');
            }
        } finally {
            screenLoader.hide();
        }
    };

    const _autoRefreshTime = (isRefresh: boolean) => {
        if (autoRefresh || isRefresh) {
            setTimeout(() => {
                toggleRefresh();
            }, 60000);
        }
    };

    const _navigateToServerDashboardDetail = (id: string) => {
        try {
            // set selected server
            serverConfigStore.setSelectedServer(id);

            // redirect to server detail
            history.push(`/ViewDashboardServer/adminServer/${id}`);
        } catch (error) {
            if (error instanceof Error) {
                _handleErrorNavigation(error.message);
            }
        }
    };

    const _navigateToAdminServerControl = (id: string) => {
        try {
            // set selected server
            serverConfigStore.setSelectedServer(id);

            // redirect to server control
            history.push(`/admindashboardcontrol/${id}`);
        } catch (error) {
            if (error instanceof Error) {
                _handleErrorNavigation(error.message);
            }
        }
    };

    const _handleErrorNavigation = (message: string) => {
        Swal.fire('เกิดข้อผิดพลาด!', message, 'error').then(result => {
            if (result.isConfirmed) {
                history.push('/');
            }
        });
    };

    return {
        activeTab,
        onSelectTab,
        handleClick,
        monitorServers,
        onchangeSwitch,
        autoRefresh,
        toggleRefresh,
        authenticationUser
    };
}

export default ServerMonitorViewModel;
