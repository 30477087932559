import { Table, TableContainer, Tbody, Td, Th, Thead, Tr } from "@chakra-ui/react";
import { TransportRequestEntity } from "data/TransportRequest";
import React, { useState } from "react";
import { FaDownload } from "react-icons/fa6";
import { IoIosSearch } from "react-icons/io";

type Props = {
    transportRequestPrd: TransportRequestEntity[];
    onClickDowloadFile: (prdTag: string) => void;
}


interface Exname {
    prdTag: string;
    transportRequestDetailperTag: {
        uatTag: string;
        transportRequestDetail: TransportRequestEntity[];
    }[];
}

function TransportRequestTablePRD(props: Props) {
    const { transportRequestPrd, onClickDowloadFile } = props;

    const headers: string[] = [
        '',
        'PRD TAG',
        'UAT TAG',
        'Module',
        'Deployment Object',
        'Version',
        'Ticket',
        'Detail',
        'Impact',
        'Risk Level',
        'Core Non Core',
        'Developer'
    ];

    const groupTransportRequestsByPrdAndUatTag = (transportRequests: TransportRequestEntity[]): Exname[] => {
        if (!transportRequests) {
            return [];
        }

        const groupedByPrdTag = transportRequests.reduce((acc, transportRequest) => {
            const { prdTag, uatTag } = transportRequest;

            if (!acc[prdTag]) {
                acc[prdTag] = { prdTag, transportRequestDetailperTag: {} };
            }

            if (!acc[prdTag].transportRequestDetailperTag[uatTag]) {
                acc[prdTag].transportRequestDetailperTag[uatTag] = { uatTag, transportRequestDetail: [] };
            }

            acc[prdTag].transportRequestDetailperTag[uatTag].transportRequestDetail.push(transportRequest);

            return acc;
        }, {} as { [prdTag: string]: { prdTag: string; transportRequestDetailperTag: { [uatTag: string]: { uatTag: string; transportRequestDetail: TransportRequestEntity[] } } } });

        return Object.values(groupedByPrdTag).map(group => ({
            prdTag: group.prdTag,
            transportRequestDetailperTag: Object.values(group.transportRequestDetailperTag),
        }));
    };

    const groupedTransportRequests = groupTransportRequestsByPrdAndUatTag(transportRequestPrd || []);

    return (
        <TableContainer
            whiteSpace="wrap"
            className="mt-2"
        >
            <table
            >
                <thead>
                    <tr>
                        {headers.map(header => (
                            <th
                                key={header}
                                className="border text-center"
                                style={{
                                    borderColor:
                                        'rgb(212 212 212 / var(--tw-border-opacity)) !important'
                                }}
                            >
                                {header}
                            </th>
                        ))}
                    </tr>
                </thead>
                <tbody className="overflow-x-scroll truncate">
                    {groupedTransportRequests.map(group => (
                        <React.Fragment key={group.prdTag}>
                            {group.transportRequestDetailperTag.map((uatGroup, uatIndex) => (
                                <React.Fragment key={uatGroup.uatTag}>
                                    {uatGroup.transportRequestDetail.map((detail, detailIndex) => (
                                        <tr key={detail.ticket} className={`[&>td]:border [&>td]:border-neutral-300 ${JSON.parse(detail.deployStatus) ? 'bg-green-50' : 'hover:bg-gray-50'}`}>
                                            {uatIndex === 0 && detailIndex === 0 && (
                                                <React.Fragment>
                                                    <td
                                                        rowSpan={group.transportRequestDetailperTag.reduce((acc, uat) => acc + uat.transportRequestDetail.length, 0)}
                                                        className="cursor-pointer text-center p-2"
                                                        onClick={() => onClickDowloadFile(group.prdTag)}
                                                    >
                                                        <FaDownload className="text-green-500" />
                                                    </td>
                                                    <td rowSpan={group.transportRequestDetailperTag.reduce((acc, uat) => acc + uat.transportRequestDetail.length, 0)}>
                                                        {group.prdTag}
                                                    </td>
                                                </React.Fragment>
                                            )}
                                            {detailIndex === 0 && (
                                                <td rowSpan={uatGroup.transportRequestDetail.length}>
                                                    {uatGroup.uatTag}
                                                </td>
                                            )}
                                            <td>{detail.module}</td>
                                            <td>{detail.deploymentObject}</td>
                                            <td>{detail.version}</td>
                                            {detailIndex === 0 && (
                                                <React.Fragment>
                                                    <td rowSpan={uatGroup.transportRequestDetail.length}>{detail.ticket}</td>
                                                    <td rowSpan={uatGroup.transportRequestDetail.length}>{detail.details}</td>
                                                    <td rowSpan={uatGroup.transportRequestDetail.length}>{detail.impact}</td>
                                                </React.Fragment>
                                            )}
                                            <td>{detail.riskLevel}</td>
                                            <td>{detail.coreOrNonCore}</td>
                                            {detailIndex === 0 && (
                                                <td rowSpan={uatGroup.transportRequestDetail.length}>{detail.createdBy}</td>
                                            )}
                                        </tr>
                                    ))}
                                </React.Fragment>
                            ))}
                        </React.Fragment>
                    ))}
                </tbody>
            </table>
        </TableContainer>
    );
}

export default TransportRequestTablePRD;