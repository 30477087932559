import { memo, useCallback, useEffect, useRef } from 'react';

type Props = {
    /** Must be 2 dimensional array */
    data: any[];
    options?: google.visualization.LineChartOptions;
    events?: {
        eventName:
            | 'ready'
            | 'error'
            | 'select'
            | 'animationfinish'
            | 'statechange'
            | 'ok'
            | 'cancel'
            | 'animationstart';
        callback: (chart: google.visualization.LineChart) => void;
    }[];
};

function LineChart(props: Props) {
    const { data, options, events } = props;

    const containerRef = useRef<HTMLDivElement>(null);

    useEffect(() => {
        google.charts.load('current', { packages: ['corechart', 'line'] });
        google.charts.setOnLoadCallback(draw);
    }, []);

    useEffect(() => {
        window.addEventListener('resize', draw);

        return () => window.addEventListener('resize', draw);
    }, []);

    const draw = useCallback(() => {
        if (!containerRef.current) {
            return;
        }

        const dataTable = google.visualization.arrayToDataTable(data);

        const chartOptions: google.visualization.LineChartOptions = {
            legend: { position: 'bottom' },
            ...options
        };

        const chart = new google.visualization.LineChart(containerRef.current);

        if (events) {
            events.forEach(event => {
                google.visualization.events.addListener(chart, event.eventName, () =>
                    event.callback(chart)
                );
            });
        }

        chart.draw(dataTable, chartOptions);
    }, [data]);

    return (
        <div className="rounded-md border p-0.5">
            <div ref={containerRef} />
        </div>
    );
}

export default memo(LineChart);
