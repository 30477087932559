import { Action, Reducer } from 'redux';
import { AppThunkAction } from './';

import * as Models from '../models/AdminWeb'

interface LoadAdminWeb {
    type: 'LOAD_ADMIN_WEB'
}

interface LoadedAdminWeb {
    type: 'LOADED_ADMIN_WEB';
    response: Models.DataAdminWeb;
    status: number,
    statusMessage: string
}

interface FailLoadedAdminWeb {
    type: 'LOAD_ADMIN_WEB_FAIL'
    status: number,
    statusMessage: any
}

interface ClearAdminWeb {
    type: 'CLEAR_ADMIN_WEB'
}

type KnownAction = LoadedAdminWeb | FailLoadedAdminWeb | LoadAdminWeb | ClearAdminWeb;


export const actionCreators = {
    requestGetAdminWeb: (startDateIndex: number): AppThunkAction<KnownAction> => (dispatch, getState) => {

        const appState = getState();
        if (startDateIndex == 100) {
            fetch(`v1/monitor/web`)
                .then((response) => {
                    if (!response.ok) {
                        throw new Error(response.json() as any)
                    } else {
                        return response.json() as Promise<Models.DataAdminWeb>
                    }
                })
                .then((data) => {
                    dispatch({ type: 'LOADED_ADMIN_WEB', response: data, status: 200, statusMessage: '' })
                })
                .catch(error => {
                    dispatch({ type: 'LOAD_ADMIN_WEB_FAIL', status: 204, statusMessage: 'errorfatch' })
                });
            dispatch({ type: 'LOAD_ADMIN_WEB' })
        }


    }
};

const unloadedState: Models.AdminWebState = { isLoadAdminWeb: false ,ResponseAdminWeb:[] };

export const reducer: Reducer<Models.AdminWebState> = (state: Models.AdminWebState | undefined, incomingAction: Action): Models.AdminWebState => {
    if (state === undefined) {
        return unloadedState;
    }
    const action = incomingAction as KnownAction;
    switch (action.type) {
        case 'LOAD_ADMIN_WEB':
            return {
                isLoadAdminWeb: false,
                ResponseAdminWeb:[]
            }
        case 'LOADED_ADMIN_WEB':
            return {
                isLoadAdminWeb: true,
                ResponseAdminWeb: action.response as any,
                statusAdminWeb: action.status,
            }
        case 'LOAD_ADMIN_WEB_FAIL':
            return {
                isLoadAdminWeb: false,
                statusAdminWeb: action.status,
                messageAdminWeb: action.statusMessage,
                ResponseAdminWeb: []
            }
        default: return state;

    }

};
