import axios from 'axios';

import { MonitorServerResponse } from '../../../schemas/monitor';

export async function getIndayById(serverId: string) {
    return axios.get<MonitorServerResponse[]>('v1/monitor/server/inday/' + serverId, {
        headers: {
            Authorization: 'Bearer ' + localStorage.getItem('MM_APPLICATION')
        }
    });
}
