import React from 'react';
import { Button, Input } from 'reactstrap';
import BootstrapSwitchButton from 'bootstrap-switch-button-react';
import { BiEdit } from 'react-icons/bi';

import ClearTransactionViewModel from './ClearTransaction.viewmodel';
import { ClearTransactionResponse, clearTransactionDetail } from 'data/schemas/clearTransaction';
import { CiSearch } from 'react-icons/ci';
import { FaSearchengin } from 'react-icons/fa6';
import { IoMdSearch } from 'react-icons/io';

const ClearTransaction = () => {
    const { companyList } = ClearTransactionViewModel();

    const clearTransactionHeader = ['Transaction ID', 'Group', 'Topic', 'Remark', 'Status', 'Detail']

    const generateMockClearTransactionDetail = (): clearTransactionDetail => {
        return {
            transaction_id: '123456',
            transaction_group: 'groupA',
            topic: 'Sample Topic',
            remark: 'Sample Remark',
            script_clear: 'CLEAR SCRIPT',
            script_select: 'SELECT SCRIPT',
            created_by: 'user1',
            created_on: '2024-01-01T00:00:00Z',
            updated_by: 'user2',
            updated_on: '2024-01-02T00:00:00Z',
            status: 'Completed',
        };
    };

    const generateMockClearTransactionResponse = (): ClearTransactionResponse => {
        return {
            transaction_id: '654321',
            transaction_group: 'groupB',
            topic: 'Another Topic',
            remark: 'Another Remark',
            status: 'Pending',
            clearTransactionDetail: [generateMockClearTransactionDetail(), generateMockClearTransactionDetail()],
        };
    };
    const mockData: ClearTransactionResponse[] = [generateMockClearTransactionResponse(), generateMockClearTransactionResponse()];


    return (
        <div className="shadow m-3 p-3 border rounded">
            <div className="row items-center flex justify-between p-2">
                <div className="flex gap-2 flex-1">
                    <select className='border-2 rounded w-1/5'>
                        {companyList.map((value, index) => (
                            <option key={index} value={value.value}>{value.name}</option>
                        ))}
                    </select>
                </div>
                <div className="justify-end items-end">
                    <Button className="btn btn-success">New Transaction</Button>
                </div>
            </div>

            <div className="row">
                <div className="col-12 mt-4">
                    <table className="table table-bordered">
                        <thead>
                            <tr className="text-center">
                                {clearTransactionHeader.map((value, index) => (
                                    <td key={index} scope='col' className='text-black'>{value}</td>
                                ))}
                            </tr>
                        </thead>
                        <tbody>
                            {mockData.map((transactionData, index) => (
                                <tr key={index}>
                                    <td className='text-black'>{transactionData.transaction_id}</td>
                                    <td className='text-black'>{transactionData.transaction_group}</td>
                                    <td className='text-black'>{transactionData.topic}</td>
                                    <td className='text-black'>{transactionData.remark}</td>
                                    <td className='text-black'>{transactionData.status}</td>
                                    <td className='text-black w-10 h-10 text-center items-center'><CiSearch /></td>
                                </tr>
                            ))}
                        </tbody>
                    </table>
                </div>
            </div>
        </div>
    );
};

export default ClearTransaction;
