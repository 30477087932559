import React, { useState } from 'react';
import { observer } from 'mobx-react';
import { TransportRequestTag, UpdateTransportRequestResult } from 'data/schemas/TransportRequest';
import { Button } from '@chakra-ui/react';
import Select from 'react-select';
import Swal from 'sweetalert2';
import { FappConfig } from '../../../../models/FappMonitor';

interface ModalProps {
    selectedDeploy: TransportRequestTag;
    handleConfirmDeploy: (portList: string[], uatTag: string) => void;
    deployTransportRequestResult: UpdateTransportRequestResult,
    fappPorts: FappConfig[];
}

function ModalDeploy(props: ModalProps) {
    const { selectedDeploy, deployTransportRequestResult, fappPorts } = props;
    const [selectedPorts, setSelectedPorts] = useState<string[]>([]);

    const updateOutput = deployTransportRequestResult.commandExecutedOutput ?? [];

    const onClickComfirmDeploy = () => {
        if (selectedPorts.length === 0) {
            Swal.fire('Port target not found!', 'please select some port.', 'warning');

            return;
        }

        props.handleConfirmDeploy(selectedPorts, selectedDeploy.uatTag);
    }

    return (
        <React.Fragment>
            <div className="flex w-full">
                <div className="flex flex-col gap-3 border rounded px-3 py-2 w-screen">
                    <div className="flex">
                        <span className="font-bold pr-2">UAT TAG:</span>
                        <span>{selectedDeploy.uatTag}</span>
                    </div>
                    <div className="flex">
                        <span className="font-bold pr-2">Details:</span>
                        <span className="text-wrap">{selectedDeploy.details}</span>
                    </div>
                    {selectedDeploy.remark && (
                        <div className="flex">
                            <span className="font-bold pr-2">Remark:</span>
                            <span>{selectedDeploy.remark}</span>
                        </div>
                    )}
                    <div className="flex">
                        <span className="font-bold pr-2">Developer:</span>
                        <span>{selectedDeploy.developer}</span>
                    </div>
                    {selectedDeploy.transportRequestDetail.map(value => (
                        <div className="border-b-2 px-3 text-nowrap last:border-none">
                            <div className="flex">
                                <span className="font-bold pr-2">Module:</span>
                                <span>{value.module}</span>
                            </div>
                            <div className="flex">
                                <span className="font-bold pr-2">File Name:</span>
                                <span>{value.deploymentObject}</span>
                            </div>
                            {value.version !== '' && (
                                <div className="flex">
                                    <span className="font-bold pr-2">Version:</span>
                                    <span>{value.version}</span>
                                </div>
                            )}
                        </div>
                    ))}
                </div>

                <div className="ml-2 w-screen">
                    {/* Select ports */}
                    <div>
                        <label>Select ports update</label>
                        <Select
                            closeMenuOnSelect={false}
                            options={fappPorts}
                            getOptionLabel={f => f.name}
                            getOptionValue={f => f.portList}
                            isMulti
                            onChange={ports => setSelectedPorts(ports.map(p => p.portList))}
                        />
                    </div>

                    {/* Output*/}
                    <div className="mt-3">
                        <label className="headTBox">Output</label>
                        <div className="mat-box svn-result text-light">
                            {updateOutput.map((result) => result.message + '\n')}
                        </div>
                    </div>
                </div>
            </div>

            {/* Footer */}
            <div className="mt-3 w-full flex justify-end">
                <Button
                    colorScheme='teal'
                    variant='outline'
                    style={{ marginRight: '5px' }}
                    onClick={onClickComfirmDeploy}
                >
                    Confirm
                </Button>
            </div>
        </React.Fragment>
    );
}

export default observer(ModalDeploy);
