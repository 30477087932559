import React from 'react';
import CreatableSelect from 'react-select/creatable';
import { Button } from 'reactstrap';

import '../../../../assets/style/styles.css';
import { CommandType, FappPortOptions, RunCommandResponse } from '../../../../models/AdminServer';
import { CommandConfigMaster } from '../../../../models/CommandConfig';

interface GitUpdateProps {
    ports: FappPortOptions[];
    onSelectPort: (port: string) => void;
    onClickRunCommand: (command: CommandType, path: string) => void;
    output: RunCommandResponse[];
    commandConfigsMaster: CommandConfigMaster[];
}

const GitUpdateComponent = (props: GitUpdateProps) => {
    return (
        <>
            {/* Enter Path */}
            <div className="mb-2">
                <label className="headTBox mb-1">Select Port (Update target)</label>
                {/* <Input className="my-1" /> */}
                <CreatableSelect
                    className="w-100"
                    isClearable={false}
                    options={props.ports}
                    onChange={port => props.onSelectPort(port?.value ?? '')}
                    isValidNewOption={() => false}
                />
            </div>

            <div className="my-1">
                {props.commandConfigsMaster &&
                    props.commandConfigsMaster.length > 0 &&
                    props.commandConfigsMaster.map((command, index) => {
                        return (
                            <Button
                                key={index}
                                onClick={() =>
                                    props.onClickRunCommand(
                                        {
                                            name: command.command_name
                                        },
                                        ''
                                    )
                                }
                                className="mr-2 mt-1"
                                style={{
                                    background: command.btn_color,
                                    borderColor: command.btn_color
                                }}
                            >
                                {command.btn_name}
                            </Button>
                        );
                    })}
            </div>

            <div className="my-1">
                <label className="headTBox">Output</label>
                <div className="mat-box svn-result text-light">
                    {props.output.map(result => result.message + '\n')}
                </div>
            </div>
        </>
    );
};

export default GitUpdateComponent;
