import { Button, Input } from '@chakra-ui/react';
import { TransportRequestSearch } from 'data/schemas/TransportRequest';
import { useState } from 'react';

type Props = {
    onClickAddTransportRequest: () => void;
    onClickSearchTransportRequest: () => void;
    onSearchTransportRequest: <K extends keyof TransportRequestSearch>(
        key: K,
        value: TransportRequestSearch[K]
    ) => void;
};

function ActionPane(props: Props) {
    const { onClickAddTransportRequest, onClickSearchTransportRequest, onSearchTransportRequest } =
        props;

    return (
        <form
            onSubmit={event => event.preventDefault()}
            className="flex items-end gap-4"
        >
            <div>
                <label htmlFor="tag">Tag</label>
                <Input
                    onChange={e => {
                        onSearchTransportRequest('uatTag', e.target.value);
                    }}
                />
            </div>
            <div>
                <label htmlFor="developer">Developer</label>
                <Input
                    onChange={e => {
                        onSearchTransportRequest('developer', e.target.value);
                    }}
                />
            </div>
            <div>
                <label htmlFor="file-name">File Name</label>
                <Input
                    onChange={e => {
                        onSearchTransportRequest('deploymentObject', e.target.value);
                    }}
                />
            </div>
            <div>
                <label htmlFor="status">Status</label>
                <Input
                    onChange={e => {
                        onSearchTransportRequest('deployStatus', e.target.value);
                    }}
                />
            </div>
            <Button
                onClick={() => {
                    onClickSearchTransportRequest();
                }}
            >
                Search
            </Button>
            <div className="gap-2">
                <label htmlFor="item">Item : </label>
                <select
                    onChange={e => {
                        onSearchTransportRequest('pageSize', e.target.value);
                    }}
                >
                    <option>15</option>
                    <option>25</option>
                    <option>35</option>
                    <option>45</option>
                </select>
            </div>
            <Button
                className="ml-auto"
                onClick={onClickAddTransportRequest}
            >
                Add TR (Transport Request)
            </Button>
        </form>
    );
}

export default ActionPane;
