import { AxiosError } from 'axios';

import { ResponseList } from 'models/Common';
import {
    CreateTransportRequest,
    TransportRequestEntity,
    TransportRequestInsert,
    TransportRequestSearch,
    TransportRequestTag,
    TransportRequestUpdateDeployStatus,
    UpdateTransportRequestResult
} from 'data/schemas/TransportRequest';
import {
    callEditOrUpdateTransportRequest,
    callInsertTransportRequestUat,
    callUpdateTransportRequestUat,
    retrieveTransportRequestByUat,
    retrieveTransportRequestUat
} from 'data/datasources/v1/TransportRequest';

import { validateDataResponse } from '../../../commons/helpers';
import { callCreateTransportRequestUatV2 } from 'data/datasources/v2/transportRequest';

export async function getTransportRequestUat(
    params?: TransportRequestSearch
): Promise<ResponseList<TransportRequestTag[]>> {
    try {
        const response = await retrieveTransportRequestUat(params);
        const result: ResponseList<TransportRequestTag[]> = validateDataResponse(response);

        return result;
    } catch (error) {
        if (error instanceof AxiosError && error.response) {
            throw new Error(validateDataResponse(error.response).message);
        }

        throw error;
    }
}

export async function insertTransportRequestUat(params: TransportRequestInsert) {
    try {
        const response = await callInsertTransportRequestUat(params);
        const result: ResponseList<TransportRequestTag[]> = validateDataResponse(response);

        return result;
    } catch (error) {
        if (error instanceof AxiosError && error.response) {
            throw new Error(validateDataResponse(error.response).message);
        }

        throw error;
    }
}

export async function createTransportRequestUatV2(params: CreateTransportRequest) {
    try {
        const response = await callCreateTransportRequestUatV2(params);
        const result: ResponseList<TransportRequestTag[]> = validateDataResponse(response);

        return result;
    } catch (error) {
        if (error instanceof AxiosError && error.response) {
            throw new Error(validateDataResponse(error.response).message);
        }

        throw error;
    }
}

export async function updateTransportRequestUat(params: TransportRequestUpdateDeployStatus) {
    try {
        const response = await callUpdateTransportRequestUat(params);
        const result: UpdateTransportRequestResult = validateDataResponse(response);

        return result;
    } catch (error) {
        if (error instanceof AxiosError && error.response) {
            throw new Error(validateDataResponse(error.response).message);
        }

        throw error;
    }
}

export async function editOrUpdateTransportRequest(body: TransportRequestTag) {
    try {
        const response = await callEditOrUpdateTransportRequest(body);
        const result: string = validateDataResponse(response);

        return result;
    } catch (error) {
        if (error instanceof AxiosError && error.response) {
            throw new Error(validateDataResponse(error.response).message);
        }

        throw error;
    }
}

export async function getTransportRequestUatByTag(
    params: string
): Promise<TransportRequestEntity[]> {
    try {
        const response = await retrieveTransportRequestByUat(params);
        const result: TransportRequestEntity[] = validateDataResponse(response);

        return result;
    } catch (error) {
        if (error instanceof AxiosError && error.response) {
            throw new Error(validateDataResponse(error.response).message);
        }

        throw error;
    }
}
